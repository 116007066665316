export default {
  // Common translations

  termsConditions: 'Termos e Condições',
  privacy: 'Política de Privacidade',
  disclaimer: 'Declaração de Isenção de Responsabilidade',
  'min-deposit': 'Depósito mínimo',
  'user-score': 'Pontuação de usuários',
  'start-trading': 'Faça Trading',
  'more-info': 'mais informações',
  description: '',
  'payment-methods': 'Métodos de Pagamento',
  'full-regulations-list': 'Lista completa de regulamentos',
  'other-recommended-partners': 'Outros Parceiros Recomendados',

  // Offers list

  offers: {
    'immediate-edge': {
      description: '',
      disclaimer: 'Estamos cientes de que existem vários sites que usam depoimentos falsos de celebridades para enviar clientes a corretores ou centrais de atendimento não regulamentados. Nosso site enviará clientes apenas a parceiros que nos tenham fornecido as garantias regulamentares exigidas. Esta é uma oferta de marketing e nossos parceiros regulamentados indicarão quais serviços eles têm disponíveis no momento do registro.',
      keyFeatures: [
        'Tenha Suporte De Um Consultor Gratuitamente',
        '62% dos usuários relatam lucro com o primeiro depósito',
        'Relata uma taxa de sucesso de 31% em investimentos de alto risco'
      ],
      logoFeatured: '/images/immediate-edge.webp',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Immediate Edge',
      paymentMethods: ['Cartão de crédito', 'Cartão de débito', 'Transferência bancária'],
      starRating: 93,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/27P3D6/'
    },
    'bitcoin-system': {
      description: '',
      disclaimer: 'Estamos cientes de que existem vários sites que usam depoimentos falsos de celebridades para enviar clientes a corretores ou centrais de atendimento não regulamentados. Nosso site enviará clientes apenas a parceiros que nos tenham fornecido as garantias regulamentares exigidas. Esta é uma oferta de marketing e nossos parceiros regulamentados indicarão quais serviços eles têm disponíveis no momento do registro.',
      keyFeatures: [
        'Tenha Suporte De Um Consultor Gratuitamente',
        '62% dos usuários relatam lucro com o primeiro depósito',
        'Relata uma taxa de sucesso de 31% em investimentos de alto risco'
      ],
      logoFeatured: '/images/bitcoin-system.svg',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin System',
      paymentMethods: ['Cartão de crédito', 'Cartão de débito', 'Transferência bancária'],
      starRating: 91,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2CTPL/'
    },
    'bitcoin-bot': {
      description: '',
      disclaimer: 'Estamos cientes de que existem vários sites que usam depoimentos falsos de celebridades para enviar clientes a corretores ou centrais de atendimento não regulamentados. Nosso site enviará clientes apenas a parceiros que nos tenham fornecido as garantias regulamentares exigidas. Esta é uma oferta de marketing e nossos parceiros regulamentados indicarão quais serviços eles têm disponíveis no momento do registro.',
      keyFeatures: [
        'Tenha Suporte De Um Consultor Gratuitamente',
        '62% dos usuários relatam lucro com o primeiro depósito',
        'Relata uma taxa de sucesso de 31% em investimentos de alto risco'
      ],
      logoFeatured: '/images/bitcoinbot.jpg',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Bot',
      paymentMethods: ['Cartão de crédito', 'Cartão de débito', 'Transferência bancária'],
      starRating: 98,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/SDFZCK/'
    },
    'bitcoin-era': {
      description: '',
      disclaimer: 'Estamos cientes de que existem vários sites que usam depoimentos falsos de celebridades para enviar clientes a corretores ou centrais de atendimento não regulamentados. Nosso site enviará clientes apenas a parceiros que nos tenham fornecido as garantias regulamentares exigidas. Esta é uma oferta de marketing e nossos parceiros regulamentados indicarão quais serviços eles têm disponíveis no momento do registro.',
      keyFeatures: [
        'Tenha Suporte De Um Consultor Gratuitamente',
        '62% dos usuários relatam lucro com o primeiro depósito',
        'Relata uma taxa de sucesso de 31% em investimentos de alto risco'
      ],
      logoFeatured: '/images/bitcoin-era.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Era',
      paymentMethods: ['Cartão de crédito', 'Cartão de débito', 'Transferência bancária'],
      starRating: 85,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/5XTBKP/'
    },
    'profit-builder': {
      description: '',
      disclaimer: 'Estamos cientes de que existem vários sites que usam depoimentos falsos de celebridades para enviar clientes a corretores ou centrais de atendimento não regulamentados. Nosso site enviará clientes apenas a parceiros que nos tenham fornecido as garantias regulamentares exigidas. Esta é uma oferta de marketing e nossos parceiros regulamentados indicarão quais serviços eles têm disponíveis no momento do registro.',
      keyFeatures: [
        'Tenha Suporte De Um Consultor Gratuitamente',
        '62% dos usuários relatam lucro com o primeiro depósito',
        'Relata uma taxa de sucesso de 31% em investimentos de alto risco'
      ],
      logoFeatured: '/images/profitbuilder.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Profit Builder',
      paymentMethods: ['Cartão de crédito', 'Cartão de débito', 'Transferência bancária'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/LDF3B4/'
    },
    '1k-in-1day': {
      description: '',
      disclaimer: 'Estamos cientes de que existem vários sites que usam depoimentos falsos de celebridades para enviar clientes a corretores ou centrais de atendimento não regulamentados. Nosso site enviará clientes apenas a parceiros que nos tenham fornecido as garantias regulamentares exigidas. Esta é uma oferta de marketing e nossos parceiros regulamentados indicarão quais serviços eles têm disponíveis no momento do registro.',
      keyFeatures: [
        'Tenha Suporte De Um Consultor Gratuitamente',
        '62% dos usuários relatam lucro com o primeiro depósito',
        'Relata uma taxa de sucesso de 31% em investimentos de alto risco'
      ],
      logoFeatured: '/images/1k-in-1day.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: '1K in 1Day',
      paymentMethods: ['Cartão de crédito', 'Cartão de débito', 'Transferência bancária'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2DZ926C/'
    },
    'amazon-stocks-investment': {
      description: '',
      disclaimer: 'Estamos cientes de que existem vários sites que usam depoimentos falsos de celebridades para enviar clientes a corretores ou centrais de atendimento não regulamentados. Nosso site enviará clientes apenas a parceiros que nos tenham fornecido as garantias regulamentares exigidas. Esta é uma oferta de marketing e nossos parceiros regulamentados indicarão quais serviços eles têm disponíveis no momento do registro.',
      keyFeatures: [
        'Tenha Suporte De Um Consultor Gratuitamente',
        '62% dos usuários relatam lucro com o primeiro depósito',
        'Relata uma taxa de sucesso de 31% em investimentos de alto risco'
      ],
      logoFeatured: '/images/amazon-stocks-investment.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Amazon Stocks Investment',
      paymentMethods: ['Cartão de crédito', 'Cartão de débito', 'Transferência bancária'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/ZDXHZQ/'
    },
    'tradermate': {
      description: '',
      disclaimer: 'Estamos cientes de que existem vários sites que usam depoimentos falsos de celebridades para enviar clientes a corretores ou centrais de atendimento não regulamentados. Nosso site enviará clientes apenas a parceiros que nos tenham fornecido as garantias regulamentares exigidas. Esta é uma oferta de marketing e nossos parceiros regulamentados indicarão quais serviços eles têm disponíveis no momento do registro.',
      keyFeatures: [
        'Tenha Suporte De Um Consultor Gratuitamente',
        '62% dos usuários relatam lucro com o primeiro depósito',
        'Relata uma taxa de sucesso de 31% em investimentos de alto risco'
      ],
      logoFeatured: '/images/tradermate.svg',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'TraderMate',
      paymentMethods: ['Cartão de crédito', 'Cartão de débito', 'Transferência bancária'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/XN48XM/'
    },
    'bitcoin-profit': {
      description: '',
      disclaimer: 'Estamos cientes de que existem vários sites que usam depoimentos falsos de celebridades para enviar clientes a corretores ou centrais de atendimento não regulamentados. Nosso site enviará clientes apenas a parceiros que nos tenham fornecido as garantias regulamentares exigidas. Esta é uma oferta de marketing e nossos parceiros regulamentados indicarão quais serviços eles têm disponíveis no momento do registro.',
      keyFeatures: [
        'Tenha Suporte De Um Consultor Gratuitamente',
        '62% dos usuários relatam lucro com o primeiro depósito',
        'Relata uma taxa de sucesso de 31% em investimentos de alto risco'
      ],
      logoFeatured: '/images/bitcoin-profit.svg',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Profit',
      paymentMethods: ['Cartão de crédito', 'Cartão de débito', 'Transferência bancária'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/ZCJM85/'
    },
    'bitcoin-buyer': {
      description: '',
      disclaimer: 'Estamos cientes de que existem vários sites que usam depoimentos falsos de celebridades para enviar clientes a corretores ou centrais de atendimento não regulamentados. Nosso site enviará clientes apenas a parceiros que nos tenham fornecido as garantias regulamentares exigidas. Esta é uma oferta de marketing e nossos parceiros regulamentados indicarão quais serviços eles têm disponíveis no momento do registro.',
      keyFeatures: [
        'Tenha Suporte De Um Consultor Gratuitamente',
        '62% dos usuários relatam lucro com o primeiro depósito',
        'Relata uma taxa de sucesso de 31% em investimentos de alto risco'
      ],
      logoFeatured: '/images/bitcoin-buyer.svg',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Buyer',
      paymentMethods: ['Cartão de crédito', 'Cartão de débito', 'Transferência bancária'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/ZGBDPC/'
    },
    'yuan-payments': {
      description: '',
      disclaimer: 'Estamos cientes de que existem vários sites que usam depoimentos falsos de celebridades para enviar clientes a corretores ou centrais de atendimento não regulamentados. Nosso site enviará clientes apenas a parceiros que nos tenham fornecido as garantias regulamentares exigidas. Esta é uma oferta de marketing e nossos parceiros regulamentados indicarão quais serviços eles têm disponíveis no momento do registro.',
      keyFeatures: [
        'Tenha Suporte De Um Consultor Gratuitamente',
        '62% dos usuários relatam lucro com o primeiro depósito',
        'Relata uma taxa de sucesso de 31% em investimentos de alto risco'
      ],
      logoFeatured: '/images/yuan-payments.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Yuan Payments',
      paymentMethods: ['Cartão de crédito', 'Cartão de débito', 'Transferência bancária'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/22C3ZNF/'
    },
    'crypto-bank': {
      description: '',
      disclaimer: 'Estamos cientes de que existem vários sites que usam depoimentos falsos de celebridades para enviar clientes a corretores ou centrais de atendimento não regulamentados. Nosso site enviará clientes apenas a parceiros que nos tenham fornecido as garantias regulamentares exigidas. Esta é uma oferta de marketing e nossos parceiros regulamentados indicarão quais serviços eles têm disponíveis no momento do registro.',
      keyFeatures: [
        'Tenha Suporte De Um Consultor Gratuitamente',
        '62% dos usuários relatam lucro com o primeiro depósito',
        'Relata uma taxa de sucesso de 31% em investimentos de alto risco'
      ],
      logoFeatured: '/images/crypto-bank.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Crypto Bank',
      paymentMethods: ['Cartão de crédito', 'Cartão de débito', 'Transferência bancária'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/22FTQ5M/'
    },
    'crypto-engine': {
      description: '',
      disclaimer: 'Estamos cientes de que existem vários sites que usam depoimentos falsos de celebridades para enviar clientes a corretores ou centrais de atendimento não regulamentados. Nosso site enviará clientes apenas a parceiros que nos tenham fornecido as garantias regulamentares exigidas. Esta é uma oferta de marketing e nossos parceiros regulamentados indicarão quais serviços eles têm disponíveis no momento do registro.',
      keyFeatures: [
        'Tenha Suporte De Um Consultor Gratuitamente',
        '62% dos usuários relatam lucro com o primeiro depósito',
        'Relata uma taxa de sucesso de 31% em investimentos de alto risco'
      ],
      logoFeatured: '/images/crypto-engine.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Crypto Engine',
      paymentMethods: ['Cartão de crédito', 'Cartão de débito', 'Transferência bancária'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/22H8LW8/'
    },
    'bitcoin-pro': {
      description: '',
      disclaimer: 'Estamos cientes de que existem vários sites que usam depoimentos falsos de celebridades para enviar clientes a corretores ou centrais de atendimento não regulamentados. Nosso site enviará clientes apenas a parceiros que nos tenham fornecido as garantias regulamentares exigidas. Esta é uma oferta de marketing e nossos parceiros regulamentados indicarão quais serviços eles têm disponíveis no momento do registro.',
      keyFeatures: [
        'Tenha Suporte De Um Consultor Gratuitamente',
        '62% dos usuários relatam lucro com o primeiro depósito',
        'Relata uma taxa de sucesso de 31% em investimentos de alto risco'
      ],
      logoFeatured: '/images/bitcoin-pro.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Pro',
      paymentMethods: ['Cartão de crédito', 'Cartão de débito', 'Transferência bancária'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/22L2DCG/'
    },

    'bitcoin-bank': {
      description: '',
      disclaimer: 'Estamos cientes de que existem vários sites que usam depoimentos falsos de celebridades para enviar clientes a corretores ou centrais de atendimento não regulamentados. Nosso site enviará clientes apenas a parceiros que nos tenham fornecido as garantias regulamentares exigidas. Esta é uma oferta de marketing e nossos parceiros regulamentados indicarão quais serviços eles têm disponíveis no momento do registro.',
      keyFeatures: [
        'Tenha Suporte De Um Consultor Gratuitamente',
        '62% dos usuários relatam lucro com o primeiro depósito',
        'Relata uma taxa de sucesso de 31% em investimentos de alto risco'
      ],
      logoFeatured: '/images/bitcoin-bank.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Bank',
      paymentMethods: ['Cartão de crédito', 'Cartão de débito', 'Transferência bancária'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/22RKX9W/'
    },
    'tesler': {
      description: '',
      disclaimer: 'Estamos cientes de que existem vários sites que usam depoimentos falsos de celebridades para enviar clientes a corretores ou centrais de atendimento não regulamentados. Nosso site enviará clientes apenas a parceiros que nos tenham fornecido as garantias regulamentares exigidas. Esta é uma oferta de marketing e nossos parceiros regulamentados indicarão quais serviços eles têm disponíveis no momento do registro.',
      keyFeatures: [
        'Tenha Suporte De Um Consultor Gratuitamente',
        '62% dos usuários relatam lucro com o primeiro depósito',
        'Relata uma taxa de sucesso de 31% em investimentos de alto risco'
      ],
      logoFeatured: '/images/tesler.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Tesler',
      paymentMethods: ['Cartão de crédito', 'Cartão de débito', 'Transferência bancária'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/22SZS2H/'
    },

    'bitcoin-up': {
      description: '',
      disclaimer: 'Estamos cientes de que existem vários sites que usam depoimentos falsos de celebridades para enviar clientes a corretores ou centrais de atendimento não regulamentados. Nosso site enviará clientes apenas a parceiros que nos tenham fornecido as garantias regulamentares exigidas. Esta é uma oferta de marketing e nossos parceiros regulamentados indicarão quais serviços eles têm disponíveis no momento do registro.',
      keyFeatures: [
        'Tenha Suporte De Um Consultor Gratuitamente',
        '62% dos usuários relatam lucro com o primeiro depósito',
        'Relata uma taxa de sucesso de 31% em investimentos de alto risco'
      ],
      logoFeatured: '/images/bitcoin-up.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin UP',
      paymentMethods: ['Cartão de crédito', 'Cartão de débito', 'Transferência bancária'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/23PZP9F/'
    },
    'nft-code': {
      description: '',
      disclaimer: 'Estamos cientes de que existem vários sites que usam depoimentos falsos de celebridades para enviar clientes a corretores ou centrais de atendimento não regulamentados. Nosso site enviará clientes apenas a parceiros que nos tenham fornecido as garantias regulamentares exigidas. Esta é uma oferta de marketing e nossos parceiros regulamentados indicarão quais serviços eles têm disponíveis no momento do registro.',
      keyFeatures: [
        'Tenha Suporte De Um Consultor Gratuitamente',
        '62% dos usuários relatam lucro com o primeiro depósito',
        'Relata uma taxa de sucesso de 31% em investimentos de alto risco'
      ],
      logoFeatured: '/images/nft-code.svg',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'NFT Code',
      paymentMethods: ['Cartão de crédito', 'Cartão de débito', 'Transferência bancária'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/23W5CH8/'
    },
    'bitcoin-code': {
      description: '',
      disclaimer: 'Estamos cientes de que existem vários sites que usam depoimentos falsos de celebridades para enviar clientes a corretores ou centrais de atendimento não regulamentados. Nosso site enviará clientes apenas a parceiros que nos tenham fornecido as garantias regulamentares exigidas. Esta é uma oferta de marketing e nossos parceiros regulamentados indicarão quais serviços eles têm disponíveis no momento do registro.',
      keyFeatures: [
        'Tenha Suporte De Um Consultor Gratuitamente',
        '62% dos usuários relatam lucro com o primeiro depósito',
        'Relata uma taxa de sucesso de 31% em investimentos de alto risco'
      ],
      logoFeatured: '/images/bitcoin-code.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Code',
      paymentMethods: ['Cartão de crédito', 'Cartão de débito', 'Transferência bancária'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/26DZF27/'
    },
    'bitcoin-fast-profit': {
      description: '',
      disclaimer: 'Estamos cientes de que existem vários sites que usam depoimentos falsos de celebridades para enviar clientes a corretores ou centrais de atendimento não regulamentados. Nosso site enviará clientes apenas a parceiros que nos tenham fornecido as garantias regulamentares exigidas. Esta é uma oferta de marketing e nossos parceiros regulamentados indicarão quais serviços eles têm disponíveis no momento do registro.',
      keyFeatures: [
        'Tenha Suporte De Um Consultor Gratuitamente',
        '62% dos usuários relatam lucro com o primeiro depósito',
        'Relata uma taxa de sucesso de 31% em investimentos de alto risco'
      ],
      logoFeatured: '/images/bitcoin-fast-profit.svg',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Fast Profit',
      paymentMethods: ['Cartão de crédito', 'Cartão de débito', 'Transferência bancária'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/26GC9QS/'
    },
    'bitcoin-trader': {
      description: '',
      disclaimer: 'Estamos cientes de que existem vários sites que usam depoimentos falsos de celebridades para enviar clientes a corretores ou centrais de atendimento não regulamentados. Nosso site enviará clientes apenas a parceiros que nos tenham fornecido as garantias regulamentares exigidas. Esta é uma oferta de marketing e nossos parceiros regulamentados indicarão quais serviços eles têm disponíveis no momento do registro.',
      keyFeatures: [
        'Tenha Suporte De Um Consultor Gratuitamente',
        '62% dos usuários relatam lucro com o primeiro depósito',
        'Relata uma taxa de sucesso de 31% em investimentos de alto risco'
      ],
      logoFeatured: '/images/bitcoin-trader.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Trader',
      paymentMethods: ['Cartão de crédito', 'Cartão de débito', 'Transferência bancária'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/26HQ6HF/'
    },
    'bitcoin-millionaire-pro-system': {
      description: '',
      disclaimer: 'Estamos cientes de que existem vários sites que usam depoimentos falsos de celebridades para enviar clientes a corretores ou centrais de atendimento não regulamentados. Nosso site enviará clientes apenas a parceiros que nos tenham fornecido as garantias regulamentares exigidas. Esta é uma oferta de marketing e nossos parceiros regulamentados indicarão quais serviços eles têm disponíveis no momento do registro.',
      keyFeatures: [
        'Tenha Suporte De Um Consultor Gratuitamente',
        '62% dos usuários relatam lucro com o primeiro depósito',
        'Relata uma taxa de sucesso de 31% em investimentos de alto risco'
      ],
      logoFeatured: '/images/bitcoin-millionaire-pro-system.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Millionaire Pro System',
      paymentMethods: ['Cartão de crédito', 'Cartão de débito', 'Transferência bancária'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/26LHXZM/'
    },
    'bitcoin-circuit': {
      description: '',
      disclaimer: 'Estamos cientes de que existem vários sites que usam depoimentos falsos de celebridades para enviar clientes a corretores ou centrais de atendimento não regulamentados. Nosso site enviará clientes apenas a parceiros que nos tenham fornecido as garantias regulamentares exigidas. Esta é uma oferta de marketing e nossos parceiros regulamentados indicarão quais serviços eles têm disponíveis no momento do registro.',
      keyFeatures: [
        'Tenha Suporte De Um Consultor Gratuitamente',
        '62% dos usuários relatam lucro com o primeiro depósito',
        'Relata uma taxa de sucesso de 31% em investimentos de alto risco'
      ],
      logoFeatured: '/images/bitcoin-circuit.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Circuit',
      paymentMethods: ['Cartão de crédito', 'Cartão de débito', 'Transferência bancária'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/26P9PFT/'
    },
    'bitcoin-future': {
      description: '',
      disclaimer: 'Estamos cientes de que existem vários sites que usam depoimentos falsos de celebridades para enviar clientes a corretores ou centrais de atendimento não regulamentados. Nosso site enviará clientes apenas a parceiros que nos tenham fornecido as garantias regulamentares exigidas. Esta é uma oferta de marketing e nossos parceiros regulamentados indicarão quais serviços eles têm disponíveis no momento do registro.',
      keyFeatures: [
        'Tenha Suporte De Um Consultor Gratuitamente',
        '62% dos usuários relatam lucro com o primeiro depósito',
        'Relata uma taxa de sucesso de 31% em investimentos de alto risco'
      ],
      logoFeatured: '/images/bitcoin-future.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Future',
      paymentMethods: ['Cartão de crédito', 'Cartão de débito', 'Transferência bancária'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/26QNL6G/'
    },
    'bitqt': {
      description: '',
      disclaimer: 'Estamos cientes de que existem vários sites que usam depoimentos falsos de celebridades para enviar clientes a corretores ou centrais de atendimento não regulamentados. Nosso site enviará clientes apenas a parceiros que nos tenham fornecido as garantias regulamentares exigidas. Esta é uma oferta de marketing e nossos parceiros regulamentados indicarão quais serviços eles têm disponíveis no momento do registro.',
      keyFeatures: [
        'Tenha Suporte De Um Consultor Gratuitamente',
        '62% dos usuários relatam lucro com o primeiro depósito',
        'Relata uma taxa de sucesso de 31% em investimentos de alto risco'
      ],
      logoFeatured: '/images/bitqt.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'BitQT',
      paymentMethods: ['Cartão de crédito', 'Cartão de débito', 'Transferência bancária'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/28Q7XLQ/'
    },
    'trade-2-earn': {
      description: '',
      disclaimer: 'Estamos cientes de que existem vários sites que usam depoimentos falsos de celebridades para enviar clientes a corretores ou centrais de atendimento não regulamentados. Nosso site enviará clientes apenas a parceiros que nos tenham fornecido as garantias regulamentares exigidas. Esta é uma oferta de marketing e nossos parceiros regulamentados indicarão quais serviços eles têm disponíveis no momento do registro.',
      keyFeatures: [
        'Tenha Suporte De Um Consultor Gratuitamente',
        '62% dos usuários relatam lucro com o primeiro depósito',
        'Relata uma taxa de sucesso de 31% em investimentos de alto risco'
      ],
      logoFeatured: '/images/trade-2-earn.svg',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Trade 2 Earn',
      paymentMethods: ['Cartão de crédito', 'Cartão de débito', 'Transferência bancária'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/28T1P3Z/'
    },
    'bitcoin-bank-breaker': {
      description: '',
      disclaimer: 'Estamos cientes de que existem vários sites que usam depoimentos falsos de celebridades para enviar clientes a corretores ou centrais de atendimento não regulamentados. Nosso site enviará clientes apenas a parceiros que nos tenham fornecido as garantias regulamentares exigidas. Esta é uma oferta de marketing e nossos parceiros regulamentados indicarão quais serviços eles têm disponíveis no momento do registro.',
      keyFeatures: [
        'Tenha Suporte De Um Consultor Gratuitamente',
        '62% dos usuários relatam lucro com o primeiro depósito',
        'Relata uma taxa de sucesso de 31% em investimentos de alto risco'
      ],
      logoFeatured: '/images/bitcoin-bank-breaker.svg',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Bank Breaker',
      paymentMethods: ['Cartão de crédito', 'Cartão de débito', 'Transferência bancária'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2BZPNQ3/'
    },
    'bitcoin-billionaire': {
      description: '',
      disclaimer: 'Estamos cientes de que existem vários sites que usam depoimentos falsos de celebridades para enviar clientes a corretores ou centrais de atendimento não regulamentados. Nosso site enviará clientes apenas a parceiros que nos tenham fornecido as garantias regulamentares exigidas. Esta é uma oferta de marketing e nossos parceiros regulamentados indicarão quais serviços eles têm disponíveis no momento do registro.',
      keyFeatures: [
        'Tenha Suporte De Um Consultor Gratuitamente',
        '62% dos usuários relatam lucro com o primeiro depósito',
        'Relata uma taxa de sucesso de 31% em investimentos de alto risco'
      ],
      logoFeatured: '/images/bitcoin-billionaire.svg',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Billionaire',
      paymentMethods: ['Cartão de crédito', 'Cartão de débito', 'Transferência bancária'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2DPBLHB/'
    },
    'bitcoin-loophole': {
      description: '',
      disclaimer: 'Estamos cientes de que existem vários sites que usam depoimentos falsos de celebridades para enviar clientes a corretores ou centrais de atendimento não regulamentados. Nosso site enviará clientes apenas a parceiros que nos tenham fornecido as garantias regulamentares exigidas. Esta é uma oferta de marketing e nossos parceiros regulamentados indicarão quais serviços eles têm disponíveis no momento do registro.',
      keyFeatures: [
        'Tenha Suporte De Um Consultor Gratuitamente',
        '62% dos usuários relatam lucro com o primeiro depósito',
        'Relata uma taxa de sucesso de 31% em investimentos de alto risco'
      ],
      logoFeatured: '/images/bitcoin-loophole.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Loophole',
      paymentMethods: ['Cartão de crédito', 'Cartão de débito', 'Transferência bancária'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2DQPH7X/'
    },
    'bitcoin-motion': {
      description: '',
      disclaimer: 'Estamos cientes de que existem vários sites que usam depoimentos falsos de celebridades para enviar clientes a corretores ou centrais de atendimento não regulamentados. Nosso site enviará clientes apenas a parceiros que nos tenham fornecido as garantias regulamentares exigidas. Esta é uma oferta de marketing e nossos parceiros regulamentados indicarão quais serviços eles têm disponíveis no momento do registro.',
      keyFeatures: [
        'Tenha Suporte De Um Consultor Gratuitamente',
        '62% dos usuários relatam lucro com o primeiro depósito',
        'Relata uma taxa de sucesso de 31% em investimentos de alto risco'
      ],
      logoFeatured: '/images/bitcoin-motion.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Motion',
      paymentMethods: ['Cartão de crédito', 'Cartão de débito', 'Transferência bancária'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2DS4CZJ/'
    },
    'profit-bitcoin': {
      description: '',
      disclaimer: 'Estamos cientes de que existem vários sites que usam depoimentos falsos de celebridades para enviar clientes a corretores ou centrais de atendimento não regulamentados. Nosso site enviará clientes apenas a parceiros que nos tenham fornecido as garantias regulamentares exigidas. Esta é uma oferta de marketing e nossos parceiros regulamentados indicarão quais serviços eles têm disponíveis no momento do registro.',
      keyFeatures: [
        'Tenha Suporte De Um Consultor Gratuitamente',
        '62% dos usuários relatam lucro com o primeiro depósito',
        'Relata uma taxa de sucesso de 31% em investimentos de alto risco'
      ],
      logoFeatured: '/images/profit-bitcoin.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Profit Bitcoin',
      paymentMethods: ['Cartão de crédito', 'Cartão de débito', 'Transferência bancária'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2DTH8P5/'
    },
    'bitcoin-evolution': {
      description: '',
      disclaimer: 'Estamos cientes de que existem vários sites que usam depoimentos falsos de celebridades para enviar clientes a corretores ou centrais de atendimento não regulamentados. Nosso site enviará clientes apenas a parceiros que nos tenham fornecido as garantias regulamentares exigidas. Esta é uma oferta de marketing e nossos parceiros regulamentados indicarão quais serviços eles têm disponíveis no momento do registro.',
      keyFeatures: [
        'Tenha Suporte De Um Consultor Gratuitamente',
        '62% dos usuários relatam lucro com o primeiro depósito',
        'Relata uma taxa de sucesso de 31% em investimentos de alto risco'
      ],
      logoFeatured: '/images/bitcoin-evolution.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Evolution',
      paymentMethods: ['Cartão de crédito', 'Cartão de débito', 'Transferência bancária'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2DWW5FQ/'
    },
    'bitcoin-champion': {
      description: '',
      disclaimer: 'Estamos cientes de que existem vários sites que usam depoimentos falsos de celebridades para enviar clientes a corretores ou centrais de atendimento não regulamentados. Nosso site enviará clientes apenas a parceiros que nos tenham fornecido as garantias regulamentares exigidas. Esta é uma oferta de marketing e nossos parceiros regulamentados indicarão quais serviços eles têm disponíveis no momento do registro.',
      keyFeatures: [
        'Tenha Suporte De Um Consultor Gratuitamente',
        '62% dos usuários relatam lucro com o primeiro depósito',
        'Relata uma taxa de sucesso de 31% em investimentos de alto risco'
      ],
      logoFeatured: '/images/bitcoin-champion.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Champion',
      paymentMethods: ['Cartão de crédito', 'Cartão de débito', 'Transferência bancária'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2F1MWWZ/'
    },
    'bitcoin-money': {
      description: '',
      disclaimer: 'Estamos cientes de que existem vários sites que usam depoimentos falsos de celebridades para enviar clientes a corretores ou centrais de atendimento não regulamentados. Nosso site enviará clientes apenas a parceiros que nos tenham fornecido as garantias regulamentares exigidas. Esta é uma oferta de marketing e nossos parceiros regulamentados indicarão quais serviços eles têm disponíveis no momento do registro.',
      keyFeatures: [
        'Tenha Suporte De Um Consultor Gratuitamente',
        '62% dos usuários relatam lucro com o primeiro depósito',
        'Relata uma taxa de sucesso de 31% em investimentos de alto risco'
      ],
      logoFeatured: '/images/bitcoin-money.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Money',
      paymentMethods: ['Cartão de crédito', 'Cartão de débito', 'Transferência bancária'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2F32RMK/'
    },
    'bitcoin-trend-app': {
      description: '',
      disclaimer: 'Estamos cientes de que existem vários sites que usam depoimentos falsos de celebridades para enviar clientes a corretores ou centrais de atendimento não regulamentados. Nosso site enviará clientes apenas a parceiros que nos tenham fornecido as garantias regulamentares exigidas. Esta é uma oferta de marketing e nossos parceiros regulamentados indicarão quais serviços eles têm disponíveis no momento do registro.',
      keyFeatures: [
        'Tenha Suporte De Um Consultor Gratuitamente',
        '62% dos usuários relatam lucro com o primeiro depósito',
        'Relata uma taxa de sucesso de 31% em investimentos de alto risco'
      ],
      logoFeatured: '/images/bitcoin-trend-app.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Trend App',
      paymentMethods: ['Cartão de crédito', 'Cartão de débito', 'Transferência bancária'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2F4FND6/'
    },
    'crypto-comeback-pro': {
      description: '',
      disclaimer: 'Estamos cientes de que existem vários sites que usam depoimentos falsos de celebridades para enviar clientes a corretores ou centrais de atendimento não regulamentados. Nosso site enviará clientes apenas a parceiros que nos tenham fornecido as garantias regulamentares exigidas. Esta é uma oferta de marketing e nossos parceiros regulamentados indicarão quais serviços eles têm disponíveis no momento do registro.',
      keyFeatures: [
        'Tenha Suporte De Um Consultor Gratuitamente',
        '62% dos usuários relatam lucro com o primeiro depósito',
        'Relata uma taxa de sucesso de 31% em investimentos de alto risco'
      ],
      logoFeatured: '/images/crypto-comeback-pro.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Crypto Comeback Pro',
      paymentMethods: ['Cartão de crédito', 'Cartão de débito', 'Transferência bancária'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2F5SK4R/'
    },
    'ethereum-system': {
      description: '',
      disclaimer: 'Estamos cientes de que existem vários sites que usam depoimentos falsos de celebridades para enviar clientes a corretores ou centrais de atendimento não regulamentados. Nosso site enviará clientes apenas a parceiros que nos tenham fornecido as garantias regulamentares exigidas. Esta é uma oferta de marketing e nossos parceiros regulamentados indicarão quais serviços eles têm disponíveis no momento do registro.',
      keyFeatures: [
        'Tenha Suporte De Um Consultor Gratuitamente',
        '62% dos usuários relatam lucro com o primeiro depósito',
        'Relata uma taxa de sucesso de 31% em investimentos de alto risco'
      ],
      logoFeatured: '/images/ethereum-system.svg',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Ethereum System',
      paymentMethods: ['Cartão de crédito', 'Cartão de débito', 'Transferência bancária'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2F77FTD/'
    },
    'the-bitcoin-millionaire': {
      description: '',
      disclaimer: 'Estamos cientes de que existem vários sites que usam depoimentos falsos de celebridades para enviar clientes a corretores ou centrais de atendimento não regulamentados. Nosso site enviará clientes apenas a parceiros que nos tenham fornecido as garantias regulamentares exigidas. Esta é uma oferta de marketing e nossos parceiros regulamentados indicarão quais serviços eles têm disponíveis no momento do registro.',
      keyFeatures: [
        'Tenha Suporte De Um Consultor Gratuitamente',
        '62% dos usuários relatam lucro com o primeiro depósito',
        'Relata uma taxa de sucesso de 31% em investimentos de alto risco'
      ],
      logoFeatured: '/images/the-bitcoin-millionaire.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'The Bitcoin Millionaire',
      paymentMethods: ['Cartão de crédito', 'Cartão de débito', 'Transferência bancária'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2F8LBL1/'
    }
  },

  home: {
    title: '{0} - Recomendado para {1} {2}',
    title2: 'Encontre os Lugares Mais Seguros para Investir',
    description: `
      {0} analisa as tendências de compra/venda do mercado para tirar vantagem e lucrar com a volatilidade. Não é necessário entender o comércio técnico para usar o software e este sistema recebeu inúmeras análises positivas online.
    `,

    description2: `
      <h2><strong>Como os corretores são classificados </strong>- {0}</h2>
      <p>
        A equipe do wealth-info.com analisa e revisa rigorosamente os corretores do setor, para que você não precise
        faze-lo. A equipe avalia uma lista de não menos que 57 itens, e alguns pontos-chave incluem:
      </p>
      <ul>
        <li>
          <strong>Qualidade do atendimento ao cliente -&nbsp;</strong>Eles têm uma seção de Ajuda fácil de usar? È fácil
          encontrar informações de contato? As informações são claras, incluindo horário de funcionamento, códigos de país
          etc.? Eles oferecem uma variedade de métodos de suporte ao cliente, por exemplo: E-mail, telefone, bate-papo,
          Correio? Eles são rápidos em responder? Eles oferecem um bom serviço? Eles são educados? Eles têm conhecimento?
        </li>
        <li>
          <strong>Facilidade de uso e interface do usuário -&nbsp;</strong>Os sites são definidos por sua interface; é o
          que os torna atraentes de usar e não é diferente no setor de investimentos. Encontrar um site com boa aparência
          e fácil de usar é crucial para uma experiência positiva de investimento.
        </li>
        <li>
          <strong>Caixa -&nbsp;</strong>Existe uma boa seleção de métodos bancários? O site é seguro? É fácil depositar
          fundos? Os saques são processados &ZeroWidthSpace;&ZeroWidthSpace;rapidamente? Do início ao fim, é um processo
          fácil e rápido?
        </li>
      </ul>
      <p>Além disso, muitos mais...</p>
      <h2><strong>5 fatos interessantes sobre o mercado de ações&nbsp;</strong></h2>
      <ol>
        <li>
          O termo&nbsp;<strong>blue chip&nbsp;</strong>é um apelido de prestígio reservado para empresas que negociam alto
          no mercado de ações e são conhecidas nacionalmente, o nome vem do chip de pôquer de maior valor, que é
          azul.&lt;/ li &gt;
        </li>
        <li>
          Uma ação autoriza dà direito ao proprietário dessa ação a literalmente uma participação na&nbsp;<strong
            >propriedade da empresa&nbsp;</strong
          >.
        </li>
        <li>
          A Amazon alcançou quase um&nbsp;<strong>ganho de 100%&nbsp;</strong>no ponto mais alto de 2018, terminando o ano
          com 49% de aumento!
        </li>
        <li>
          O início de cada dia de negociação na Bolsa de Nova York começa com o&nbsp;<strong
            >toque de um sino real&nbsp;</strong
          >às 9:30, isso acontece desde 1903.
        </li>
        <li>
          Em 1972, uma reunião de 24 dos maiores comerciantes resultou na criação do mercado, a New York Stock Exchange
          (NYSE). Eles se encontraram&nbsp;<strong>fora de Wall Street para negociar ações&nbsp;</strong>.
        </li>
      </ol>
    `
  },

  register: {
    title: 'Antes de se ir embora...',
    description: 'Os mercados estão se movendo rapidamente. Não perca a chance de lucrar com as últimas altas e baixas.Registre-se hoje e você ficará surpreso ao retirar seu lucro de 2 semanas!',
    registerNow: 'Abra sua conta agora!',
    startTradingNow: 'Comece já a negociar',
    terms: 'Ao preencher este formulário, entendo que serei redirecionado para um parceiro comercial de terceiros e que minhas informações pessoais serão compartilhadas.',
    fullName: 'Nome completo',
    email: 'E-mail',
    howOldAreU: 'Quantos anos você tem?',
    loading: 'Cadastro...'
  },

  footer: {
    description: `
      <p>Risk Warning:</p>
      <p>When investing your capital is at risk.</p>
      <p><br /></p>
      <p>
        Past performance is not an indication of future results. Any investing history presented is less than 5
        years old unless otherwise stated and may not suffice as a basis for investment decisions. Prices may go
        down as well as up, prices can fluctuate widely and you may lose all of or more than the amount you invest.
        Investing is not suitable for everyone; please ensure that you have fully understood the risks and
        legalities involved. If you are unsure, seek independent financial, legal, tax and/or accounting advice.
        This website does not provide investment, financial, legal, tax or accounting advice. Your investment may
        not qualify for investor protection in your country or state of residence, so please conduct your own due
        diligence.
      </p>
      <p>
        {0} is an independent comparison platform and information service that aims to provide you with
        the tools you need to make better decisions. While we are independent, the offers that appear on this site
        are from companies from which {0} receives compensation.&nbsp;
      </p>
      <p>
        Your investment may not qualify for investor protection in your country or state of residence, so please
        conduct your own due diligence. This website is free for you to use but we may receive commission from the
        companies we feature on this site.
      </p>
      <p>
      Wellgard Enterprise Inc <br>
      216913 Suite 1 Second floor , Sound & Vision House, Francis Rachel Street <br>
      Victoria Mahe<br>
       Seychelles
      </p>
    `
  },

  month: {
    "January": "Janeiro",
    "February": "Fevereiro",
    "March": "Março",
    "April": "Abril",
    "May": "Maio",
    "June": "Junho",
    "July": "Julho",
    "August": "Agosto",
    "September": "Setembro",
    "October": "Outubro",
    "November": "Novembro",
    "December": "dezembro"
  }
};
