<template>
  <div class="relative flex flex-col min-h-screen antialiased">
    <Header></Header>

    <main class="flex flex-1 pageBackgroundColor">
      <slot />
    </main>

    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/view/components/layout/Header";
import Footer from "@/view/components/layout/Footer";

export default {
  name: "Default",
  components: {
    Header,
    Footer
  }
};
</script>
