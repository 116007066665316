export default {
  // Common translations

  termsConditions: 'Terms and Conditions',
  privacy: 'Privacy Policy',
  disclaimer: 'Disclaimer',
  'min-deposit': 'Min. Deposit',
  'user-score': 'User Score',
  'start-trading': 'Start Trading',
  'more-info': 'More Info',
  description: '',
  'payment-methods': 'Payment Methods',
  'full-regulations-list': 'Full regulations list',
  'other-recommended-partners': 'Other Recommended Partners',

  // Offers list

  offers: {
    'immediate-edge': {
      description: '',
      disclaimer: 'We are aware of numerous websites which use false celebrity endorsements to send customers to unregulated brokers or call centers. Our website will only send customers to partners who have provided us with the required regulatory assurances. This is a marketing offer and our regulated partners will indicate what services they have available upon registration.',
      keyFeatures: [
        '67% Users Report Profit from their First Deposit',
        'Reported 44% Success Rate in High-Risk Investments',
        'Get Support From A Free Advisor'
      ],
      logoFeatured: '/images/immediate-edge.webp',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Immediate Edge',
      paymentMethods: ['Credit Card', 'Debit Card', 'Wire Transfer'],
      starRating: 97,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/27P3D6/'
    },
    'bitcoin-system': {
      description: '',
      disclaimer: 'We are aware of numerous websites which use false celebrity endorsements to send customers to unregulated brokers or call centers. Our website will only send customers to partners who have provided us with the required regulatory assurances. This is a marketing offer and our regulated partners will indicate what services they have available upon registration.',
      keyFeatures: [
        'Get Support From A Free Advisor',
        'Reported 61% Success Rate in High-Risk Investments',
        'New Market-Leading Interface 2022'
      ],
      logoFeatured: '/images/bitcoin-system.svg',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin System',
      paymentMethods: ['Credit Card', 'Debit Card', 'Wire Transfer'],
      starRating: 91,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2CTPL/'
    },
    'bitcoin-bot': {
      description: '',
      disclaimer: 'We are aware of numerous websites which use false celebrity endorsements to send customers to unregulated brokers or call centers. Our website will only send customers to partners who have provided us with the required regulatory assurances. This is a marketing offer and our regulated partners will indicate what services they have available upon registration.',
      keyFeatures: [
        'Join Users in Gaining Reported 35% Minimum Profits',
        'Simple and Secure Interface',
        'Get Support From A Free Advisor'
      ],
      logoFeatured: '/images/bitcoinbot.jpg',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Bot',
      paymentMethods: ['Credit Card', 'Debit Card', 'Wire Transfer'],
      starRating: 89,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/SDFZCK/'
    },
    'bitcoin-era': {
      description: '',
      disclaimer: 'We are aware of numerous websites which use false celebrity endorsements to send customers to unregulated brokers or call centers. Our website will only send customers to partners who have provided us with the required regulatory assurances. This is a marketing offer and our regulated partners will indicate what services they have available upon registration.',
      keyFeatures: [
        '62% Users Report Profit from their First Deposit',
        'Reported 31% Success Rate in High-Risk Investments',
        'Get Support From A Free Advisor'
      ],
      logoFeatured: '/images/bitcoin-era.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Era',
      paymentMethods: ['Credit Card', 'Debit Card', 'Wire Transfer'],
      starRating: 85,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/5XTBKP/'
    },
    'profit-builder': {
      description: '',
      disclaimer: 'We are aware of numerous websites which use false celebrity endorsements to send customers to unregulated brokers or call centers. Our website will only send customers to partners who have provided us with the required regulatory assurances. This is a marketing offer and our regulated partners will indicate what services they have available upon registration.',
      keyFeatures: [
        '62% Users Report Profit from their First Deposit',
        'Reported 31% Success Rate in High-Risk Investments',
        'Get Support From A Free Advisor'
      ],
      logoFeatured: '/images/profitbuilder.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Profit Builder',
      paymentMethods: ['Credit Card', 'Debit Card', 'Wire Transfer'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/LDF3B4/'
    },
    'greenwood-formula': {
      description: '',
      disclaimer: 'We are aware of numerous websites which use false celebrity endorsements to send customers to unregulated brokers or call centers. Our website will only send customers to partners who have provided us with the required regulatory assurances. This is a marketing offer and our regulated partners will indicate what services they have available upon registration.',
      keyFeatures: [
        '62% Users Report Profit from their First Deposit',
        'Reported 31% Success Rate in High-Risk Investments',
        'Get Support From A Free Advisor'
      ],
      logoFeatured: '/images/greenwood-formula.svg',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Greenwood Formula',
      paymentMethods: ['Credit Card', 'Debit Card', 'Wire Transfer'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2BR65RM/'
    },
    '1k-in-1day': {
      description: '',
      disclaimer: 'We are aware of numerous websites which use false celebrity endorsements to send customers to unregulated brokers or call centers. Our website will only send customers to partners who have provided us with the required regulatory assurances. This is a marketing offer and our regulated partners will indicate what services they have available upon registration.',
      keyFeatures: [
        '62% Users Report Profit from their First Deposit',
        'Reported 31% Success Rate in High-Risk Investments',
        'Get Support From A Free Advisor'
      ],
      logoFeatured: '/images/1k-in-1day.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: '1K in 1Day',
      paymentMethods: ['Credit Card', 'Debit Card', 'Wire Transfer'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2DZ926C/'
    },
    'amazon-stocks-investment': {
      description: '',
      disclaimer: 'We are aware of numerous websites which use false celebrity endorsements to send customers to unregulated brokers or call centers. Our website will only send customers to partners who have provided us with the required regulatory assurances. This is a marketing offer and our regulated partners will indicate what services they have available upon registration.',
      keyFeatures: [
        '62% Users Report Profit from their First Deposit',
        'Reported 31% Success Rate in High-Risk Investments',
        'Get Support From A Free Advisor'
      ],
      logoFeatured: '/images/amazon-stocks-investment.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Amazon Stocks Investment',
      paymentMethods: ['Credit Card', 'Debit Card', 'Wire Transfer'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/ZDXHZQ/'
    },
    'anonsystem': {
      description: '',
      disclaimer: 'We are aware of numerous websites which use false celebrity endorsements to send customers to unregulated brokers or call centers. Our website will only send customers to partners who have provided us with the required regulatory assurances. This is a marketing offer and our regulated partners will indicate what services they have available upon registration.',
      keyFeatures: [
        '62% Users Report Profit from their First Deposit',
        'Reported 31% Success Rate in High-Risk Investments',
        'Get Support From A Free Advisor'
      ],
      logoFeatured: '/images/anonsystem.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Anonsystem',
      paymentMethods: ['Credit Card', 'Debit Card', 'Wire Transfer'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/5FZH7M/'
    },
    'bitcoin-aussie': {
      description: '',
      disclaimer: 'We are aware of numerous websites which use false celebrity endorsements to send customers to unregulated brokers or call centers. Our website will only send customers to partners who have provided us with the required regulatory assurances. This is a marketing offer and our regulated partners will indicate what services they have available upon registration.',
      keyFeatures: [
        '62% Users Report Profit from their First Deposit',
        'Reported 31% Success Rate in High-Risk Investments',
        'Get Support From A Free Advisor'
      ],
      logoFeatured: '/images/bitcoin-aussie.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Aussie',
      paymentMethods: ['Credit Card', 'Debit Card', 'Wire Transfer'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/59RT1S/'
    },
    'bitcoin-canuck-system': {
      description: '',
      disclaimer: 'We are aware of numerous websites which use false celebrity endorsements to send customers to unregulated brokers or call centers. Our website will only send customers to partners who have provided us with the required regulatory assurances. This is a marketing offer and our regulated partners will indicate what services they have available upon registration.',
      keyFeatures: [
        '62% Users Report Profit from their First Deposit',
        'Reported 31% Success Rate in High-Risk Investments',
        'Get Support From A Free Advisor'
      ],
      logoFeatured: '/images/bitcoin-canuck-system.svg',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Canuck System',
      paymentMethods: ['Credit Card', 'Debit Card', 'Wire Transfer'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/SJLLKD/'
    },
    '1k-daily-profit': {
      description: '',
      disclaimer: 'We are aware of numerous websites which use false celebrity endorsements to send customers to unregulated brokers or call centers. Our website will only send customers to partners who have provided us with the required regulatory assurances. This is a marketing offer and our regulated partners will indicate what services they have available upon registration.',
      keyFeatures: [
        '62% Users Report Profit from their First Deposit',
        'Reported 31% Success Rate in High-Risk Investments',
        'Get Support From A Free Advisor'
      ],
      logoFeatured: '/images/1k-daily-profit.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: '1K Daily Profit',
      paymentMethods: ['Credit Card', 'Debit Card', 'Wire Transfer'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/28NT2W5/'
    },
    'bitcoin-gemini': {
      description: '',
      disclaimer: 'We are aware of numerous websites which use false celebrity endorsements to send customers to unregulated brokers or call centers. Our website will only send customers to partners who have provided us with the required regulatory assurances. This is a marketing offer and our regulated partners will indicate what services they have available upon registration.',
      keyFeatures: [
        '62% Users Report Profit from their First Deposit',
        'Reported 31% Success Rate in High-Risk Investments',
        'Get Support From A Free Advisor'
      ],
      logoFeatured: '/images/bitcoin-gemini.svg',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Gemini',
      paymentMethods: ['Credit Card', 'Debit Card', 'Wire Transfer'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/4P586J/'
    },
    'bitcoin-kiwi-system': {
      description: '',
      disclaimer: 'We are aware of numerous websites which use false celebrity endorsements to send customers to unregulated brokers or call centers. Our website will only send customers to partners who have provided us with the required regulatory assurances. This is a marketing offer and our regulated partners will indicate what services they have available upon registration.',
      keyFeatures: [
        '62% Users Report Profit from their First Deposit',
        'Reported 31% Success Rate in High-Risk Investments',
        'Get Support From A Free Advisor'
      ],
      logoFeatured: '/images/bitcoin-kiwi-system.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Kiwi System',
      paymentMethods: ['Credit Card', 'Debit Card', 'Wire Transfer'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/4JZKZP/'
    },
    'bitcoin-lifestyle': {
      description: '',
      disclaimer: 'We are aware of numerous websites which use false celebrity endorsements to send customers to unregulated brokers or call centers. Our website will only send customers to partners who have provided us with the required regulatory assurances. This is a marketing offer and our regulated partners will indicate what services they have available upon registration.',
      keyFeatures: [
        '62% Users Report Profit from their First Deposit',
        'Reported 31% Success Rate in High-Risk Investments',
        'Get Support From A Free Advisor'
      ],
      logoFeatured: '/images/bitcoin-lifestyle.jpg',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Lifestyle',
      paymentMethods: ['Credit Card', 'Debit Card', 'Wire Transfer'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/4HKP84/'
    },
    'bitcoin-malay-system': {
      description: '',
      disclaimer: 'We are aware of numerous websites which use false celebrity endorsements to send customers to unregulated brokers or call centers. Our website will only send customers to partners who have provided us with the required regulatory assurances. This is a marketing offer and our regulated partners will indicate what services they have available upon registration.',
      keyFeatures: [
        '62% Users Report Profit from their First Deposit',
        'Reported 31% Success Rate in High-Risk Investments',
        'Get Support From A Free Advisor'
      ],
      logoFeatured: '/images/bitcoin-malay-system.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Malay System',
      paymentMethods: ['Credit Card', 'Debit Card', 'Wire Transfer'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/4CF329/'
    },
    'bitcoin-prime': {
      description: '',
      disclaimer: 'We are aware of numerous websites which use false celebrity endorsements to send customers to unregulated brokers or call centers. Our website will only send customers to partners who have provided us with the required regulatory assurances. This is a marketing offer and our regulated partners will indicate what services they have available upon registration.',
      keyFeatures: [
        '62% Users Report Profit from their First Deposit',
        'Reported 31% Success Rate in High-Risk Investments',
        'Get Support From A Free Advisor'
      ],
      logoFeatured: '/images/bitcoin-prime.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Prime',
      paymentMethods: ['Credit Card', 'Debit Card', 'Wire Transfer'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/26K5382/'
    },
    'tradermate': {
      description: '',
      disclaimer: 'We are aware of numerous websites which use false celebrity endorsements to send customers to unregulated brokers or call centers. Our website will only send customers to partners who have provided us with the required regulatory assurances. This is a marketing offer and our regulated partners will indicate what services they have available upon registration.',
      keyFeatures: [
        '62% Users Report Profit from their First Deposit',
        'Reported 31% Success Rate in High-Risk Investments',
        'Get Support From A Free Advisor'
      ],
      logoFeatured: '/images/tradermate-compliant.svg',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'TraderMate',
      paymentMethods: ['Credit Card', 'Debit Card', 'Wire Transfer'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/XN48XM/'
    },
    'bitcoin-profit': {
      description: '',
      disclaimer: 'We are aware of numerous websites which use false celebrity endorsements to send customers to unregulated brokers or call centers. Our website will only send customers to partners who have provided us with the required regulatory assurances. This is a marketing offer and our regulated partners will indicate what services they have available upon registration.',
      keyFeatures: [
        '62% Users Report Profit from their First Deposit',
        'Reported 31% Success Rate in High-Risk Investments',
        'Get Support From A Free Advisor'
      ],
      logoFeatured: '/images/bitcoin-profit.svg',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Profit',
      paymentMethods: ['Credit Card', 'Debit Card', 'Wire Transfer'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/ZCJM85/'
    },
    'bitcoin-buyer': {
      description: '',
      disclaimer: 'We are aware of numerous websites which use false celebrity endorsements to send customers to unregulated brokers or call centers. Our website will only send customers to partners who have provided us with the required regulatory assurances. This is a marketing offer and our regulated partners will indicate what services they have available upon registration.',
      keyFeatures: [
        '62% Users Report Profit from their First Deposit',
        'Reported 31% Success Rate in High-Risk Investments',
        'Get Support From A Free Advisor'
      ],
      logoFeatured: '/images/bitcoin-buyer.svg',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Buyer',
      paymentMethods: ['Credit Card', 'Debit Card', 'Wire Transfer'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/ZGBDPC/'
    },
    'yuan-payments': {
      description: '',
      disclaimer: 'We are aware of numerous websites which use false celebrity endorsements to send customers to unregulated brokers or call centers. Our website will only send customers to partners who have provided us with the required regulatory assurances. This is a marketing offer and our regulated partners will indicate what services they have available upon registration.',
      keyFeatures: [
        '62% Users Report Profit from their First Deposit',
        'Reported 31% Success Rate in High-Risk Investments',
        'Get Support From A Free Advisor'
      ],
      logoFeatured: '/images/yuan-payments.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Yuan Payments',
      paymentMethods: ['Credit Card', 'Debit Card', 'Wire Transfer'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/22C3ZNF/'
    },
    'crypto-bank': {
      description: '',
      disclaimer: 'We are aware of numerous websites which use false celebrity endorsements to send customers to unregulated brokers or call centers. Our website will only send customers to partners who have provided us with the required regulatory assurances. This is a marketing offer and our regulated partners will indicate what services they have available upon registration.',
      keyFeatures: [
        '62% Users Report Profit from their First Deposit',
        'Reported 31% Success Rate in High-Risk Investments',
        'Get Support From A Free Advisor'
      ],
      logoFeatured: '/images/crypto-bank.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Crypto Bank',
      paymentMethods: ['Credit Card', 'Debit Card', 'Wire Transfer'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/22FTQ5M/'
    },
    'crypto-engine': {
      description: '',
      disclaimer: 'We are aware of numerous websites which use false celebrity endorsements to send customers to unregulated brokers or call centers. Our website will only send customers to partners who have provided us with the required regulatory assurances. This is a marketing offer and our regulated partners will indicate what services they have available upon registration.',
      keyFeatures: [
        '62% Users Report Profit from their First Deposit',
        'Reported 31% Success Rate in High-Risk Investments',
        'Get Support From A Free Advisor'
      ],
      logoFeatured: '/images/crypto-engine.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Crypto Engine',
      paymentMethods: ['Credit Card', 'Debit Card', 'Wire Transfer'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/22H8LW8/'
    },
    'bitcoin-pro': {
      description: '',
      disclaimer: 'We are aware of numerous websites which use false celebrity endorsements to send customers to unregulated brokers or call centers. Our website will only send customers to partners who have provided us with the required regulatory assurances. This is a marketing offer and our regulated partners will indicate what services they have available upon registration.',
      keyFeatures: [
        '62% Users Report Profit from their First Deposit',
        'Reported 31% Success Rate in High-Risk Investments',
        'Get Support From A Free Advisor'
      ],
      logoFeatured: '/images/bitcoin-pro.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Pro',
      paymentMethods: ['Credit Card', 'Debit Card', 'Wire Transfer'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/22L2DCG/'
    },

    'bitcoin-bank': {
      description: '',
      disclaimer: 'We are aware of numerous websites which use false celebrity endorsements to send customers to unregulated brokers or call centers. Our website will only send customers to partners who have provided us with the required regulatory assurances. This is a marketing offer and our regulated partners will indicate what services they have available upon registration.',
      keyFeatures: [
        '62% Users Report Profit from their First Deposit',
        'Reported 31% Success Rate in High-Risk Investments',
        'Get Support From A Free Advisor'
      ],
      logoFeatured: '/images/bitcoin-bank.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Bank',
      paymentMethods: ['Credit Card', 'Debit Card', 'Wire Transfer'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/22RKX9W/'
    },
    'tesler': {
      description: '',
      disclaimer: 'We are aware of numerous websites which use false celebrity endorsements to send customers to unregulated brokers or call centers. Our website will only send customers to partners who have provided us with the required regulatory assurances. This is a marketing offer and our regulated partners will indicate what services they have available upon registration.',
      keyFeatures: [
        '62% Users Report Profit from their First Deposit',
        'Reported 31% Success Rate in High-Risk Investments',
        'Get Support From A Free Advisor'
      ],
      logoFeatured: '/images/tesler.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Tesler',
      paymentMethods: ['Credit Card', 'Debit Card', 'Wire Transfer'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/22SZS2H/'
    },
    'bitcoin-up': {
      description: '',
      disclaimer: 'We are aware of numerous websites which use false celebrity endorsements to send customers to unregulated brokers or call centers. Our website will only send customers to partners who have provided us with the required regulatory assurances. This is a marketing offer and our regulated partners will indicate what services they have available upon registration.',
      keyFeatures: [
        '62% Users Report Profit from their First Deposit',
        'Reported 31% Success Rate in High-Risk Investments',
        'Get Support From A Free Advisor'
      ],
      logoFeatured: '/images/bitcoin-up.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin UP',
      paymentMethods: ['Credit Card', 'Debit Card', 'Wire Transfer'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/23PZP9F/'
    },
    'nft-code': {
      description: '',
      disclaimer: 'We are aware of numerous websites which use false celebrity endorsements to send customers to unregulated brokers or call centers. Our website will only send customers to partners who have provided us with the required regulatory assurances. This is a marketing offer and our regulated partners will indicate what services they have available upon registration.',
      keyFeatures: [
        '62% Users Report Profit from their First Deposit',
        'Reported 31% Success Rate in High-Risk Investments',
        'Get Support From A Free Advisor'
      ],
      logoFeatured: '/images/nft-code.svg',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'NFT Code',
      paymentMethods: ['Credit Card', 'Debit Card', 'Wire Transfer'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/23W5CH8/'
    },
    'bit-iq': {
      description: '',
      disclaimer: 'We are aware of numerous websites which use false celebrity endorsements to send customers to unregulated brokers or call centers. Our website will only send customers to partners who have provided us with the required regulatory assurances. This is a marketing offer and our regulated partners will indicate what services they have available upon registration.',
      keyFeatures: [
        '62% Users Report Profit from their First Deposit',
        'Reported 31% Success Rate in High-Risk Investments',
        'Get Support From A Free Advisor'
      ],
      logoFeatured: '/images/bit-iq.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bit IQ',
      paymentMethods: ['Credit Card', 'Debit Card', 'Wire Transfer'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/25TNNXJ/'
    },
    'bitcoin-code': {
      description: '',
      disclaimer: 'We are aware of numerous websites which use false celebrity endorsements to send customers to unregulated brokers or call centers. Our website will only send customers to partners who have provided us with the required regulatory assurances. This is a marketing offer and our regulated partners will indicate what services they have available upon registration.',
      keyFeatures: [
        '62% Users Report Profit from their First Deposit',
        'Reported 31% Success Rate in High-Risk Investments',
        'Get Support From A Free Advisor'
      ],
      logoFeatured: '/images/bitcoin-code.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Code',
      paymentMethods: ['Credit Card', 'Debit Card', 'Wire Transfer'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/26DZF27/'
    },
    'bitcoin-fast-profit': {
      description: '',
      disclaimer: 'We are aware of numerous websites which use false celebrity endorsements to send customers to unregulated brokers or call centers. Our website will only send customers to partners who have provided us with the required regulatory assurances. This is a marketing offer and our regulated partners will indicate what services they have available upon registration.',
      keyFeatures: [
        '62% Users Report Profit from their First Deposit',
        'Reported 31% Success Rate in High-Risk Investments',
        'Get Support From A Free Advisor'
      ],
      logoFeatured: '/images/bitcoin-fast-profit.svg',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Fast Profit',
      paymentMethods: ['Credit Card', 'Debit Card', 'Wire Transfer'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/26GC9QS/'
    },
    'bitcoin-trader': {
      description: '',
      disclaimer: 'We are aware of numerous websites which use false celebrity endorsements to send customers to unregulated brokers or call centers. Our website will only send customers to partners who have provided us with the required regulatory assurances. This is a marketing offer and our regulated partners will indicate what services they have available upon registration.',
      keyFeatures: [
        '62% Users Report Profit from their First Deposit',
        'Reported 31% Success Rate in High-Risk Investments',
        'Get Support From A Free Advisor'
      ],
      logoFeatured: '/images/bitcoin-trader.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Trader',
      paymentMethods: ['Credit Card', 'Debit Card', 'Wire Transfer'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/26HQ6HF/'
    },
    'bitcoin-millionaire-pro-system': {
      description: '',
      disclaimer: 'We are aware of numerous websites which use false celebrity endorsements to send customers to unregulated brokers or call centers. Our website will only send customers to partners who have provided us with the required regulatory assurances. This is a marketing offer and our regulated partners will indicate what services they have available upon registration.',
      keyFeatures: [
        '62% Users Report Profit from their First Deposit',
        'Reported 31% Success Rate in High-Risk Investments',
        'Get Support From A Free Advisor'
      ],
      logoFeatured: '/images/bitcoin-millionaire-pro-system.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Millionaire Pro System',
      paymentMethods: ['Credit Card', 'Debit Card', 'Wire Transfer'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/26LHXZM/'
    },
    'bitcoin-circuit': {
      description: '',
      disclaimer: 'We are aware of numerous websites which use false celebrity endorsements to send customers to unregulated brokers or call centers. Our website will only send customers to partners who have provided us with the required regulatory assurances. This is a marketing offer and our regulated partners will indicate what services they have available upon registration.',
      keyFeatures: [
        '62% Users Report Profit from their First Deposit',
        'Reported 31% Success Rate in High-Risk Investments',
        'Get Support From A Free Advisor'
      ],
      logoFeatured: '/images/bitcoin-circuit.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Circuit',
      paymentMethods: ['Credit Card', 'Debit Card', 'Wire Transfer'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/26P9PFT/'
    },
    'bitcoin-future': {
      description: '',
      disclaimer: 'We are aware of numerous websites which use false celebrity endorsements to send customers to unregulated brokers or call centers. Our website will only send customers to partners who have provided us with the required regulatory assurances. This is a marketing offer and our regulated partners will indicate what services they have available upon registration.',
      keyFeatures: [
        '62% Users Report Profit from their First Deposit',
        'Reported 31% Success Rate in High-Risk Investments',
        'Get Support From A Free Advisor'
      ],
      logoFeatured: '/images/bitcoin-future.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Future',
      paymentMethods: ['Credit Card', 'Debit Card', 'Wire Transfer'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/26QNL6G/'
    },
    'bitqt': {
      description: '',
      disclaimer: 'We are aware of numerous websites which use false celebrity endorsements to send customers to unregulated brokers or call centers. Our website will only send customers to partners who have provided us with the required regulatory assurances. This is a marketing offer and our regulated partners will indicate what services they have available upon registration.',
      keyFeatures: [
        '62% Users Report Profit from their First Deposit',
        'Reported 31% Success Rate in High-Risk Investments',
        'Get Support From A Free Advisor'
      ],
      logoFeatured: '/images/bitqt.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'BitQT',
      paymentMethods: ['Credit Card', 'Debit Card', 'Wire Transfer'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/28Q7XLQ/'
    },
    'trade-2-earn': {
      description: '',
      disclaimer: 'We are aware of numerous websites which use false celebrity endorsements to send customers to unregulated brokers or call centers. Our website will only send customers to partners who have provided us with the required regulatory assurances. This is a marketing offer and our regulated partners will indicate what services they have available upon registration.',
      keyFeatures: [
        '62% Users Report Profit from their First Deposit',
        'Reported 31% Success Rate in High-Risk Investments',
        'Get Support From A Free Advisor'
      ],
      logoFeatured: '/images/trade-2-earn.svg',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Trade 2 Earn',
      paymentMethods: ['Credit Card', 'Debit Card', 'Wire Transfer'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/28T1P3Z/'
    },
    'bitcoin-bank-breaker': {
      description: '',
      disclaimer: 'We are aware of numerous websites which use false celebrity endorsements to send customers to unregulated brokers or call centers. Our website will only send customers to partners who have provided us with the required regulatory assurances. This is a marketing offer and our regulated partners will indicate what services they have available upon registration.',
      keyFeatures: [
        '62% Users Report Profit from their First Deposit',
        'Reported 31% Success Rate in High-Risk Investments',
        'Get Support From A Free Advisor'
      ],
      logoFeatured: '/images/bitcoin-bank-breaker.svg',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Bank Breaker',
      paymentMethods: ['Credit Card', 'Debit Card', 'Wire Transfer'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2BZPNQ3/'
    },
    'bitcoin-billionaire': {
      description: '',
      disclaimer: 'We are aware of numerous websites which use false celebrity endorsements to send customers to unregulated brokers or call centers. Our website will only send customers to partners who have provided us with the required regulatory assurances. This is a marketing offer and our regulated partners will indicate what services they have available upon registration.',
      keyFeatures: [
        '62% Users Report Profit from their First Deposit',
        'Reported 31% Success Rate in High-Risk Investments',
        'Get Support From A Free Advisor'
      ],
      logoFeatured: '/images/bitcoin-billionaire.svg',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Billionaire',
      paymentMethods: ['Credit Card', 'Debit Card', 'Wire Transfer'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2DPBLHB/'
    },
    'bitcoin-loophole': {
      description: '',
      disclaimer: 'We are aware of numerous websites which use false celebrity endorsements to send customers to unregulated brokers or call centers. Our website will only send customers to partners who have provided us with the required regulatory assurances. This is a marketing offer and our regulated partners will indicate what services they have available upon registration.',
      keyFeatures: [
        '62% Users Report Profit from their First Deposit',
        'Reported 31% Success Rate in High-Risk Investments',
        'Get Support From A Free Advisor'
      ],
      logoFeatured: '/images/bitcoin-loophole.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Loophole',
      paymentMethods: ['Credit Card', 'Debit Card', 'Wire Transfer'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2DQPH7X/'
    },
    'bitcoin-motion': {
      description: '',
      disclaimer: 'We are aware of numerous websites which use false celebrity endorsements to send customers to unregulated brokers or call centers. Our website will only send customers to partners who have provided us with the required regulatory assurances. This is a marketing offer and our regulated partners will indicate what services they have available upon registration.',
      keyFeatures: [
        '62% Users Report Profit from their First Deposit',
        'Reported 31% Success Rate in High-Risk Investments',
        'Get Support From A Free Advisor'
      ],
      logoFeatured: '/images/bitcoin-motion.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Motion',
      paymentMethods: ['Credit Card', 'Debit Card', 'Wire Transfer'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2DS4CZJ/'
    },
    'profit-bitcoin': {
      description: '',
      disclaimer: 'We are aware of numerous websites which use false celebrity endorsements to send customers to unregulated brokers or call centers. Our website will only send customers to partners who have provided us with the required regulatory assurances. This is a marketing offer and our regulated partners will indicate what services they have available upon registration.',
      keyFeatures: [
        '62% Users Report Profit from their First Deposit',
        'Reported 31% Success Rate in High-Risk Investments',
        'Get Support From A Free Advisor'
      ],
      logoFeatured: '/images/profit-bitcoin.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Profit Bitcoin',
      paymentMethods: ['Credit Card', 'Debit Card', 'Wire Transfer'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2DTH8P5/'
    },
    'bitcoin-evolution': {
      description: '',
      disclaimer: 'We are aware of numerous websites which use false celebrity endorsements to send customers to unregulated brokers or call centers. Our website will only send customers to partners who have provided us with the required regulatory assurances. This is a marketing offer and our regulated partners will indicate what services they have available upon registration.',
      keyFeatures: [
        '62% Users Report Profit from their First Deposit',
        'Reported 31% Success Rate in High-Risk Investments',
        'Get Support From A Free Advisor'
      ],
      logoFeatured: '/images/bitcoin-evolution.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Evolution',
      paymentMethods: ['Credit Card', 'Debit Card', 'Wire Transfer'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2DWW5FQ/'
    },
    'bitcoin-champion': {
      description: '',
      disclaimer: 'We are aware of numerous websites which use false celebrity endorsements to send customers to unregulated brokers or call centers. Our website will only send customers to partners who have provided us with the required regulatory assurances. This is a marketing offer and our regulated partners will indicate what services they have available upon registration.',
      keyFeatures: [
        '62% Users Report Profit from their First Deposit',
        'Reported 31% Success Rate in High-Risk Investments',
        'Get Support From A Free Advisor'
      ],
      logoFeatured: '/images/bitcoin-champion.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Champion',
      paymentMethods: ['Credit Card', 'Debit Card', 'Wire Transfer'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2F1MWWZ/'
    },
    'bitcoin-money': {
      description: '',
      disclaimer: 'We are aware of numerous websites which use false celebrity endorsements to send customers to unregulated brokers or call centers. Our website will only send customers to partners who have provided us with the required regulatory assurances. This is a marketing offer and our regulated partners will indicate what services they have available upon registration.',
      keyFeatures: [
        '62% Users Report Profit from their First Deposit',
        'Reported 31% Success Rate in High-Risk Investments',
        'Get Support From A Free Advisor'
      ],
      logoFeatured: '/images/bitcoin-money.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Money',
      paymentMethods: ['Credit Card', 'Debit Card', 'Wire Transfer'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2F32RMK/'
    },
    'bitcoin-trend-app': {
      description: '',
      disclaimer: 'We are aware of numerous websites which use false celebrity endorsements to send customers to unregulated brokers or call centers. Our website will only send customers to partners who have provided us with the required regulatory assurances. This is a marketing offer and our regulated partners will indicate what services they have available upon registration.',
      keyFeatures: [
        '62% Users Report Profit from their First Deposit',
        'Reported 31% Success Rate in High-Risk Investments',
        'Get Support From A Free Advisor'
      ],
      logoFeatured: '/images/bitcoin-trend-app.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Trend App',
      paymentMethods: ['Credit Card', 'Debit Card', 'Wire Transfer'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2F4FND6/'
    },
    'crypto-comeback-pro': {
      description: '',
      disclaimer: 'We are aware of numerous websites which use false celebrity endorsements to send customers to unregulated brokers or call centers. Our website will only send customers to partners who have provided us with the required regulatory assurances. This is a marketing offer and our regulated partners will indicate what services they have available upon registration.',
      keyFeatures: [
        '62% Users Report Profit from their First Deposit',
        'Reported 31% Success Rate in High-Risk Investments',
        'Get Support From A Free Advisor'
      ],
      logoFeatured: '/images/crypto-comeback-pro.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Crypto Comeback Pro',
      paymentMethods: ['Credit Card', 'Debit Card', 'Wire Transfer'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2F5SK4R/'
    },
    'ethereum-system': {
      description: '',
      disclaimer: 'We are aware of numerous websites which use false celebrity endorsements to send customers to unregulated brokers or call centers. Our website will only send customers to partners who have provided us with the required regulatory assurances. This is a marketing offer and our regulated partners will indicate what services they have available upon registration.',
      keyFeatures: [
        '62% Users Report Profit from their First Deposit',
        'Reported 31% Success Rate in High-Risk Investments',
        'Get Support From A Free Advisor'
      ],
      logoFeatured: '/images/ethereum-system.svg',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Ethereum System',
      paymentMethods: ['Credit Card', 'Debit Card', 'Wire Transfer'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2F77FTD/'
    },
    'the-bitcoin-millionaire': {
      description: '',
      disclaimer: 'We are aware of numerous websites which use false celebrity endorsements to send customers to unregulated brokers or call centers. Our website will only send customers to partners who have provided us with the required regulatory assurances. This is a marketing offer and our regulated partners will indicate what services they have available upon registration.',
      keyFeatures: [
        '62% Users Report Profit from their First Deposit',
        'Reported 31% Success Rate in High-Risk Investments',
        'Get Support From A Free Advisor'
      ],
      logoFeatured: '/images/the-bitcoin-millionaire.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'The Bitcoin Millionaire',
      paymentMethods: ['Credit Card', 'Debit Card', 'Wire Transfer'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2F8LBL1/'
    }
  },

  home: {
    title: '{0} - Recommended for {1} {2}',
    title2: 'Find the safest Places to Invest',
    description: `
      {0} analyses the market buy/sell trends in order to take advantage and profit from volatility. You do
      not need to understand technical investing in order to be able to use the software and this system has received
      numerous positive reviews online.&nbsp;
    `,

    description2: `
      <h2><strong>How Brokers are Rated -&nbsp;</strong>{0}</h2>
      <p>
        The {1} team analyse and rigorously review brokers in the industry so you don’t have to. The team
        evaluate a list of no less than 57 items but some key points include:&nbsp;
      </p>
      <ul>
        <li>
          <strong>Quality of Customer Service -&nbsp;</strong>Do they have an easy to use Help section? Do they have
          easy to find contact information? Is the information clear, including hours of operation, country codes etc.?
          Do they offer a variety of customer support methods – e.g. Email, Phone, Chat, Mail? Are they quick to
          respond? Do they offer good service? Are they polite? Are they knowledgeable?
        </li>
        <li>
          <strong>Ease of use and User Interface -&nbsp;</strong>Websites are defined by their interface; it’s what
          makes them attractive to use, and it’s no different within the investment sector. Finding a site that looks
          good and is easy to use is crucial for a positive investment experience.
        </li>
        <li>
          <strong>Cashier -</strong> Is there a good selection of banking methods? Is the site secure? Is it easy to
          deposit funds? Are withdrawals processed quickly? Start to finish, is it an easy and quick process?
        </li>
      </ul>
      <p>Plus many many more...</p>
      <h2><strong>5 Interesting Stock Market Facts</strong></h2>
      <ul>
        <li>
          1. The Stock Market is more than 400 years old!
        </li>
        <li>
          2. One share entitles the owner of that share to literally a share in the&nbsp;<strong
            >ownership of the company</strong
          >.
        </li>
        <li>
          3. The stock market is 70% likely to go up on any year
        </li>
        <li>
          4. The start of every investment day on the New York Stock Exchange starts with the&nbsp;<strong
            >ringing of an actual bell</strong
          >
          at 9:30, this has been happening since 1903.
        </li>
        <li>
          5. September is the most volatile month
        </li>
      </ul>
    `
  },

  register: {
    title: 'Before You Leave...',
    description: 'The markets are moving fast. Don’t miss out on your chance to profit from the latest rises and falls. Register today and You’ll be Surprised after you withdraw your 2-week profit!',
    registerNow: 'Register Now',
    startTradingNow: 'Start Trading Now',
    terms: 'By completing this form I understand that I am going to be redirected to a 3rd party trading partner and that my personal information will be shared.',
    fullName: 'Full Name',
    email: 'E-mail',
    howOldAreU: 'How old are you?',
    loading: 'Registration...'
  },

  footer: {
    description: `
      <p>Risk Warning:</p>
      <p>When investing your capital is at risk.</p>
      <p><br /></p>
      <p>
        Past performance is not an indication of future results. Any investing history presented is less than 5
        years old unless otherwise stated and may not suffice as a basis for investment decisions. Prices may go
        down as well as up, prices can fluctuate widely and you may lose all of or more than the amount you invest.
        Investing is not suitable for everyone; please ensure that you have fully understood the risks and
        legalities involved. If you are unsure, seek independent financial, legal, tax and/or accounting advice.
        This website does not provide investment, financial, legal, tax or accounting advice. Your investment may
        not qualify for investor protection in your country or state of residence, so please conduct your own due
        diligence.
      </p>
      <p>
        {0} is an independent comparison platform and information service that aims to provide you with
        the tools you need to make better decisions. While we are independent, the offers that appear on this site
        are from companies from which {0} receives compensation.&nbsp;
      </p>
      <p>
        Your investment may not qualify for investor protection in your country or state of residence, so please
        conduct your own due diligence. This website is free for you to use but we may receive commission from the
        companies we feature on this site.
      </p>
      <p>
      Wellgard Enterprise Inc <br>
      216913 Suite 1 Second floor , Sound & Vision House, Francis Rachel Street <br>
      Victoria Mahe<br>
       Seychelles
      </p>
    `
  },

  month: {
    "January": "January",
    "February": "February",
    "March": "March",
    "April": "April",
    "May": "May",
    "June": "June",
    "July": "July",
    "August": "August",
    "September": "September",
    "October": "October",
    "November": "November",
    "December": "December"
  }
};
