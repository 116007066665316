export default {
  // Common translations

  termsConditions: 'Términos y condiciones',
  privacy: 'Política de privacidad',
  disclaimer: 'Descargo de responsabilidad',
  'min-deposit': 'Depósito mínimo',
  'user-score': 'Puntuación de los usuarios',
  'start-trading': 'Regístrate ahora',
  'more-info': 'más información',
  description: 'Descripción',
  'payment-methods': 'Métodos de pago',
  'full-regulations-list': 'Lista completa de regulaciones',
  'other-recommended-partners': 'Otros Asociados Recomendados',

  // Offers list

  offers: {
    'immediate-edge': {
      description: '',
      disclaimer: 'Somos conscientes de la gran cantidad de sitios web que recurren a famosos para llevar a los usuarios hasta brókeres y call centers no regulados. Nuestro sitio web solo envía a los usuarios a socios que nos han proporcionado previamente las garantías regulatorias pertinentes. Esta es una oferta mercadotécnica y nuestros socios regulados indicarán qué brindan ras registro.',
      keyFeatures: [
        'Obtenga Apoyo de Asesor Gratis',
        'Los usuarios informan de beneficios desde el primer depósito',
        'Inversión de alto riesgo con una tasa de éxito del 31 %'
      ],
      logoFeatured: '/images/immediate-edge.webp',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Immediate Edge',
      paymentMethods: ['Tarjeta de crédito', 'Tarjeta de débito', 'Transferencia bancaria'],
      starRating: 93,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/27P3D6/'
    },
    'bitcoin-system': {
      description: '',
      disclaimer: 'Somos conscientes de la gran cantidad de sitios web que recurren a famosos para llevar a los usuarios hasta brókeres y call centers no regulados. Nuestro sitio web solo envía a los usuarios a socios que nos han proporcionado previamente las garantías regulatorias pertinentes. Esta es una oferta mercadotécnica y nuestros socios regulados indicarán qué brindan ras registro.',
      keyFeatures: [
        'Obtenga Apoyo de Asesor Gratis',
        'Los usuarios informan de beneficios desde el primer depósito',
        'Inversión de alto riesgo con una tasa de éxito del 31 %'
      ],
      logoFeatured: '/images/bitcoin-system.svg',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin System',
      paymentMethods: ['Tarjeta de crédito', 'Tarjeta de débito', 'Transferencia bancaria'],
      starRating: 91,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2CTPL/'
    },
    'bitcoin-bot': {
      description: '',
      disclaimer: 'Somos conscientes de la gran cantidad de sitios web que recurren a famosos para llevar a los usuarios hasta brókeres y call centers no regulados. Nuestro sitio web solo envía a los usuarios a socios que nos han proporcionado previamente las garantías regulatorias pertinentes. Esta es una oferta mercadotécnica y nuestros socios regulados indicarán qué brindan ras registro.',
      keyFeatures: [
        'Obtenga Apoyo de Asesor Gratis',
        'Los usuarios informan de beneficios desde el primer depósito',
        'Inversión de alto riesgo con una tasa de éxito del 31 %'
      ],
      logoFeatured: '/images/bitcoinbot.jpg',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Bot',
      paymentMethods: ['Tarjeta de crédito', 'Tarjeta de débito', 'Transferencia bancaria'],
      starRating: 98,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/SDFZCK/'
    },
    'bitcoin-era': {
      description: '',
      disclaimer: 'Somos conscientes de la gran cantidad de sitios web que recurren a famosos para llevar a los usuarios hasta brókeres y call centers no regulados. Nuestro sitio web solo envía a los usuarios a socios que nos han proporcionado previamente las garantías regulatorias pertinentes. Esta es una oferta mercadotécnica y nuestros socios regulados indicarán qué brindan ras registro.',
      keyFeatures: [
        'Obtenga Apoyo de Asesor Gratis',
        'Los usuarios informan de beneficios desde el primer depósito',
        'Inversión de alto riesgo con una tasa de éxito del 31 %'
      ],
      logoFeatured: '/images/bitcoin-era.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Era',
      paymentMethods: ['Tarjeta de crédito', 'Tarjeta de débito', 'Transferencia bancaria'],
      starRating: 85,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/5XTBKP/'
    },
    'profit-builder': {
      description: '',
      disclaimer: 'Somos conscientes de la gran cantidad de sitios web que recurren a famosos para llevar a los usuarios hasta brókeres y call centers no regulados. Nuestro sitio web solo envía a los usuarios a socios que nos han proporcionado previamente las garantías regulatorias pertinentes. Esta es una oferta mercadotécnica y nuestros socios regulados indicarán qué brindan ras registro.',
      keyFeatures: [
        'Obtenga Apoyo de Asesor Gratis',
        'Los usuarios informan de beneficios desde el primer depósito',
        'Inversión de alto riesgo con una tasa de éxito del 31 %'
      ],
      logoFeatured: '/images/profitbuilder.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Profit Builder',
      paymentMethods: ['Tarjeta de crédito', 'Tarjeta de débito', 'Transferencia bancaria'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/LDF3B4/'
    },
    '1k-in-1day': {
      description: '',
      disclaimer: 'Somos conscientes de la gran cantidad de sitios web que recurren a famosos para llevar a los usuarios hasta brókeres y call centers no regulados. Nuestro sitio web solo envía a los usuarios a socios que nos han proporcionado previamente las garantías regulatorias pertinentes. Esta es una oferta mercadotécnica y nuestros socios regulados indicarán qué brindan ras registro.',
      keyFeatures: [
        'Obtenga Apoyo de Asesor Gratis',
        'Los usuarios informan de beneficios desde el primer depósito',
        'Inversión de alto riesgo con una tasa de éxito del 31 %'
      ],
      logoFeatured: '/images/1k-in-1day.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: '1K in 1Day',
      paymentMethods: ['Tarjeta de crédito', 'Tarjeta de débito', 'Transferencia bancaria'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2DZ926C/'
    },
    'amazon-stocks-investment': {
      description: '',
      disclaimer: 'Somos conscientes de la gran cantidad de sitios web que recurren a famosos para llevar a los usuarios hasta brókeres y call centers no regulados. Nuestro sitio web solo envía a los usuarios a socios que nos han proporcionado previamente las garantías regulatorias pertinentes. Esta es una oferta mercadotécnica y nuestros socios regulados indicarán qué brindan ras registro.',
      keyFeatures: [
        'Obtenga Apoyo de Asesor Gratis',
        'Los usuarios informan de beneficios desde el primer depósito',
        'Inversión de alto riesgo con una tasa de éxito del 31 %'
      ],
      logoFeatured: '/images/amazon-stocks-investment.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Amazon Stocks Investment',
      paymentMethods: ['Tarjeta de crédito', 'Tarjeta de débito', 'Transferencia bancaria'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/ZDXHZQ/'
    },
    'anonsystem': {
      description: '',
      disclaimer: 'Somos conscientes de la gran cantidad de sitios web que recurren a famosos para llevar a los usuarios hasta brókeres y call centers no regulados. Nuestro sitio web solo envía a los usuarios a socios que nos han proporcionado previamente las garantías regulatorias pertinentes. Esta es una oferta mercadotécnica y nuestros socios regulados indicarán qué brindan ras registro.',
      keyFeatures: [
        'Obtenga Apoyo de Asesor Gratis',
        'Los usuarios informan de beneficios desde el primer depósito',
        'Inversión de alto riesgo con una tasa de éxito del 31 %'
      ],
      logoFeatured: '/images/anonsystem.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Anonsystem',
      paymentMethods: ['Tarjeta de crédito', 'Tarjeta de débito', 'Transferencia bancaria'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/5FZH7M/'
    },
    'tradermate': {
      description: '',
      disclaimer: 'Somos conscientes de la gran cantidad de sitios web que recurren a famosos para llevar a los usuarios hasta brókeres y call centers no regulados. Nuestro sitio web solo envía a los usuarios a socios que nos han proporcionado previamente las garantías regulatorias pertinentes. Esta es una oferta mercadotécnica y nuestros socios regulados indicarán qué brindan ras registro.',
      keyFeatures: [
        'Obtenga Apoyo de Asesor Gratis',
        'Los usuarios informan de beneficios desde el primer depósito',
        'Inversión de alto riesgo con una tasa de éxito del 31 %'
      ],
      logoFeatured: '/images/tradermate.svg',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'TraderMate',
      paymentMethods: ['Tarjeta de crédito', 'Tarjeta de débito', 'Transferencia bancaria'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/XN48XM/'
    },
    'bitcoin-profit': {
      description: '',
      disclaimer: 'Somos conscientes de la gran cantidad de sitios web que recurren a famosos para llevar a los usuarios hasta brókeres y call centers no regulados. Nuestro sitio web solo envía a los usuarios a socios que nos han proporcionado previamente las garantías regulatorias pertinentes. Esta es una oferta mercadotécnica y nuestros socios regulados indicarán qué brindan ras registro.',
      keyFeatures: [
        'Obtenga Apoyo de Asesor Gratis',
        'Los usuarios informan de beneficios desde el primer depósito',
        'Inversión de alto riesgo con una tasa de éxito del 31 %'
      ],
      logoFeatured: '/images/bitcoin-profit.svg',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Profit',
      paymentMethods: ['Tarjeta de crédito', 'Tarjeta de débito', 'Transferencia bancaria'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/ZCJM85/'
    },
    'bitcoin-buyer': {
      description: '',
      disclaimer: 'Somos conscientes de la gran cantidad de sitios web que recurren a famosos para llevar a los usuarios hasta brókeres y call centers no regulados. Nuestro sitio web solo envía a los usuarios a socios que nos han proporcionado previamente las garantías regulatorias pertinentes. Esta es una oferta mercadotécnica y nuestros socios regulados indicarán qué brindan ras registro.',
      keyFeatures: [
        'Obtenga Apoyo de Asesor Gratis',
        'Los usuarios informan de beneficios desde el primer depósito',
        'Inversión de alto riesgo con una tasa de éxito del 31 %'
      ],
      logoFeatured: '/images/bitcoin-buyer.svg',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Buyer',
      paymentMethods: ['Tarjeta de crédito', 'Tarjeta de débito', 'Transferencia bancaria'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/ZGBDPC/'
    },
    'yuan-payments': {
      description: '',
      disclaimer: 'Somos conscientes de la gran cantidad de sitios web que recurren a famosos para llevar a los usuarios hasta brókeres y call centers no regulados. Nuestro sitio web solo envía a los usuarios a socios que nos han proporcionado previamente las garantías regulatorias pertinentes. Esta es una oferta mercadotécnica y nuestros socios regulados indicarán qué brindan ras registro.',
      keyFeatures: [
        'Obtenga Apoyo de Asesor Gratis',
        'Los usuarios informan de beneficios desde el primer depósito',
        'Inversión de alto riesgo con una tasa de éxito del 31 %'
      ],
      logoFeatured: '/images/yuan-payments.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Yuan Payments',
      paymentMethods: ['Tarjeta de crédito', 'Tarjeta de débito', 'Transferencia bancaria'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/22C3ZNF/'
    },
    'crypto-bank': {
      description: '',
      disclaimer: 'Somos conscientes de la gran cantidad de sitios web que recurren a famosos para llevar a los usuarios hasta brókeres y call centers no regulados. Nuestro sitio web solo envía a los usuarios a socios que nos han proporcionado previamente las garantías regulatorias pertinentes. Esta es una oferta mercadotécnica y nuestros socios regulados indicarán qué brindan ras registro.',
      keyFeatures: [
        'Obtenga Apoyo de Asesor Gratis',
        'Los usuarios informan de beneficios desde el primer depósito',
        'Inversión de alto riesgo con una tasa de éxito del 31 %'
      ],
      logoFeatured: '/images/crypto-bank.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Crypto Bank',
      paymentMethods: ['Tarjeta de crédito', 'Tarjeta de débito', 'Transferencia bancaria'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/22FTQ5M/'
    },
    'crypto-engine': {
      description: '',
      disclaimer: 'Somos conscientes de la gran cantidad de sitios web que recurren a famosos para llevar a los usuarios hasta brókeres y call centers no regulados. Nuestro sitio web solo envía a los usuarios a socios que nos han proporcionado previamente las garantías regulatorias pertinentes. Esta es una oferta mercadotécnica y nuestros socios regulados indicarán qué brindan ras registro.',
      keyFeatures: [
        'Obtenga Apoyo de Asesor Gratis',
        'Los usuarios informan de beneficios desde el primer depósito',
        'Inversión de alto riesgo con una tasa de éxito del 31 %'
      ],
      logoFeatured: '/images/crypto-engine.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Crypto Engine',
      paymentMethods: ['Tarjeta de crédito', 'Tarjeta de débito', 'Transferencia bancaria'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/22H8LW8/'
    },
    'bitcoin-pro': {
      description: '',
      disclaimer: 'Somos conscientes de la gran cantidad de sitios web que recurren a famosos para llevar a los usuarios hasta brókeres y call centers no regulados. Nuestro sitio web solo envía a los usuarios a socios que nos han proporcionado previamente las garantías regulatorias pertinentes. Esta es una oferta mercadotécnica y nuestros socios regulados indicarán qué brindan ras registro.',
      keyFeatures: [
        'Obtenga Apoyo de Asesor Gratis',
        'Los usuarios informan de beneficios desde el primer depósito',
        'Inversión de alto riesgo con una tasa de éxito del 31 %'
      ],
      logoFeatured: '/images/bitcoin-pro.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Pro',
      paymentMethods: ['Tarjeta de crédito', 'Tarjeta de débito', 'Transferencia bancaria'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/22L2DCG/'
    },
    'bitcoin-bank': {
      description: '',
      disclaimer: 'Somos conscientes de la gran cantidad de sitios web que recurren a famosos para llevar a los usuarios hasta brókeres y call centers no regulados. Nuestro sitio web solo envía a los usuarios a socios que nos han proporcionado previamente las garantías regulatorias pertinentes. Esta es una oferta mercadotécnica y nuestros socios regulados indicarán qué brindan ras registro.',
      keyFeatures: [
        'Obtenga Apoyo de Asesor Gratis',
        'Los usuarios informan de beneficios desde el primer depósito',
        'Inversión de alto riesgo con una tasa de éxito del 31 %'
      ],
      logoFeatured: '/images/bitcoin-bank.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Bank',
      paymentMethods: ['Tarjeta de crédito', 'Tarjeta de débito', 'Transferencia bancaria'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/22RKX9W/'
    },
    'tesler': {
      description: '',
      disclaimer: 'Somos conscientes de la gran cantidad de sitios web que recurren a famosos para llevar a los usuarios hasta brókeres y call centers no regulados. Nuestro sitio web solo envía a los usuarios a socios que nos han proporcionado previamente las garantías regulatorias pertinentes. Esta es una oferta mercadotécnica y nuestros socios regulados indicarán qué brindan ras registro.',
      keyFeatures: [
        'Obtenga Apoyo de Asesor Gratis',
        'Los usuarios informan de beneficios desde el primer depósito',
        'Inversión de alto riesgo con una tasa de éxito del 31 %'
      ],
      logoFeatured: '/images/tesler.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Tesler',
      paymentMethods: ['Tarjeta de crédito', 'Tarjeta de débito', 'Transferencia bancaria'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/22SZS2H/'
    },

    'bitcoin-up': {
      description: '',
      disclaimer: 'Somos conscientes de la gran cantidad de sitios web que recurren a famosos para llevar a los usuarios hasta brókeres y call centers no regulados. Nuestro sitio web solo envía a los usuarios a socios que nos han proporcionado previamente las garantías regulatorias pertinentes. Esta es una oferta mercadotécnica y nuestros socios regulados indicarán qué brindan ras registro.',
      keyFeatures: [
        'Obtenga Apoyo de Asesor Gratis',
        'Los usuarios informan de beneficios desde el primer depósito',
        'Inversión de alto riesgo con una tasa de éxito del 31 %'
      ],
      logoFeatured: '/images/bitcoin-up.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin UP',
      paymentMethods: ['Tarjeta de crédito', 'Tarjeta de débito', 'Transferencia bancaria'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/23PZP9F/'
    },
    'nft-code': {
      description: '',
      disclaimer: 'Somos conscientes de la gran cantidad de sitios web que recurren a famosos para llevar a los usuarios hasta brókeres y call centers no regulados. Nuestro sitio web solo envía a los usuarios a socios que nos han proporcionado previamente las garantías regulatorias pertinentes. Esta es una oferta mercadotécnica y nuestros socios regulados indicarán qué brindan ras registro.',
      keyFeatures: [
        'Obtenga Apoyo de Asesor Gratis',
        'Los usuarios informan de beneficios desde el primer depósito',
        'Inversión de alto riesgo con una tasa de éxito del 31 %'
      ],
      logoFeatured: '/images/nft-code.svg',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'NFT Code',
      paymentMethods: ['Tarjeta de crédito', 'Tarjeta de débito', 'Transferencia bancaria'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/23W5CH8/'
    },
    'bit-iq': {
      description: '',
      disclaimer: 'Somos conscientes de la gran cantidad de sitios web que recurren a famosos para llevar a los usuarios hasta brókeres y call centers no regulados. Nuestro sitio web solo envía a los usuarios a socios que nos han proporcionado previamente las garantías regulatorias pertinentes. Esta es una oferta mercadotécnica y nuestros socios regulados indicarán qué brindan ras registro.',
      keyFeatures: [
        'Obtenga Apoyo de Asesor Gratis',
        'Los usuarios informan de beneficios desde el primer depósito',
        'Inversión de alto riesgo con una tasa de éxito del 31 %'
      ],
      logoFeatured: '/images/bit-iq.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bit IQ',
      paymentMethods: ['Tarjeta de crédito', 'Tarjeta de débito', 'Transferencia bancaria'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/25TNNXJ/?uid=2380'
    },
    'bitcoin-code': {
      description: '',
      disclaimer: 'Somos conscientes de la gran cantidad de sitios web que recurren a famosos para llevar a los usuarios hasta brókeres y call centers no regulados. Nuestro sitio web solo envía a los usuarios a socios que nos han proporcionado previamente las garantías regulatorias pertinentes. Esta es una oferta mercadotécnica y nuestros socios regulados indicarán qué brindan ras registro.',
      keyFeatures: [
        'Obtenga Apoyo de Asesor Gratis',
        'Los usuarios informan de beneficios desde el primer depósito',
        'Inversión de alto riesgo con una tasa de éxito del 31 %'
      ],
      logoFeatured: '/images/bitcoin-code.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Code',
      paymentMethods: ['Tarjeta de crédito', 'Tarjeta de débito', 'Transferencia bancaria'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/26DZF27/'
    },
    'bitcoin-fast-profit': {
      description: '',
      disclaimer: 'Somos conscientes de la gran cantidad de sitios web que recurren a famosos para llevar a los usuarios hasta brókeres y call centers no regulados. Nuestro sitio web solo envía a los usuarios a socios que nos han proporcionado previamente las garantías regulatorias pertinentes. Esta es una oferta mercadotécnica y nuestros socios regulados indicarán qué brindan ras registro.',
      keyFeatures: [
        'Obtenga Apoyo de Asesor Gratis',
        'Los usuarios informan de beneficios desde el primer depósito',
        'Inversión de alto riesgo con una tasa de éxito del 31 %'
      ],
      logoFeatured: '/images/bitcoin-fast-profit.svg',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Fast Profit',
      paymentMethods: ['Tarjeta de crédito', 'Tarjeta de débito', 'Transferencia bancaria'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/26GC9QS/'
    },
    'bitcoin-trader': {
      description: '',
      disclaimer: 'Somos conscientes de la gran cantidad de sitios web que recurren a famosos para llevar a los usuarios hasta brókeres y call centers no regulados. Nuestro sitio web solo envía a los usuarios a socios que nos han proporcionado previamente las garantías regulatorias pertinentes. Esta es una oferta mercadotécnica y nuestros socios regulados indicarán qué brindan ras registro.',
      keyFeatures: [
        'Obtenga Apoyo de Asesor Gratis',
        'Los usuarios informan de beneficios desde el primer depósito',
        'Inversión de alto riesgo con una tasa de éxito del 31 %'
      ],
      logoFeatured: '/images/bitcoin-trader.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Trader',
      paymentMethods: ['Tarjeta de crédito', 'Tarjeta de débito', 'Transferencia bancaria'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/26HQ6HF/'
    },
    'bitcoin-millionaire-pro-system': {
      description: '',
      disclaimer: 'Somos conscientes de la gran cantidad de sitios web que recurren a famosos para llevar a los usuarios hasta brókeres y call centers no regulados. Nuestro sitio web solo envía a los usuarios a socios que nos han proporcionado previamente las garantías regulatorias pertinentes. Esta es una oferta mercadotécnica y nuestros socios regulados indicarán qué brindan ras registro.',
      keyFeatures: [
        'Obtenga Apoyo de Asesor Gratis',
        'Los usuarios informan de beneficios desde el primer depósito',
        'Inversión de alto riesgo con una tasa de éxito del 31 %'
      ],
      logoFeatured: '/images/bitcoin-millionaire-pro-system.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Millionaire Pro System',
      paymentMethods: ['Tarjeta de crédito', 'Tarjeta de débito', 'Transferencia bancaria'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/26LHXZM/'
    },
    'bitcoin-circuit': {
      description: '',
      disclaimer: 'Somos conscientes de la gran cantidad de sitios web que recurren a famosos para llevar a los usuarios hasta brókeres y call centers no regulados. Nuestro sitio web solo envía a los usuarios a socios que nos han proporcionado previamente las garantías regulatorias pertinentes. Esta es una oferta mercadotécnica y nuestros socios regulados indicarán qué brindan ras registro.',
      keyFeatures: [
        'Obtenga Apoyo de Asesor Gratis',
        'Los usuarios informan de beneficios desde el primer depósito',
        'Inversión de alto riesgo con una tasa de éxito del 31 %'
      ],
      logoFeatured: '/images/bitcoin-circuit.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Circuit',
      paymentMethods: ['Tarjeta de crédito', 'Tarjeta de débito', 'Transferencia bancaria'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/26P9PFT/'
    },
    'bitcoin-future': {
      description: '',
      disclaimer: 'Somos conscientes de la gran cantidad de sitios web que recurren a famosos para llevar a los usuarios hasta brókeres y call centers no regulados. Nuestro sitio web solo envía a los usuarios a socios que nos han proporcionado previamente las garantías regulatorias pertinentes. Esta es una oferta mercadotécnica y nuestros socios regulados indicarán qué brindan ras registro.',
      keyFeatures: [
        'Obtenga Apoyo de Asesor Gratis',
        'Los usuarios informan de beneficios desde el primer depósito',
        'Inversión de alto riesgo con una tasa de éxito del 31 %'
      ],
      logoFeatured: '/images/bitcoin-future.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Future',
      paymentMethods: ['Tarjeta de crédito', 'Tarjeta de débito', 'Transferencia bancaria'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/26QNL6G/'
    },
    'bitqt': {
      description: '',
      disclaimer: 'Somos conscientes de la gran cantidad de sitios web que recurren a famosos para llevar a los usuarios hasta brókeres y call centers no regulados. Nuestro sitio web solo envía a los usuarios a socios que nos han proporcionado previamente las garantías regulatorias pertinentes. Esta es una oferta mercadotécnica y nuestros socios regulados indicarán qué brindan ras registro.',
      keyFeatures: [
        'Obtenga Apoyo de Asesor Gratis',
        'Los usuarios informan de beneficios desde el primer depósito',
        'Inversión de alto riesgo con una tasa de éxito del 31 %'
      ],
      logoFeatured: '/images/bitqt.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'BitQT',
      paymentMethods: ['Tarjeta de crédito', 'Tarjeta de débito', 'Transferencia bancaria'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/28Q7XLQ/'
    },
    'trade-2-earn': {
      description: '',
      disclaimer: 'Somos conscientes de la gran cantidad de sitios web que recurren a famosos para llevar a los usuarios hasta brókeres y call centers no regulados. Nuestro sitio web solo envía a los usuarios a socios que nos han proporcionado previamente las garantías regulatorias pertinentes. Esta es una oferta mercadotécnica y nuestros socios regulados indicarán qué brindan ras registro.',
      keyFeatures: [
        'Obtenga Apoyo de Asesor Gratis',
        'Los usuarios informan de beneficios desde el primer depósito',
        'Inversión de alto riesgo con una tasa de éxito del 31 %'
      ],
      logoFeatured: '/images/trade-2-earn.svg',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Trade 2 Earn',
      paymentMethods: ['Tarjeta de crédito', 'Tarjeta de débito', 'Transferencia bancaria'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/28T1P3Z/'
    },
    'bitcoin-bank-breaker': {
      description: '',
      disclaimer: 'Somos conscientes de la gran cantidad de sitios web que recurren a famosos para llevar a los usuarios hasta brókeres y call centers no regulados. Nuestro sitio web solo envía a los usuarios a socios que nos han proporcionado previamente las garantías regulatorias pertinentes. Esta es una oferta mercadotécnica y nuestros socios regulados indicarán qué brindan ras registro.',
      keyFeatures: [
        'Obtenga Apoyo de Asesor Gratis',
        'Los usuarios informan de beneficios desde el primer depósito',
        'Inversión de alto riesgo con una tasa de éxito del 31 %'
      ],
      logoFeatured: '/images/bitcoin-bank-breaker.svg',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Bank Breaker',
      paymentMethods: ['Tarjeta de crédito', 'Tarjeta de débito', 'Transferencia bancaria'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2BZPNQ3/'
    },
    'bitcoin-billionaire': {
      description: '',
      disclaimer: 'Somos conscientes de la gran cantidad de sitios web que recurren a famosos para llevar a los usuarios hasta brókeres y call centers no regulados. Nuestro sitio web solo envía a los usuarios a socios que nos han proporcionado previamente las garantías regulatorias pertinentes. Esta es una oferta mercadotécnica y nuestros socios regulados indicarán qué brindan ras registro.',
      keyFeatures: [
        'Obtenga Apoyo de Asesor Gratis',
        'Los usuarios informan de beneficios desde el primer depósito',
        'Inversión de alto riesgo con una tasa de éxito del 31 %'
      ],
      logoFeatured: '/images/bitcoin-billionaire.svg',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Billionaire',
      paymentMethods: ['Tarjeta de crédito', 'Tarjeta de débito', 'Transferencia bancaria'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2DPBLHB/'
    },
    'bitcoin-loophole': {
      description: '',
      disclaimer: 'Somos conscientes de la gran cantidad de sitios web que recurren a famosos para llevar a los usuarios hasta brókeres y call centers no regulados. Nuestro sitio web solo envía a los usuarios a socios que nos han proporcionado previamente las garantías regulatorias pertinentes. Esta es una oferta mercadotécnica y nuestros socios regulados indicarán qué brindan ras registro.',
      keyFeatures: [
        'Obtenga Apoyo de Asesor Gratis',
        'Los usuarios informan de beneficios desde el primer depósito',
        'Inversión de alto riesgo con una tasa de éxito del 31 %'
      ],
      logoFeatured: '/images/bitcoin-loophole.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Loophole',
      paymentMethods: ['Tarjeta de crédito', 'Tarjeta de débito', 'Transferencia bancaria'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2DQPH7X/'
    },
    'bitcoin-motion': {
      description: '',
      disclaimer: 'Somos conscientes de la gran cantidad de sitios web que recurren a famosos para llevar a los usuarios hasta brókeres y call centers no regulados. Nuestro sitio web solo envía a los usuarios a socios que nos han proporcionado previamente las garantías regulatorias pertinentes. Esta es una oferta mercadotécnica y nuestros socios regulados indicarán qué brindan ras registro.',
      keyFeatures: [
        'Obtenga Apoyo de Asesor Gratis',
        'Los usuarios informan de beneficios desde el primer depósito',
        'Inversión de alto riesgo con una tasa de éxito del 31 %'
      ],
      logoFeatured: '/images/bitcoin-motion.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Motion',
      paymentMethods: ['Tarjeta de crédito', 'Tarjeta de débito', 'Transferencia bancaria'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2DS4CZJ/'
    },
    'profit-bitcoin': {
      description: '',
      disclaimer: 'Somos conscientes de la gran cantidad de sitios web que recurren a famosos para llevar a los usuarios hasta brókeres y call centers no regulados. Nuestro sitio web solo envía a los usuarios a socios que nos han proporcionado previamente las garantías regulatorias pertinentes. Esta es una oferta mercadotécnica y nuestros socios regulados indicarán qué brindan ras registro.',
      keyFeatures: [
        'Obtenga Apoyo de Asesor Gratis',
        'Los usuarios informan de beneficios desde el primer depósito',
        'Inversión de alto riesgo con una tasa de éxito del 31 %'
      ],
      logoFeatured: '/images/profit-bitcoin.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Profit Bitcoin',
      paymentMethods: ['Tarjeta de crédito', 'Tarjeta de débito', 'Transferencia bancaria'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2DTH8P5/'
    },
    'bitcoin-evolution': {
      description: '',
      disclaimer: 'Somos conscientes de la gran cantidad de sitios web que recurren a famosos para llevar a los usuarios hasta brókeres y call centers no regulados. Nuestro sitio web solo envía a los usuarios a socios que nos han proporcionado previamente las garantías regulatorias pertinentes. Esta es una oferta mercadotécnica y nuestros socios regulados indicarán qué brindan ras registro.',
      keyFeatures: [
        'Obtenga Apoyo de Asesor Gratis',
        'Los usuarios informan de beneficios desde el primer depósito',
        'Inversión de alto riesgo con una tasa de éxito del 31 %'
      ],
      logoFeatured: '/images/bitcoin-evolution.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Evolution',
      paymentMethods: ['Tarjeta de crédito', 'Tarjeta de débito', 'Transferencia bancaria'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2DWW5FQ/'
    },
    'bitcoin-champion': {
      description: '',
      disclaimer: 'Somos conscientes de la gran cantidad de sitios web que recurren a famosos para llevar a los usuarios hasta brókeres y call centers no regulados. Nuestro sitio web solo envía a los usuarios a socios que nos han proporcionado previamente las garantías regulatorias pertinentes. Esta es una oferta mercadotécnica y nuestros socios regulados indicarán qué brindan ras registro.',
      keyFeatures: [
        'Obtenga Apoyo de Asesor Gratis',
        'Los usuarios informan de beneficios desde el primer depósito',
        'Inversión de alto riesgo con una tasa de éxito del 31 %'
      ],
      logoFeatured: '/images/bitcoin-champion.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Champion',
      paymentMethods: ['Tarjeta de crédito', 'Tarjeta de débito', 'Transferencia bancaria'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2F1MWWZ/'
    },
    'bitcoin-money': {
      description: '',
      disclaimer: 'Somos conscientes de la gran cantidad de sitios web que recurren a famosos para llevar a los usuarios hasta brókeres y call centers no regulados. Nuestro sitio web solo envía a los usuarios a socios que nos han proporcionado previamente las garantías regulatorias pertinentes. Esta es una oferta mercadotécnica y nuestros socios regulados indicarán qué brindan ras registro.',
      keyFeatures: [
        'Obtenga Apoyo de Asesor Gratis',
        'Los usuarios informan de beneficios desde el primer depósito',
        'Inversión de alto riesgo con una tasa de éxito del 31 %'
      ],
      logoFeatured: '/images/bitcoin-money.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Money',
      paymentMethods: ['Tarjeta de crédito', 'Tarjeta de débito', 'Transferencia bancaria'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2F32RMK/'
    },
    'bitcoin-trend-app': {
      description: '',
      disclaimer: 'Somos conscientes de la gran cantidad de sitios web que recurren a famosos para llevar a los usuarios hasta brókeres y call centers no regulados. Nuestro sitio web solo envía a los usuarios a socios que nos han proporcionado previamente las garantías regulatorias pertinentes. Esta es una oferta mercadotécnica y nuestros socios regulados indicarán qué brindan ras registro.',
      keyFeatures: [
        'Obtenga Apoyo de Asesor Gratis',
        'Los usuarios informan de beneficios desde el primer depósito',
        'Inversión de alto riesgo con una tasa de éxito del 31 %'
      ],
      logoFeatured: '/images/bitcoin-trend-app.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Trend App',
      paymentMethods: ['Tarjeta de crédito', 'Tarjeta de débito', 'Transferencia bancaria'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2F4FND6/'
    },
    'crypto-comeback-pro': {
      description: '',
      disclaimer: 'Somos conscientes de la gran cantidad de sitios web que recurren a famosos para llevar a los usuarios hasta brókeres y call centers no regulados. Nuestro sitio web solo envía a los usuarios a socios que nos han proporcionado previamente las garantías regulatorias pertinentes. Esta es una oferta mercadotécnica y nuestros socios regulados indicarán qué brindan ras registro.',
      keyFeatures: [
        'Obtenga Apoyo de Asesor Gratis',
        'Los usuarios informan de beneficios desde el primer depósito',
        'Inversión de alto riesgo con una tasa de éxito del 31 %'
      ],
      logoFeatured: '/images/crypto-comeback-pro.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Crypto Comeback Pro',
      paymentMethods: ['Tarjeta de crédito', 'Tarjeta de débito', 'Transferencia bancaria'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2F5SK4R/'
    },
    'ethereum-system': {
      description: '',
      disclaimer: 'Somos conscientes de la gran cantidad de sitios web que recurren a famosos para llevar a los usuarios hasta brókeres y call centers no regulados. Nuestro sitio web solo envía a los usuarios a socios que nos han proporcionado previamente las garantías regulatorias pertinentes. Esta es una oferta mercadotécnica y nuestros socios regulados indicarán qué brindan ras registro.',
      keyFeatures: [
        'Obtenga Apoyo de Asesor Gratis',
        'Los usuarios informan de beneficios desde el primer depósito',
        'Inversión de alto riesgo con una tasa de éxito del 31 %'
      ],
      logoFeatured: '/images/ethereum-system.svg',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Ethereum System',
      paymentMethods: ['Tarjeta de crédito', 'Tarjeta de débito', 'Transferencia bancaria'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2F77FTD/'
    },
    'the-bitcoin-millionaire': {
      description: '',
      disclaimer: 'Somos conscientes de la gran cantidad de sitios web que recurren a famosos para llevar a los usuarios hasta brókeres y call centers no regulados. Nuestro sitio web solo envía a los usuarios a socios que nos han proporcionado previamente las garantías regulatorias pertinentes. Esta es una oferta mercadotécnica y nuestros socios regulados indicarán qué brindan ras registro.',
      keyFeatures: [
        'Obtenga Apoyo de Asesor Gratis',
        'Los usuarios informan de beneficios desde el primer depósito',
        'Inversión de alto riesgo con una tasa de éxito del 31 %'
      ],
      logoFeatured: '/images/the-bitcoin-millionaire.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'The Bitcoin Millionaire',
      paymentMethods: ['Tarjeta de crédito', 'Tarjeta de débito', 'Transferencia bancaria'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2F8LBL1/'
    }
  },

  home: {
    title: '{0} - Recomendado para {1} {2}',
    title2: 'Encuentra los Sitios más seguros para Invertir',
    description: `
      {0} analiza las tendencias de venta y compra del mercado para poder beneficiarse de la volatilidad y obtener así beneficios. No necesitas tener conocimientos técnicos sobre trading para usar el software. El sistema ha recibido numerosas críticas positivas online.
    `,

    description2: `
      <h2><strong>Cómo Calificamos los Brókeres&nbsp;</strong>- {0}</h2>
      <p>
        El equipo de {1} analiza y revisa de forma rigurosa los brókeres en la industria para que no tengas
        que hacerlo tú. El equipo evalúa una lista de al menos 57 elementos, pero algunos de los puntos clave incluyen:
      </p>
      <ul>
        <li>
          <strong>Calidad de atención al cliente:&nbsp;&nbsp;</strong>¿tienen una sección de ayuda fácil de utilizar?
          ¿La información de contacto se consigue fácilmente? ¿La información está clara, incluyendo horarios, códigos
          por país, etc.? ¿Ofrecen una variedad de métodos de atención al cliente, p. ej., correo electrónico, teléfono,
          chat y correo ordinario? ¿Son rápidos en sus respuestas? ¿Ofrecen un buen servicio? ¿Son educados? ¿Tienen
          conocimientos?
        </li>
        <li>
          <strong>Facilidad de Uso e Interfaz de Usuario:&nbsp;&nbsp;</strong>los sitios web están definidos por su
          interfaz. Es lo que les hace atractivos para usar y esto también se cumple en el sector de las inversiones.
          Encontrar un sitio que se vea bien y sea fácil de usar es crucial para una experiencia de inversión positiva.
        </li>
        <li>
          <strong>Pagos:&nbsp;&nbsp;</strong>¿tiene una buena selección de métodos bancarios? ¿El sitio es seguro? ¿Es
          fácil depositar fondos? ¿Los retiros son procesados con rapidez? ¿En general, el proceso es rápido y fácil?
        </li>
      </ul>
      <p>Y muchos, muchos más...</p>
    `
  },

  register: {
    title: 'Antes de que te vayas...',
    description: 'Los mercados se mueven rápido. No pierdas la oportunidad de beneficiarte de las últimas subidas y bajadas.Regístrate hoy y te sorprenderás después de retirar las ganancias conseguidas en 2 semanas!',
    registerNow: 'Comience a operar',
    startTradingNow: 'Empieza a operar ya',
    terms: 'Al completar este formulario, entiendo que seré redirigido a un socio de trading de terceros y que se compartirá mi información personal.',
    fullName: 'Nombre completo',
    email: 'E-mail',
    howOldAreU: '¿Cuantos años tienes?',
    loading: 'Registro...'
  },

  footer: {
    description: `
        <p>Risk Warning:</p>
        <p>When investing your capital is at risk.</p>
        <p><br /></p>
        <p>
          Past performance is not an indication of future results. Any investing history presented is less than 5
          years old unless otherwise stated and may not suffice as a basis for investment decisions. Prices may go
          down as well as up, prices can fluctuate widely and you may lose all of or more than the amount you invest.
          Investing is not suitable for everyone; please ensure that you have fully understood the risks and
          legalities involved. If you are unsure, seek independent financial, legal, tax and/or accounting advice.
          This website does not provide investment, financial, legal, tax or accounting advice. Your investment may
          not qualify for investor protection in your country or state of residence, so please conduct your own due
          diligence.
        </p>
        <p>
          {0} is an independent comparison platform and information service that aims to provide you with
          the tools you need to make better decisions. While we are independent, the offers that appear on this site
          are from companies from which {0} receives compensation.&nbsp;
        </p>
        <p>
          Your investment may not qualify for investor protection in your country or state of residence, so please
          conduct your own due diligence. This website is free for you to use but we may receive commission from the
          companies we feature on this site.
        </p>
        <p>
        Wellgard Enterprise Inc <br>
        216913 Suite 1 Second floor , Sound & Vision House, Francis Rachel Street <br>
        Victoria Mahe<br>
         Seychelles
        </p>
      `
  },

  month: {
    "January": "Enero",
    "February": "Febrero",
    "March": "Marzo",
    "April": "Abril",
    "May": "Mayo",
    "June": "junio",
    "July": "Julio",
    "August": "agosto",
    "September": "Septiembre",
    "October": "Octubre",
    "November": "Noviembre",
    "December": "Diciembre"
  }
};
