<template>
  <header class="header">
    <div class="container lg:px-10 max-w-7xl">
      <div class="py-4">
        <div class="text-center">
          <img
            :src="logo"
            alt="wealth-research"
            class="object-contain object-left w-40 h-8"
            width="160"
            height="32"
          />
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  data: () => ({
    logo: process.env.VUE_APP_LOGO || '/images/logo.png'
  })
}
</script>
