export default {
  // Common translations

  termsConditions: 'Geschäftsbedingungen',
  privacy: ' Datenschutzerklärung',
  disclaimer: ' Haftungsausschluss',
  'min-deposit': 'Mindesteinzahlung',
  'user-score': 'Benutzerbewertung',
  'start-trading': 'Jetzt handeln',
  'more-info': 'Mehr Infos',
  description: 'Beschreibung',
  'payment-methods': 'Zahlungsarten',
  'full-regulations-list': 'Vollständige Liste der Vorschriften',
  'other-recommended-partners': 'Andere empfohlene Partner',

  // Offers list

  offers: {
    'immediate-edge': {
      description: '',
      disclaimer: 'Uns sind zahlreiche Websites bekannt, die falsche prominente Empfehlungen verwenden, um Kunden an unregulierte Broker oder Call-Center zu schicken. Unsere Website schickt Kunden nur an Partner, die uns die erforderlichen regulatorischen Zusicherungen gegeben haben. Dies ist ein Marketingangebot, und unsere regulierten Partner werden bei der Registrierung angeben, welche Dienstleistungen ihnen zur Verfügung stehen.',
      keyFeatures: [
        'Unterstützung von einem Kostenlosen Berater',
        '62% Benutzer berichten von Gewinn aus ihrer ersten Einzahlung',
        'berichteten 31% Erfolgsquote bei risikoreichen Investitionen'
      ],
      logoFeatured: '/images/immediate-edge.webp',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Immediate Edge',
      paymentMethods: ['Banküberweisung', 'Debitkarte', 'Kreditkarte'],
      starRating: 93,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/27P3D6/?uid=16'
    },
    'bitcoin-system': {
      description: '',
      disclaimer: 'Uns sind zahlreiche Websites bekannt, die falsche prominente Empfehlungen verwenden, um Kunden an unregulierte Broker oder Call-Center zu schicken. Unsere Website schickt Kunden nur an Partner, die uns die erforderlichen regulatorischen Zusicherungen gegeben haben. Dies ist ein Marketingangebot, und unsere regulierten Partner werden bei der Registrierung angeben, welche Dienstleistungen ihnen zur Verfügung stehen.',
      keyFeatures: [
        'Unterstützung von einem Kostenlosen Berater',
        '62% Benutzer berichten von Gewinn aus ihrer ersten Einzahlung',
        'berichteten 31% Erfolgsquote bei risikoreichen Investitionen'
      ],
      logoFeatured: '/images/bitcoin-system.svg',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin System',
      paymentMethods: ['Banküberweisung', 'Debitkarte', 'Kreditkarte'],
      starRating: 91,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2CTPL/?uid=85'
    },
    'bitcoin-bot': {
      description: '',
      disclaimer: 'Uns sind zahlreiche Websites bekannt, die falsche prominente Empfehlungen verwenden, um Kunden an unregulierte Broker oder Call-Center zu schicken. Unsere Website schickt Kunden nur an Partner, die uns die erforderlichen regulatorischen Zusicherungen gegeben haben. Dies ist ein Marketingangebot, und unsere regulierten Partner werden bei der Registrierung angeben, welche Dienstleistungen ihnen zur Verfügung stehen.',
      keyFeatures: [
        'Unterstützung von einem Kostenlosen Berater',
        '62% Benutzer berichten von Gewinn aus ihrer ersten Einzahlung',
        'berichteten 31% Erfolgsquote bei risikoreichen Investitionen'
      ],
      logoFeatured: '/images/bitcoinbot.jpg',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Bot',
      paymentMethods: ['Banküberweisung', 'Debitkarte', 'Kreditkarte'],
      starRating: 98,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/SDFZCK/'
    },
    'bitcoin-era': {
      description: '',
      disclaimer: 'Uns sind zahlreiche Websites bekannt, die falsche prominente Empfehlungen verwenden, um Kunden an unregulierte Broker oder Call-Center zu schicken. Unsere Website schickt Kunden nur an Partner, die uns die erforderlichen regulatorischen Zusicherungen gegeben haben. Dies ist ein Marketingangebot, und unsere regulierten Partner werden bei der Registrierung angeben, welche Dienstleistungen ihnen zur Verfügung stehen.',
      keyFeatures: [
        'Unterstützung von einem Kostenlosen Berater',
        '62% Benutzer berichten von Gewinn aus ihrer ersten Einzahlung',
        'berichteten 31% Erfolgsquote bei risikoreichen Investitionen'
      ],
      logoFeatured: '/images/bitcoin-era.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Era',
      paymentMethods: ['Banküberweisung', 'Debitkarte', 'Kreditkarte'],
      starRating: 85,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/5XTBKP/'
    },
    'profit-builder': {
      description: '',
      disclaimer: 'Uns sind zahlreiche Websites bekannt, die falsche prominente Empfehlungen verwenden, um Kunden an unregulierte Broker oder Call-Center zu schicken. Unsere Website schickt Kunden nur an Partner, die uns die erforderlichen regulatorischen Zusicherungen gegeben haben. Dies ist ein Marketingangebot, und unsere regulierten Partner werden bei der Registrierung angeben, welche Dienstleistungen ihnen zur Verfügung stehen.',
      keyFeatures: [
        'Unterstützung von einem Kostenlosen Berater',
        '62% Benutzer berichten von Gewinn aus ihrer ersten Einzahlung',
        'berichteten 31% Erfolgsquote bei risikoreichen Investitionen'
      ],
      logoFeatured: '/images/profitbuilder.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Profit Builder',
      paymentMethods: ['Banküberweisung', 'Debitkarte', 'Kreditkarte'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/LDF3B4/'
    },
    '1k-in-1day': {
      description: '',
      disclaimer: 'Uns sind zahlreiche Websites bekannt, die falsche prominente Empfehlungen verwenden, um Kunden an unregulierte Broker oder Call-Center zu schicken. Unsere Website schickt Kunden nur an Partner, die uns die erforderlichen regulatorischen Zusicherungen gegeben haben. Dies ist ein Marketingangebot, und unsere regulierten Partner werden bei der Registrierung angeben, welche Dienstleistungen ihnen zur Verfügung stehen.',
      keyFeatures: [
        'Unterstützung von einem Kostenlosen Berater',
        '62% Benutzer berichten von Gewinn aus ihrer ersten Einzahlung',
        'berichteten 31% Erfolgsquote bei risikoreichen Investitionen'
      ],
      logoFeatured: '/images/1k-in-1day.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: '1K in 1Day',
      paymentMethods: ['Banküberweisung', 'Debitkarte', 'Kreditkarte'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2DZ926C/'
    },
    'amazon-stocks-investment': {
      description: '',
      disclaimer: 'Uns sind zahlreiche Websites bekannt, die falsche prominente Empfehlungen verwenden, um Kunden an unregulierte Broker oder Call-Center zu schicken. Unsere Website schickt Kunden nur an Partner, die uns die erforderlichen regulatorischen Zusicherungen gegeben haben. Dies ist ein Marketingangebot, und unsere regulierten Partner werden bei der Registrierung angeben, welche Dienstleistungen ihnen zur Verfügung stehen.',
      keyFeatures: [
        'Unterstützung von einem Kostenlosen Berater',
        '62% Benutzer berichten von Gewinn aus ihrer ersten Einzahlung',
        'berichteten 31% Erfolgsquote bei risikoreichen Investitionen'
      ],
      logoFeatured: '/images/amazon-stocks-investment.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Amazon Stocks Investment',
      paymentMethods: ['Banküberweisung', 'Debitkarte', 'Kreditkarte'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/ZDXHZQ/'
    },
    'bitcoin-lifestyle': {
      description: '',
      disclaimer: 'Uns sind zahlreiche Websites bekannt, die falsche prominente Empfehlungen verwenden, um Kunden an unregulierte Broker oder Call-Center zu schicken. Unsere Website schickt Kunden nur an Partner, die uns die erforderlichen regulatorischen Zusicherungen gegeben haben. Dies ist ein Marketingangebot, und unsere regulierten Partner werden bei der Registrierung angeben, welche Dienstleistungen ihnen zur Verfügung stehen.',
      keyFeatures: [
        'Unterstützung von einem Kostenlosen Berater',
        '62% Benutzer berichten von Gewinn aus ihrer ersten Einzahlung',
        'berichteten 31% Erfolgsquote bei risikoreichen Investitionen'
      ],
      logoFeatured: '/images/bitcoin-lifestyle.jpg',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Lifestyle',
      paymentMethods: ['Banküberweisung', 'Debitkarte', 'Kreditkarte'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/4HKP84/?uid=133'
    },
    'tradermate': {
      description: '',
      disclaimer: 'Uns sind zahlreiche Websites bekannt, die falsche prominente Empfehlungen verwenden, um Kunden an unregulierte Broker oder Call-Center zu schicken. Unsere Website schickt Kunden nur an Partner, die uns die erforderlichen regulatorischen Zusicherungen gegeben haben. Dies ist ein Marketingangebot, und unsere regulierten Partner werden bei der Registrierung angeben, welche Dienstleistungen ihnen zur Verfügung stehen.',
      keyFeatures: [
        'Unterstützung von einem Kostenlosen Berater',
        '62% Benutzer berichten von Gewinn aus ihrer ersten Einzahlung',
        'berichteten 31% Erfolgsquote bei risikoreichen Investitionen'
      ],
      logoFeatured: '/images/tradermate.svg',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'TraderMate',
      paymentMethods: ['Banküberweisung', 'Debitkarte', 'Kreditkarte'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/XN48XM/'
    },
    'bitcoin-profit': {
      description: '',
      disclaimer: 'Uns sind zahlreiche Websites bekannt, die falsche prominente Empfehlungen verwenden, um Kunden an unregulierte Broker oder Call-Center zu schicken. Unsere Website schickt Kunden nur an Partner, die uns die erforderlichen regulatorischen Zusicherungen gegeben haben. Dies ist ein Marketingangebot, und unsere regulierten Partner werden bei der Registrierung angeben, welche Dienstleistungen ihnen zur Verfügung stehen.',
      keyFeatures: [
        'Unterstützung von einem Kostenlosen Berater',
        '62% Benutzer berichten von Gewinn aus ihrer ersten Einzahlung',
        'berichteten 31% Erfolgsquote bei risikoreichen Investitionen'
      ],
      logoFeatured: '/images/bitcoin-profit.svg',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Profit',
      paymentMethods: ['Banküberweisung', 'Debitkarte', 'Kreditkarte'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/ZCJM85/'
    },
    'bitcoin-buyer': {
      description: '',
      disclaimer: 'Uns sind zahlreiche Websites bekannt, die falsche prominente Empfehlungen verwenden, um Kunden an unregulierte Broker oder Call-Center zu schicken. Unsere Website schickt Kunden nur an Partner, die uns die erforderlichen regulatorischen Zusicherungen gegeben haben. Dies ist ein Marketingangebot, und unsere regulierten Partner werden bei der Registrierung angeben, welche Dienstleistungen ihnen zur Verfügung stehen.',
      keyFeatures: [
        'Unterstützung von einem Kostenlosen Berater',
        '62% Benutzer berichten von Gewinn aus ihrer ersten Einzahlung',
        'berichteten 31% Erfolgsquote bei risikoreichen Investitionen'
      ],
      logoFeatured: '/images/bitcoin-buyer.svg',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Buyer',
      paymentMethods: ['Banküberweisung', 'Debitkarte', 'Kreditkarte'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/ZGBDPC/'
    },
    'yuan-payments': {
      description: '',
      disclaimer: 'Uns sind zahlreiche Websites bekannt, die falsche prominente Empfehlungen verwenden, um Kunden an unregulierte Broker oder Call-Center zu schicken. Unsere Website schickt Kunden nur an Partner, die uns die erforderlichen regulatorischen Zusicherungen gegeben haben. Dies ist ein Marketingangebot, und unsere regulierten Partner werden bei der Registrierung angeben, welche Dienstleistungen ihnen zur Verfügung stehen.',
      keyFeatures: [
        'Unterstützung von einem Kostenlosen Berater',
        '62% Benutzer berichten von Gewinn aus ihrer ersten Einzahlung',
        'berichteten 31% Erfolgsquote bei risikoreichen Investitionen'
      ],
      logoFeatured: '/images/yuan-payments.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Yuan Payments',
      paymentMethods: ['Banküberweisung', 'Debitkarte', 'Kreditkarte'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/22C3ZNF/'
    },
    'crypto-bank': {
      description: '',
      disclaimer: 'Uns sind zahlreiche Websites bekannt, die falsche prominente Empfehlungen verwenden, um Kunden an unregulierte Broker oder Call-Center zu schicken. Unsere Website schickt Kunden nur an Partner, die uns die erforderlichen regulatorischen Zusicherungen gegeben haben. Dies ist ein Marketingangebot, und unsere regulierten Partner werden bei der Registrierung angeben, welche Dienstleistungen ihnen zur Verfügung stehen.',
      keyFeatures: [
        'Unterstützung von einem Kostenlosen Berater',
        '62% Benutzer berichten von Gewinn aus ihrer ersten Einzahlung',
        'berichteten 31% Erfolgsquote bei risikoreichen Investitionen'
      ],
      logoFeatured: '/images/crypto-bank.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Crypto Bank',
      paymentMethods: ['Banküberweisung', 'Debitkarte', 'Kreditkarte'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/22FTQ5M/'
    },
    'crypto-engine': {
      description: '',
      disclaimer: 'Uns sind zahlreiche Websites bekannt, die falsche prominente Empfehlungen verwenden, um Kunden an unregulierte Broker oder Call-Center zu schicken. Unsere Website schickt Kunden nur an Partner, die uns die erforderlichen regulatorischen Zusicherungen gegeben haben. Dies ist ein Marketingangebot, und unsere regulierten Partner werden bei der Registrierung angeben, welche Dienstleistungen ihnen zur Verfügung stehen.',
      keyFeatures: [
        'Unterstützung von einem Kostenlosen Berater',
        '62% Benutzer berichten von Gewinn aus ihrer ersten Einzahlung',
        'berichteten 31% Erfolgsquote bei risikoreichen Investitionen'
      ],
      logoFeatured: '/images/crypto-engine.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Crypto Engine',
      paymentMethods: ['Banküberweisung', 'Debitkarte', 'Kreditkarte'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/22H8LW8/'
    },
    'bitcoin-pro': {
      description: '',
      disclaimer: 'Uns sind zahlreiche Websites bekannt, die falsche prominente Empfehlungen verwenden, um Kunden an unregulierte Broker oder Call-Center zu schicken. Unsere Website schickt Kunden nur an Partner, die uns die erforderlichen regulatorischen Zusicherungen gegeben haben. Dies ist ein Marketingangebot, und unsere regulierten Partner werden bei der Registrierung angeben, welche Dienstleistungen ihnen zur Verfügung stehen.',
      keyFeatures: [
        'Unterstützung von einem Kostenlosen Berater',
        '62% Benutzer berichten von Gewinn aus ihrer ersten Einzahlung',
        'berichteten 31% Erfolgsquote bei risikoreichen Investitionen'
      ],
      logoFeatured: '/images/bitcoin-pro.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Pro',
      paymentMethods: ['Banküberweisung', 'Debitkarte', 'Kreditkarte'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/22L2DCG/'
    },

    'bitcoin-bank': {
      description: '',
      disclaimer: 'Uns sind zahlreiche Websites bekannt, die falsche prominente Empfehlungen verwenden, um Kunden an unregulierte Broker oder Call-Center zu schicken. Unsere Website schickt Kunden nur an Partner, die uns die erforderlichen regulatorischen Zusicherungen gegeben haben. Dies ist ein Marketingangebot, und unsere regulierten Partner werden bei der Registrierung angeben, welche Dienstleistungen ihnen zur Verfügung stehen.',
      keyFeatures: [
        'Unterstützung von einem Kostenlosen Berater',
        '62% Benutzer berichten von Gewinn aus ihrer ersten Einzahlung',
        'berichteten 31% Erfolgsquote bei risikoreichen Investitionen'
      ],
      logoFeatured: '/images/bitcoin-bank.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Bank',
      paymentMethods: ['Banküberweisung', 'Debitkarte', 'Kreditkarte'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/22RKX9W/'
    },
    'tesler': {
      description: '',
      disclaimer: 'Uns sind zahlreiche Websites bekannt, die falsche prominente Empfehlungen verwenden, um Kunden an unregulierte Broker oder Call-Center zu schicken. Unsere Website schickt Kunden nur an Partner, die uns die erforderlichen regulatorischen Zusicherungen gegeben haben. Dies ist ein Marketingangebot, und unsere regulierten Partner werden bei der Registrierung angeben, welche Dienstleistungen ihnen zur Verfügung stehen.',
      keyFeatures: [
        'Unterstützung von einem Kostenlosen Berater',
        '62% Benutzer berichten von Gewinn aus ihrer ersten Einzahlung',
        'berichteten 31% Erfolgsquote bei risikoreichen Investitionen'
      ],
      logoFeatured: '/images/tesler.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Tesler',
      paymentMethods: ['Banküberweisung', 'Debitkarte', 'Kreditkarte'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/22SZS2H/'
    },
    'tradermate-compliant-2': {
      description: '',
      disclaimer: 'Uns sind zahlreiche Websites bekannt, die falsche prominente Empfehlungen verwenden, um Kunden an unregulierte Broker oder Call-Center zu schicken. Unsere Website schickt Kunden nur an Partner, die uns die erforderlichen regulatorischen Zusicherungen gegeben haben. Dies ist ein Marketingangebot, und unsere regulierten Partner werden bei der Registrierung angeben, welche Dienstleistungen ihnen zur Verfügung stehen.',
      keyFeatures: [
        'Unterstützung von einem Kostenlosen Berater',
        '62% Benutzer berichten von Gewinn aus ihrer ersten Einzahlung',
        'berichteten 31% Erfolgsquote bei risikoreichen Investitionen'
      ],
      logoFeatured: '/images/tradermate-compliant.svg',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'TraderMate Compliant',
      paymentMethods: ['Banküberweisung', 'Debitkarte', 'Kreditkarte'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/23FMDTR/'
    },
    'bitcoin-up': {
      description: '',
      disclaimer: 'Uns sind zahlreiche Websites bekannt, die falsche prominente Empfehlungen verwenden, um Kunden an unregulierte Broker oder Call-Center zu schicken. Unsere Website schickt Kunden nur an Partner, die uns die erforderlichen regulatorischen Zusicherungen gegeben haben. Dies ist ein Marketingangebot, und unsere regulierten Partner werden bei der Registrierung angeben, welche Dienstleistungen ihnen zur Verfügung stehen.',
      keyFeatures: [
        'Unterstützung von einem Kostenlosen Berater',
        '62% Benutzer berichten von Gewinn aus ihrer ersten Einzahlung',
        'berichteten 31% Erfolgsquote bei risikoreichen Investitionen'
      ],
      logoFeatured: '/images/bitcoin-up.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin UP',
      paymentMethods: ['Banküberweisung', 'Debitkarte', 'Kreditkarte'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/23PZP9F/'
    },
    'nft-code': {
      description: '',
      disclaimer: 'Uns sind zahlreiche Websites bekannt, die falsche prominente Empfehlungen verwenden, um Kunden an unregulierte Broker oder Call-Center zu schicken. Unsere Website schickt Kunden nur an Partner, die uns die erforderlichen regulatorischen Zusicherungen gegeben haben. Dies ist ein Marketingangebot, und unsere regulierten Partner werden bei der Registrierung angeben, welche Dienstleistungen ihnen zur Verfügung stehen.',
      keyFeatures: [
        'Unterstützung von einem Kostenlosen Berater',
        '62% Benutzer berichten von Gewinn aus ihrer ersten Einzahlung',
        'berichteten 31% Erfolgsquote bei risikoreichen Investitionen'
      ],
      logoFeatured: '/images/nft-code.svg',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'NFT Code',
      paymentMethods: ['Banküberweisung', 'Debitkarte', 'Kreditkarte'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/23W5CH8/'
    },
    'bit-iq': {
      description: '',
      disclaimer: 'Uns sind zahlreiche Websites bekannt, die falsche prominente Empfehlungen verwenden, um Kunden an unregulierte Broker oder Call-Center zu schicken. Unsere Website schickt Kunden nur an Partner, die uns die erforderlichen regulatorischen Zusicherungen gegeben haben. Dies ist ein Marketingangebot, und unsere regulierten Partner werden bei der Registrierung angeben, welche Dienstleistungen ihnen zur Verfügung stehen.',
      keyFeatures: [
        'Unterstützung von einem Kostenlosen Berater',
        '62% Benutzer berichten von Gewinn aus ihrer ersten Einzahlung',
        'berichteten 31% Erfolgsquote bei risikoreichen Investitionen'
      ],
      logoFeatured: '/images/bit-iq.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bit IQ',
      paymentMethods: ['Banküberweisung', 'Debitkarte', 'Kreditkarte'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/25TNNXJ/?uid=2379'
    },
    'bitcoin-code': {
      description: '',
      disclaimer: 'Uns sind zahlreiche Websites bekannt, die falsche prominente Empfehlungen verwenden, um Kunden an unregulierte Broker oder Call-Center zu schicken. Unsere Website schickt Kunden nur an Partner, die uns die erforderlichen regulatorischen Zusicherungen gegeben haben. Dies ist ein Marketingangebot, und unsere regulierten Partner werden bei der Registrierung angeben, welche Dienstleistungen ihnen zur Verfügung stehen.',
      keyFeatures: [
        'Unterstützung von einem Kostenlosen Berater',
        '62% Benutzer berichten von Gewinn aus ihrer ersten Einzahlung',
        'berichteten 31% Erfolgsquote bei risikoreichen Investitionen'
      ],
      logoFeatured: '/images/bitcoin-code.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Code',
      paymentMethods: ['Banküberweisung', 'Debitkarte', 'Kreditkarte'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/26DZF27/'
    },
    'bitcoin-fast-profit': {
      description: '',
      disclaimer: 'Uns sind zahlreiche Websites bekannt, die falsche prominente Empfehlungen verwenden, um Kunden an unregulierte Broker oder Call-Center zu schicken. Unsere Website schickt Kunden nur an Partner, die uns die erforderlichen regulatorischen Zusicherungen gegeben haben. Dies ist ein Marketingangebot, und unsere regulierten Partner werden bei der Registrierung angeben, welche Dienstleistungen ihnen zur Verfügung stehen.',
      keyFeatures: [
        'Unterstützung von einem Kostenlosen Berater',
        '62% Benutzer berichten von Gewinn aus ihrer ersten Einzahlung',
        'berichteten 31% Erfolgsquote bei risikoreichen Investitionen'
      ],
      logoFeatured: '/images/bitcoin-fast-profit.svg',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Fast Profit',
      paymentMethods: ['Banküberweisung', 'Debitkarte', 'Kreditkarte'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/26GC9QS/'
    },
    'bitcoin-trader': {
      description: '',
      disclaimer: 'Uns sind zahlreiche Websites bekannt, die falsche prominente Empfehlungen verwenden, um Kunden an unregulierte Broker oder Call-Center zu schicken. Unsere Website schickt Kunden nur an Partner, die uns die erforderlichen regulatorischen Zusicherungen gegeben haben. Dies ist ein Marketingangebot, und unsere regulierten Partner werden bei der Registrierung angeben, welche Dienstleistungen ihnen zur Verfügung stehen.',
      keyFeatures: [
        'Unterstützung von einem Kostenlosen Berater',
        '62% Benutzer berichten von Gewinn aus ihrer ersten Einzahlung',
        'berichteten 31% Erfolgsquote bei risikoreichen Investitionen'
      ],
      logoFeatured: '/images/bitcoin-trader.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Trader',
      paymentMethods: ['Banküberweisung', 'Debitkarte', 'Kreditkarte'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/26HQ6HF/'
    },
    'bitcoin-millionaire-pro-system': {
      description: '',
      disclaimer: 'Uns sind zahlreiche Websites bekannt, die falsche prominente Empfehlungen verwenden, um Kunden an unregulierte Broker oder Call-Center zu schicken. Unsere Website schickt Kunden nur an Partner, die uns die erforderlichen regulatorischen Zusicherungen gegeben haben. Dies ist ein Marketingangebot, und unsere regulierten Partner werden bei der Registrierung angeben, welche Dienstleistungen ihnen zur Verfügung stehen.',
      keyFeatures: [
        'Unterstützung von einem Kostenlosen Berater',
        '62% Benutzer berichten von Gewinn aus ihrer ersten Einzahlung',
        'berichteten 31% Erfolgsquote bei risikoreichen Investitionen'
      ],
      logoFeatured: '/images/bitcoin-millionaire-pro-system.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Millionaire Pro System',
      paymentMethods: ['Banküberweisung', 'Debitkarte', 'Kreditkarte'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/26LHXZM/'
    },
    'bitcoin-circuit': {
      description: '',
      disclaimer: 'Uns sind zahlreiche Websites bekannt, die falsche prominente Empfehlungen verwenden, um Kunden an unregulierte Broker oder Call-Center zu schicken. Unsere Website schickt Kunden nur an Partner, die uns die erforderlichen regulatorischen Zusicherungen gegeben haben. Dies ist ein Marketingangebot, und unsere regulierten Partner werden bei der Registrierung angeben, welche Dienstleistungen ihnen zur Verfügung stehen.',
      keyFeatures: [
        'Unterstützung von einem Kostenlosen Berater',
        '62% Benutzer berichten von Gewinn aus ihrer ersten Einzahlung',
        'berichteten 31% Erfolgsquote bei risikoreichen Investitionen'
      ],
      logoFeatured: '/images/bitcoin-circuit.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Circuit',
      paymentMethods: ['Banküberweisung', 'Debitkarte', 'Kreditkarte'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/26P9PFT/'
    },
    'bitcoin-future': {
      description: '',
      disclaimer: 'Uns sind zahlreiche Websites bekannt, die falsche prominente Empfehlungen verwenden, um Kunden an unregulierte Broker oder Call-Center zu schicken. Unsere Website schickt Kunden nur an Partner, die uns die erforderlichen regulatorischen Zusicherungen gegeben haben. Dies ist ein Marketingangebot, und unsere regulierten Partner werden bei der Registrierung angeben, welche Dienstleistungen ihnen zur Verfügung stehen.',
      keyFeatures: [
        'Unterstützung von einem Kostenlosen Berater',
        '62% Benutzer berichten von Gewinn aus ihrer ersten Einzahlung',
        'berichteten 31% Erfolgsquote bei risikoreichen Investitionen'
      ],
      logoFeatured: '/images/bitcoin-future.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Future',
      paymentMethods: ['Banküberweisung', 'Debitkarte', 'Kreditkarte'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/26QNL6G/'
    },
    'bitqt': {
      description: '',
      disclaimer: 'Uns sind zahlreiche Websites bekannt, die falsche prominente Empfehlungen verwenden, um Kunden an unregulierte Broker oder Call-Center zu schicken. Unsere Website schickt Kunden nur an Partner, die uns die erforderlichen regulatorischen Zusicherungen gegeben haben. Dies ist ein Marketingangebot, und unsere regulierten Partner werden bei der Registrierung angeben, welche Dienstleistungen ihnen zur Verfügung stehen.',
      keyFeatures: [
        'Unterstützung von einem Kostenlosen Berater',
        '62% Benutzer berichten von Gewinn aus ihrer ersten Einzahlung',
        'berichteten 31% Erfolgsquote bei risikoreichen Investitionen'
      ],
      logoFeatured: '/images/bitqt.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'BitQT',
      paymentMethods: ['Banküberweisung', 'Debitkarte', 'Kreditkarte'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/28Q7XLQ/'
    },
    'trade-2-earn': {
      description: '',
      disclaimer: 'Uns sind zahlreiche Websites bekannt, die falsche prominente Empfehlungen verwenden, um Kunden an unregulierte Broker oder Call-Center zu schicken. Unsere Website schickt Kunden nur an Partner, die uns die erforderlichen regulatorischen Zusicherungen gegeben haben. Dies ist ein Marketingangebot, und unsere regulierten Partner werden bei der Registrierung angeben, welche Dienstleistungen ihnen zur Verfügung stehen.',
      keyFeatures: [
        'Unterstützung von einem Kostenlosen Berater',
        '62% Benutzer berichten von Gewinn aus ihrer ersten Einzahlung',
        'berichteten 31% Erfolgsquote bei risikoreichen Investitionen'
      ],
      logoFeatured: '/images/trade-2-earn.svg',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Trade 2 Earn',
      paymentMethods: ['Banküberweisung', 'Debitkarte', 'Kreditkarte'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/28T1P3Z/'
    },
    'bitcoin-bank-breaker': {
      description: '',
      disclaimer: 'Uns sind zahlreiche Websites bekannt, die falsche prominente Empfehlungen verwenden, um Kunden an unregulierte Broker oder Call-Center zu schicken. Unsere Website schickt Kunden nur an Partner, die uns die erforderlichen regulatorischen Zusicherungen gegeben haben. Dies ist ein Marketingangebot, und unsere regulierten Partner werden bei der Registrierung angeben, welche Dienstleistungen ihnen zur Verfügung stehen.',
      keyFeatures: [
        'Unterstützung von einem Kostenlosen Berater',
        '62% Benutzer berichten von Gewinn aus ihrer ersten Einzahlung',
        'berichteten 31% Erfolgsquote bei risikoreichen Investitionen'
      ],
      logoFeatured: '/images/bitcoin-bank-breaker.svg',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Bank Breaker',
      paymentMethods: ['Banküberweisung', 'Debitkarte', 'Kreditkarte'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2BZPNQ3/'
    },
    'bitcoin-billionaire': {
      description: '',
      disclaimer: 'Uns sind zahlreiche Websites bekannt, die falsche prominente Empfehlungen verwenden, um Kunden an unregulierte Broker oder Call-Center zu schicken. Unsere Website schickt Kunden nur an Partner, die uns die erforderlichen regulatorischen Zusicherungen gegeben haben. Dies ist ein Marketingangebot, und unsere regulierten Partner werden bei der Registrierung angeben, welche Dienstleistungen ihnen zur Verfügung stehen.',
      keyFeatures: [
        'Unterstützung von einem Kostenlosen Berater',
        '62% Benutzer berichten von Gewinn aus ihrer ersten Einzahlung',
        'berichteten 31% Erfolgsquote bei risikoreichen Investitionen'
      ],
      logoFeatured: '/images/bitcoin-billionaire.svg',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Billionaire',
      paymentMethods: ['Banküberweisung', 'Debitkarte', 'Kreditkarte'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2DPBLHB/'
    },
    'bitcoin-loophole': {
      description: '',
      disclaimer: 'Uns sind zahlreiche Websites bekannt, die falsche prominente Empfehlungen verwenden, um Kunden an unregulierte Broker oder Call-Center zu schicken. Unsere Website schickt Kunden nur an Partner, die uns die erforderlichen regulatorischen Zusicherungen gegeben haben. Dies ist ein Marketingangebot, und unsere regulierten Partner werden bei der Registrierung angeben, welche Dienstleistungen ihnen zur Verfügung stehen.',
      keyFeatures: [
        'Unterstützung von einem Kostenlosen Berater',
        '62% Benutzer berichten von Gewinn aus ihrer ersten Einzahlung',
        'berichteten 31% Erfolgsquote bei risikoreichen Investitionen'
      ],
      logoFeatured: '/images/bitcoin-loophole.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Loophole',
      paymentMethods: ['Banküberweisung', 'Debitkarte', 'Kreditkarte'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2DQPH7X/'
    },
    'bitcoin-motion': {
      description: '',
      disclaimer: 'Uns sind zahlreiche Websites bekannt, die falsche prominente Empfehlungen verwenden, um Kunden an unregulierte Broker oder Call-Center zu schicken. Unsere Website schickt Kunden nur an Partner, die uns die erforderlichen regulatorischen Zusicherungen gegeben haben. Dies ist ein Marketingangebot, und unsere regulierten Partner werden bei der Registrierung angeben, welche Dienstleistungen ihnen zur Verfügung stehen.',
      keyFeatures: [
        'Unterstützung von einem Kostenlosen Berater',
        '62% Benutzer berichten von Gewinn aus ihrer ersten Einzahlung',
        'berichteten 31% Erfolgsquote bei risikoreichen Investitionen'
      ],
      logoFeatured: '/images/bitcoin-motion.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Motion',
      paymentMethods: ['Banküberweisung', 'Debitkarte', 'Kreditkarte'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2DS4CZJ/'
    },
    'profit-bitcoin': {
      description: '',
      disclaimer: 'Uns sind zahlreiche Websites bekannt, die falsche prominente Empfehlungen verwenden, um Kunden an unregulierte Broker oder Call-Center zu schicken. Unsere Website schickt Kunden nur an Partner, die uns die erforderlichen regulatorischen Zusicherungen gegeben haben. Dies ist ein Marketingangebot, und unsere regulierten Partner werden bei der Registrierung angeben, welche Dienstleistungen ihnen zur Verfügung stehen.',
      keyFeatures: [
        'Unterstützung von einem Kostenlosen Berater',
        '62% Benutzer berichten von Gewinn aus ihrer ersten Einzahlung',
        'berichteten 31% Erfolgsquote bei risikoreichen Investitionen'
      ],
      logoFeatured: '/images/profit-bitcoin.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Profit Bitcoin',
      paymentMethods: ['Banküberweisung', 'Debitkarte', 'Kreditkarte'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2DTH8P5/'
    },
    'bitcoin-evolution': {
      description: '',
      disclaimer: 'Uns sind zahlreiche Websites bekannt, die falsche prominente Empfehlungen verwenden, um Kunden an unregulierte Broker oder Call-Center zu schicken. Unsere Website schickt Kunden nur an Partner, die uns die erforderlichen regulatorischen Zusicherungen gegeben haben. Dies ist ein Marketingangebot, und unsere regulierten Partner werden bei der Registrierung angeben, welche Dienstleistungen ihnen zur Verfügung stehen.',
      keyFeatures: [
        'Unterstützung von einem Kostenlosen Berater',
        '62% Benutzer berichten von Gewinn aus ihrer ersten Einzahlung',
        'berichteten 31% Erfolgsquote bei risikoreichen Investitionen'
      ],
      logoFeatured: '/images/bitcoin-evolution.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Evolution',
      paymentMethods: ['Banküberweisung', 'Debitkarte', 'Kreditkarte'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2DWW5FQ/'
    },
    'bitcoin-champion': {
      description: '',
      disclaimer: 'Uns sind zahlreiche Websites bekannt, die falsche prominente Empfehlungen verwenden, um Kunden an unregulierte Broker oder Call-Center zu schicken. Unsere Website schickt Kunden nur an Partner, die uns die erforderlichen regulatorischen Zusicherungen gegeben haben. Dies ist ein Marketingangebot, und unsere regulierten Partner werden bei der Registrierung angeben, welche Dienstleistungen ihnen zur Verfügung stehen.',
      keyFeatures: [
        'Unterstützung von einem Kostenlosen Berater',
        '62% Benutzer berichten von Gewinn aus ihrer ersten Einzahlung',
        'berichteten 31% Erfolgsquote bei risikoreichen Investitionen'
      ],
      logoFeatured: '/images/bitcoin-champion.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Champion',
      paymentMethods: ['Banküberweisung', 'Debitkarte', 'Kreditkarte'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2F1MWWZ/'
    },
    'bitcoin-money': {
      description: '',
      disclaimer: 'Uns sind zahlreiche Websites bekannt, die falsche prominente Empfehlungen verwenden, um Kunden an unregulierte Broker oder Call-Center zu schicken. Unsere Website schickt Kunden nur an Partner, die uns die erforderlichen regulatorischen Zusicherungen gegeben haben. Dies ist ein Marketingangebot, und unsere regulierten Partner werden bei der Registrierung angeben, welche Dienstleistungen ihnen zur Verfügung stehen.',
      keyFeatures: [
        'Unterstützung von einem Kostenlosen Berater',
        '62% Benutzer berichten von Gewinn aus ihrer ersten Einzahlung',
        'berichteten 31% Erfolgsquote bei risikoreichen Investitionen'
      ],
      logoFeatured: '/images/bitcoin-money.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Money',
      paymentMethods: ['Banküberweisung', 'Debitkarte', 'Kreditkarte'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2F32RMK/'
    },
    'bitcoin-trend-app': {
      description: '',
      disclaimer: 'Uns sind zahlreiche Websites bekannt, die falsche prominente Empfehlungen verwenden, um Kunden an unregulierte Broker oder Call-Center zu schicken. Unsere Website schickt Kunden nur an Partner, die uns die erforderlichen regulatorischen Zusicherungen gegeben haben. Dies ist ein Marketingangebot, und unsere regulierten Partner werden bei der Registrierung angeben, welche Dienstleistungen ihnen zur Verfügung stehen.',
      keyFeatures: [
        'Unterstützung von einem Kostenlosen Berater',
        '62% Benutzer berichten von Gewinn aus ihrer ersten Einzahlung',
        'berichteten 31% Erfolgsquote bei risikoreichen Investitionen'
      ],
      logoFeatured: '/images/bitcoin-trend-app.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Trend App',
      paymentMethods: ['Banküberweisung', 'Debitkarte', 'Kreditkarte'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2F4FND6/'
    },
    'crypto-comeback-pro': {
      description: '',
      disclaimer: 'Uns sind zahlreiche Websites bekannt, die falsche prominente Empfehlungen verwenden, um Kunden an unregulierte Broker oder Call-Center zu schicken. Unsere Website schickt Kunden nur an Partner, die uns die erforderlichen regulatorischen Zusicherungen gegeben haben. Dies ist ein Marketingangebot, und unsere regulierten Partner werden bei der Registrierung angeben, welche Dienstleistungen ihnen zur Verfügung stehen.',
      keyFeatures: [
        'Unterstützung von einem Kostenlosen Berater',
        '62% Benutzer berichten von Gewinn aus ihrer ersten Einzahlung',
        'berichteten 31% Erfolgsquote bei risikoreichen Investitionen'
      ],
      logoFeatured: '/images/crypto-comeback-pro.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Crypto Comeback Pro',
      paymentMethods: ['Banküberweisung', 'Debitkarte', 'Kreditkarte'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2F5SK4R/'
    },
    'ethereum-system': {
      description: '',
      disclaimer: 'Uns sind zahlreiche Websites bekannt, die falsche prominente Empfehlungen verwenden, um Kunden an unregulierte Broker oder Call-Center zu schicken. Unsere Website schickt Kunden nur an Partner, die uns die erforderlichen regulatorischen Zusicherungen gegeben haben. Dies ist ein Marketingangebot, und unsere regulierten Partner werden bei der Registrierung angeben, welche Dienstleistungen ihnen zur Verfügung stehen.',
      keyFeatures: [
        'Unterstützung von einem Kostenlosen Berater',
        '62% Benutzer berichten von Gewinn aus ihrer ersten Einzahlung',
        'berichteten 31% Erfolgsquote bei risikoreichen Investitionen'
      ],
      logoFeatured: '/images/ethereum-system.svg',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Ethereum System',
      paymentMethods: ['Banküberweisung', 'Debitkarte', 'Kreditkarte'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2F77FTD/'
    },
    'the-bitcoin-millionaire': {
      description: '',
      disclaimer: 'Uns sind zahlreiche Websites bekannt, die falsche prominente Empfehlungen verwenden, um Kunden an unregulierte Broker oder Call-Center zu schicken. Unsere Website schickt Kunden nur an Partner, die uns die erforderlichen regulatorischen Zusicherungen gegeben haben. Dies ist ein Marketingangebot, und unsere regulierten Partner werden bei der Registrierung angeben, welche Dienstleistungen ihnen zur Verfügung stehen.',
      keyFeatures: [
        'Unterstützung von einem Kostenlosen Berater',
        '62% Benutzer berichten von Gewinn aus ihrer ersten Einzahlung',
        'berichteten 31% Erfolgsquote bei risikoreichen Investitionen'
      ],
      logoFeatured: '/images/the-bitcoin-millionaire.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'The Bitcoin Millionaire',
      paymentMethods: ['Banküberweisung', 'Debitkarte', 'Kreditkarte'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2F8LBL1/'
    }
  },

  home: {
    title: '{0} - Empfohlen für {1} {2}',
    title2: 'Finde die sichersten Investments',
    description: `
      {0} analysiert die Kauf-/Verkaufstrends des Marktes, um von der Volatilität zu profitieren und davon zu profitieren. Sie müssen den technischen Handel nicht verstehen, um die Software nutzen zu können, und dieses System hat online zahlreiche positive Bewertungen erhalten.
    `,

    description2: `
      <h2><strong>Wie Broker bewertet werden </strong>- {0}</h2>

      <p>
        Das {1}-Team analysiert und überprüft Makler in der Branche für dich rigoros. Das Team bewertet eine
        Liste von ganzen 57 Eigenschaften, aber einige wichtige Punkte sind:
      </p>
      <ul>
        <li>
          <strong>Qualität des Kundenservice -&nbsp;</strong>Gibt es einen einfach Zugang zu Hilfe? Gibt es leicht zu
          findende Kontaktinformationen? Sind die Informationen klar, einschließlich der Betriebszeiten, Ländercodes
          usw.? Werden eine Vielzahl von Methoden des Kundensupports angeboten wie z.B. E-Mail, Telefon, Chat, Mail?
          Reagieren sie schnell? Bieten sie einen guten Service? Sind sie höflich? Sind sie sachkundig?
        </li>
        <li>
          <strong>Benutzerfreundlichkeit und User Interface -&nbsp;</strong>Websites werden durch ihr User Interface
          definiert; das macht sie attraktiv für User. Innerhalb des Investmentbereichs ist es nicht anders. Nur eine
          Website die gut zu finden, gut aussieht und einfach zu bedienen ist, bietet eine positive Erfahrung mit dem
          Investment.
        </li>
        <li>
          <strong>Kassierer -</strong>&nbsp;Gibt es eine gute Auswahl an Bankmethoden? Ist die Website sicher? Ist es
          einfach, Geld einzuzahlen? Werden Auszahlungen schnell bearbeitet? Von Anfang bis Ende, ist es rundum ein
          einfacher und schneller Prozess?
        </li>
      </ul>
      <p>Und noch ganz viele andere...</p>
    `
  },

  register: {
    title: 'Bevor Sie gehen ...',
    description: 'Die Märkte sind schnelllebig. Verpassen Sie nicht Ihre Chance, von den jüngsten Anstiegen und Rückgängen zu profitieren.Registrieren Sie sich heute und lassen Sie sich überraschen, wenn Sie Ihren ersten 2-Wochen-Gewinn abheben!',
    registerNow: 'Jetzt mein Konto eröffnen!',
    startTradingNow: 'Jetzt mit dem Handel beginnen',
    terms: 'Beim Ausfüllen dieses Formulars nehme ich zur Kenntnis, dass ich an einen externen Handelspartner weitergeleitet werde und dass meine persönlichen Daten weitergegeben werden.',
    fullName: 'Vollständiger Name',
    email: 'E-mail',
    howOldAreU: 'Wie alt sind Sie?',
    loading: 'Anmeldung...'
  },

  footer: {
    description: `
      <p>Risk Warning:</p>
      <p>When investing your capital is at risk.</p>
      <p><br /></p>
      <p>
        Past performance is not an indication of future results. Any investing history presented is less than 5
        years old unless otherwise stated and may not suffice as a basis for investment decisions. Prices may go
        down as well as up, prices can fluctuate widely and you may lose all of or more than the amount you invest.
        Investing is not suitable for everyone; please ensure that you have fully understood the risks and
        legalities involved. If you are unsure, seek independent financial, legal, tax and/or accounting advice.
        This website does not provide investment, financial, legal, tax or accounting advice. Your investment may
        not qualify for investor protection in your country or state of residence, so please conduct your own due
        diligence.
      </p>
      <p>
        {0} is an independent comparison platform and information service that aims to provide you with
        the tools you need to make better decisions. While we are independent, the offers that appear on this site
        are from companies from which {0} receives compensation.&nbsp;
      </p>
      <p>
        Your investment may not qualify for investor protection in your country or state of residence, so please
        conduct your own due diligence. This website is free for you to use but we may receive commission from the
        companies we feature on this site.
      </p>
      <p>
      Wellgard Enterprise Inc <br>
      216913 Suite 1 Second floor , Sound & Vision House, Francis Rachel Street <br>
      Victoria Mahe<br>
       Seychelles
      </p>
    `
  },

  month: {
    "January": "Januar",
    "February": "Februar",
    "March": "März",
    "April": "April",
    "May": "Mai",
    "June": "Juni",
    "July": "Juli",
    "August": "August",
    "September": "September",
    "October": "Oktober",
    "November": "November",
    "December": "Dezember"
  }
};
