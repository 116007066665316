export default {
  // Common translations

  termsConditions: 'Algemene voorwaarden',
  privacy: 'Privacybeleid',
  disclaimer: 'Vrijwaring',
  'min-deposit': 'Minimale storting',
  'user-score': 'Gebruikersscore',
  'start-trading': 'Begin met handelen',
  'more-info': 'meer informatie',
  description: '',
  'payment-methods': 'Betaalmethoden',
  'full-regulations-list': 'Volledige reglementenlijst',
  'other-recommended-partners': 'Andere aanbevolen partners',

  // Offers list

  offers: {
    'immediate-edge': {
      description: '',
      disclaimer: 'We zijn op de hoogte van de talrijke websites die valse aanbevelingen van beroemdheden gebruiken om klanten naar niet-gereguleerde brokers of callcenters te sturen. Onze website stuurt klanten enkel naar de partners die ons de vereiste wettelijke garanties hebben verstrekt. Dit is een marketingaanbieding en onze gereguleerde partners zullen bij aanmelding aangeven welke diensten ze beschikbaar hebben.',
      keyFeatures: [
        'Krijg Hulp Van Een Gratis Adviseur',
        '62% van de gebruikers meldt een winst bij hun eerste storting',
        '31% slagingspercentage bij investeringen met een hoog risico'
      ],
      logoFeatured: '/images/immediate-edge.webp',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Immediate Edge',
      paymentMethods: ['Debetkaart', 'Kredietkaart', 'Overschrijving'],
      starRating: 93,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/27P3D6/'
    },
    'bitcoin-system': {
      description: '',
      disclaimer: 'We zijn op de hoogte van de talrijke websites die valse aanbevelingen van beroemdheden gebruiken om klanten naar niet-gereguleerde brokers of callcenters te sturen. Onze website stuurt klanten enkel naar de partners die ons de vereiste wettelijke garanties hebben verstrekt. Dit is een marketingaanbieding en onze gereguleerde partners zullen bij aanmelding aangeven welke diensten ze beschikbaar hebben.',
      keyFeatures: [
        'Krijg Hulp Van Een Gratis Adviseur',
        '62% van de gebruikers meldt een winst bij hun eerste storting',
        '31% slagingspercentage bij investeringen met een hoog risico'
      ],
      logoFeatured: '/images/bitcoin-system.svg',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin System',
      paymentMethods: ['Debetkaart', 'Kredietkaart', 'Overschrijving'],
      starRating: 91,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2CTPL/'
    },
    'bitcoin-bot': {
      description: '',
      disclaimer: 'We zijn op de hoogte van de talrijke websites die valse aanbevelingen van beroemdheden gebruiken om klanten naar niet-gereguleerde brokers of callcenters te sturen. Onze website stuurt klanten enkel naar de partners die ons de vereiste wettelijke garanties hebben verstrekt. Dit is een marketingaanbieding en onze gereguleerde partners zullen bij aanmelding aangeven welke diensten ze beschikbaar hebben.',
      keyFeatures: [
        'Krijg Hulp Van Een Gratis Adviseur',
        '62% van de gebruikers meldt een winst bij hun eerste storting',
        '31% slagingspercentage bij investeringen met een hoog risico'
      ],
      logoFeatured: '/images/bitcoinbot.jpg',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Bot',
      paymentMethods: ['Debetkaart', 'Kredietkaart', 'Overschrijving'],
      starRating: 98,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/SDFZCK/'
    },
    'bitcoin-era': {
      description: '',
      disclaimer: 'We zijn op de hoogte van de talrijke websites die valse aanbevelingen van beroemdheden gebruiken om klanten naar niet-gereguleerde brokers of callcenters te sturen. Onze website stuurt klanten enkel naar de partners die ons de vereiste wettelijke garanties hebben verstrekt. Dit is een marketingaanbieding en onze gereguleerde partners zullen bij aanmelding aangeven welke diensten ze beschikbaar hebben.',
      keyFeatures: [
        'Krijg Hulp Van Een Gratis Adviseur',
        '62% van de gebruikers meldt een winst bij hun eerste storting',
        '31% slagingspercentage bij investeringen met een hoog risico'
      ],
      logoFeatured: '/images/bitcoin-era.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Era',
      paymentMethods: ['Debetkaart', 'Kredietkaart', 'Overschrijving'],
      starRating: 85,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/5XTBKP/'
    },
    'profit-builder': {
      description: '',
      disclaimer: 'We zijn op de hoogte van de talrijke websites die valse aanbevelingen van beroemdheden gebruiken om klanten naar niet-gereguleerde brokers of callcenters te sturen. Onze website stuurt klanten enkel naar de partners die ons de vereiste wettelijke garanties hebben verstrekt. Dit is een marketingaanbieding en onze gereguleerde partners zullen bij aanmelding aangeven welke diensten ze beschikbaar hebben.',
      keyFeatures: [
        'Krijg Hulp Van Een Gratis Adviseur',
        '62% van de gebruikers meldt een winst bij hun eerste storting',
        '31% slagingspercentage bij investeringen met een hoog risico'
      ],
      logoFeatured: '/images/profitbuilder.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Profit Builder',
      paymentMethods: ['Debetkaart', 'Kredietkaart', 'Overschrijving'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/LDF3B4/'
    },
    '1k-in-1day': {
      description: '',
      disclaimer: 'We zijn op de hoogte van de talrijke websites die valse aanbevelingen van beroemdheden gebruiken om klanten naar niet-gereguleerde brokers of callcenters te sturen. Onze website stuurt klanten enkel naar de partners die ons de vereiste wettelijke garanties hebben verstrekt. Dit is een marketingaanbieding en onze gereguleerde partners zullen bij aanmelding aangeven welke diensten ze beschikbaar hebben.',
      keyFeatures: [
        'Krijg Hulp Van Een Gratis Adviseur',
        '62% van de gebruikers meldt een winst bij hun eerste storting',
        '31% slagingspercentage bij investeringen met een hoog risico'
      ],
      logoFeatured: '/images/1k-in-1day.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: '1K in 1Day',
      paymentMethods: ['Debetkaart', 'Kredietkaart', 'Overschrijving'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2DZ926C/'
    },
    'amazon-stocks-investment': {
      description: '',
      disclaimer: 'We zijn op de hoogte van de talrijke websites die valse aanbevelingen van beroemdheden gebruiken om klanten naar niet-gereguleerde brokers of callcenters te sturen. Onze website stuurt klanten enkel naar de partners die ons de vereiste wettelijke garanties hebben verstrekt. Dit is een marketingaanbieding en onze gereguleerde partners zullen bij aanmelding aangeven welke diensten ze beschikbaar hebben.',
      keyFeatures: [
        'Krijg Hulp Van Een Gratis Adviseur',
        '62% van de gebruikers meldt een winst bij hun eerste storting',
        '31% slagingspercentage bij investeringen met een hoog risico'
      ],
      logoFeatured: '/images/amazon-stocks-investment.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Amazon Stocks Investment',
      paymentMethods: ['Debetkaart', 'Kredietkaart', 'Overschrijving'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/ZDXHZQ/'
    },
    'tradermate': {
      description: '',
      disclaimer: 'We zijn op de hoogte van de talrijke websites die valse aanbevelingen van beroemdheden gebruiken om klanten naar niet-gereguleerde brokers of callcenters te sturen. Onze website stuurt klanten enkel naar de partners die ons de vereiste wettelijke garanties hebben verstrekt. Dit is een marketingaanbieding en onze gereguleerde partners zullen bij aanmelding aangeven welke diensten ze beschikbaar hebben.',
      keyFeatures: [
        'Krijg Hulp Van Een Gratis Adviseur',
        '62% van de gebruikers meldt een winst bij hun eerste storting',
        '31% slagingspercentage bij investeringen met een hoog risico'
      ],
      logoFeatured: '/images/tradermate.svg',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'TraderMate',
      paymentMethods: ['Debetkaart', 'Kredietkaart', 'Overschrijving'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/XN48XM/'
    },
    'bitcoin-profit': {
      description: '',
      disclaimer: 'We zijn op de hoogte van de talrijke websites die valse aanbevelingen van beroemdheden gebruiken om klanten naar niet-gereguleerde brokers of callcenters te sturen. Onze website stuurt klanten enkel naar de partners die ons de vereiste wettelijke garanties hebben verstrekt. Dit is een marketingaanbieding en onze gereguleerde partners zullen bij aanmelding aangeven welke diensten ze beschikbaar hebben.',
      keyFeatures: [
        'Krijg Hulp Van Een Gratis Adviseur',
        '62% van de gebruikers meldt een winst bij hun eerste storting',
        '31% slagingspercentage bij investeringen met een hoog risico'
      ],
      logoFeatured: '/images/bitcoin-profit.svg',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Profit',
      paymentMethods: ['Debetkaart', 'Kredietkaart', 'Overschrijving'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/ZCJM85/'
    },
    'bitcoin-buyer': {
      description: '',
      disclaimer: 'We zijn op de hoogte van de talrijke websites die valse aanbevelingen van beroemdheden gebruiken om klanten naar niet-gereguleerde brokers of callcenters te sturen. Onze website stuurt klanten enkel naar de partners die ons de vereiste wettelijke garanties hebben verstrekt. Dit is een marketingaanbieding en onze gereguleerde partners zullen bij aanmelding aangeven welke diensten ze beschikbaar hebben.',
      keyFeatures: [
        'Krijg Hulp Van Een Gratis Adviseur',
        '62% van de gebruikers meldt een winst bij hun eerste storting',
        '31% slagingspercentage bij investeringen met een hoog risico'
      ],
      logoFeatured: '/images/bitcoin-buyer.svg',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Buyer',
      paymentMethods: ['Debetkaart', 'Kredietkaart', 'Overschrijving'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/ZGBDPC/'
    },
    'yuan-payments': {
      description: '',
      disclaimer: 'We zijn op de hoogte van de talrijke websites die valse aanbevelingen van beroemdheden gebruiken om klanten naar niet-gereguleerde brokers of callcenters te sturen. Onze website stuurt klanten enkel naar de partners die ons de vereiste wettelijke garanties hebben verstrekt. Dit is een marketingaanbieding en onze gereguleerde partners zullen bij aanmelding aangeven welke diensten ze beschikbaar hebben.',
      keyFeatures: [
        'Krijg Hulp Van Een Gratis Adviseur',
        '62% van de gebruikers meldt een winst bij hun eerste storting',
        '31% slagingspercentage bij investeringen met een hoog risico'
      ],
      logoFeatured: '/images/yuan-payments.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Yuan Payments',
      paymentMethods: ['Debetkaart', 'Kredietkaart', 'Overschrijving'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/22C3ZNF/'
    },
    'crypto-bank': {
      description: '',
      disclaimer: 'We zijn op de hoogte van de talrijke websites die valse aanbevelingen van beroemdheden gebruiken om klanten naar niet-gereguleerde brokers of callcenters te sturen. Onze website stuurt klanten enkel naar de partners die ons de vereiste wettelijke garanties hebben verstrekt. Dit is een marketingaanbieding en onze gereguleerde partners zullen bij aanmelding aangeven welke diensten ze beschikbaar hebben.',
      keyFeatures: [
        'Krijg Hulp Van Een Gratis Adviseur',
        '62% van de gebruikers meldt een winst bij hun eerste storting',
        '31% slagingspercentage bij investeringen met een hoog risico'
      ],
      logoFeatured: '/images/crypto-bank.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Crypto Bank',
      paymentMethods: ['Debetkaart', 'Kredietkaart', 'Overschrijving'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/22FTQ5M/'
    },
    'crypto-engine': {
      description: '',
      disclaimer: 'We zijn op de hoogte van de talrijke websites die valse aanbevelingen van beroemdheden gebruiken om klanten naar niet-gereguleerde brokers of callcenters te sturen. Onze website stuurt klanten enkel naar de partners die ons de vereiste wettelijke garanties hebben verstrekt. Dit is een marketingaanbieding en onze gereguleerde partners zullen bij aanmelding aangeven welke diensten ze beschikbaar hebben.',
      keyFeatures: [
        'Krijg Hulp Van Een Gratis Adviseur',
        '62% van de gebruikers meldt een winst bij hun eerste storting',
        '31% slagingspercentage bij investeringen met een hoog risico'
      ],
      logoFeatured: '/images/crypto-engine.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Crypto Engine',
      paymentMethods: ['Debetkaart', 'Kredietkaart', 'Overschrijving'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/22H8LW8/'
    },
    'bitcoin-pro': {
      description: '',
      disclaimer: 'We zijn op de hoogte van de talrijke websites die valse aanbevelingen van beroemdheden gebruiken om klanten naar niet-gereguleerde brokers of callcenters te sturen. Onze website stuurt klanten enkel naar de partners die ons de vereiste wettelijke garanties hebben verstrekt. Dit is een marketingaanbieding en onze gereguleerde partners zullen bij aanmelding aangeven welke diensten ze beschikbaar hebben.',
      keyFeatures: [
        'Krijg Hulp Van Een Gratis Adviseur',
        '62% van de gebruikers meldt een winst bij hun eerste storting',
        '31% slagingspercentage bij investeringen met een hoog risico'
      ],
      logoFeatured: '/images/bitcoin-pro.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Pro',
      paymentMethods: ['Debetkaart', 'Kredietkaart', 'Overschrijving'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/22L2DCG/'
    },

    'bitcoin-bank': {
      description: '',
      disclaimer: 'We zijn op de hoogte van de talrijke websites die valse aanbevelingen van beroemdheden gebruiken om klanten naar niet-gereguleerde brokers of callcenters te sturen. Onze website stuurt klanten enkel naar de partners die ons de vereiste wettelijke garanties hebben verstrekt. Dit is een marketingaanbieding en onze gereguleerde partners zullen bij aanmelding aangeven welke diensten ze beschikbaar hebben.',
      keyFeatures: [
        'Krijg Hulp Van Een Gratis Adviseur',
        '62% van de gebruikers meldt een winst bij hun eerste storting',
        '31% slagingspercentage bij investeringen met een hoog risico'
      ],
      logoFeatured: '/images/bitcoin-bank.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Bank',
      paymentMethods: ['Debetkaart', 'Kredietkaart', 'Overschrijving'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/22RKX9W/'
    },
    'tesler': {
      description: '',
      disclaimer: 'We zijn op de hoogte van de talrijke websites die valse aanbevelingen van beroemdheden gebruiken om klanten naar niet-gereguleerde brokers of callcenters te sturen. Onze website stuurt klanten enkel naar de partners die ons de vereiste wettelijke garanties hebben verstrekt. Dit is een marketingaanbieding en onze gereguleerde partners zullen bij aanmelding aangeven welke diensten ze beschikbaar hebben.',
      keyFeatures: [
        'Krijg Hulp Van Een Gratis Adviseur',
        '62% van de gebruikers meldt een winst bij hun eerste storting',
        '31% slagingspercentage bij investeringen met een hoog risico'
      ],
      logoFeatured: '/images/tesler.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Tesler',
      paymentMethods: ['Debetkaart', 'Kredietkaart', 'Overschrijving'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/22SZS2H/'
    },

    'bitcoin-up': {
      description: '',
      disclaimer: 'We zijn op de hoogte van de talrijke websites die valse aanbevelingen van beroemdheden gebruiken om klanten naar niet-gereguleerde brokers of callcenters te sturen. Onze website stuurt klanten enkel naar de partners die ons de vereiste wettelijke garanties hebben verstrekt. Dit is een marketingaanbieding en onze gereguleerde partners zullen bij aanmelding aangeven welke diensten ze beschikbaar hebben.',
      keyFeatures: [
        'Krijg Hulp Van Een Gratis Adviseur',
        '62% van de gebruikers meldt een winst bij hun eerste storting',
        '31% slagingspercentage bij investeringen met een hoog risico'
      ],
      logoFeatured: '/images/bitcoin-up.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin UP',
      paymentMethods: ['Debetkaart', 'Kredietkaart', 'Overschrijving'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/23PZP9F/'
    },
    'nft-code': {
      description: '',
      disclaimer: 'We zijn op de hoogte van de talrijke websites die valse aanbevelingen van beroemdheden gebruiken om klanten naar niet-gereguleerde brokers of callcenters te sturen. Onze website stuurt klanten enkel naar de partners die ons de vereiste wettelijke garanties hebben verstrekt. Dit is een marketingaanbieding en onze gereguleerde partners zullen bij aanmelding aangeven welke diensten ze beschikbaar hebben.',
      keyFeatures: [
        'Krijg Hulp Van Een Gratis Adviseur',
        '62% van de gebruikers meldt een winst bij hun eerste storting',
        '31% slagingspercentage bij investeringen met een hoog risico'
      ],
      logoFeatured: '/images/nft-code.svg',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'NFT Code',
      paymentMethods: ['Debetkaart', 'Kredietkaart', 'Overschrijving'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/23W5CH8/'
    },
    'bit-iq': {
      description: '',
      disclaimer: 'We zijn op de hoogte van de talrijke websites die valse aanbevelingen van beroemdheden gebruiken om klanten naar niet-gereguleerde brokers of callcenters te sturen. Onze website stuurt klanten enkel naar de partners die ons de vereiste wettelijke garanties hebben verstrekt. Dit is een marketingaanbieding en onze gereguleerde partners zullen bij aanmelding aangeven welke diensten ze beschikbaar hebben.',
      keyFeatures: [
        'Krijg Hulp Van Een Gratis Adviseur',
        '62% van de gebruikers meldt een winst bij hun eerste storting',
        '31% slagingspercentage bij investeringen met een hoog risico'
      ],
      logoFeatured: '/images/bit-iq.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bit IQ',
      paymentMethods: ['Debetkaart', 'Kredietkaart', 'Overschrijving'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/25TNNXJ/?uid=2382'
    },
    'bitcoin-code': {
      description: '',
      disclaimer: 'We zijn op de hoogte van de talrijke websites die valse aanbevelingen van beroemdheden gebruiken om klanten naar niet-gereguleerde brokers of callcenters te sturen. Onze website stuurt klanten enkel naar de partners die ons de vereiste wettelijke garanties hebben verstrekt. Dit is een marketingaanbieding en onze gereguleerde partners zullen bij aanmelding aangeven welke diensten ze beschikbaar hebben.',
      keyFeatures: [
        'Krijg Hulp Van Een Gratis Adviseur',
        '62% van de gebruikers meldt een winst bij hun eerste storting',
        '31% slagingspercentage bij investeringen met een hoog risico'
      ],
      logoFeatured: '/images/bitcoin-code.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Code',
      paymentMethods: ['Debetkaart', 'Kredietkaart', 'Overschrijving'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/26DZF27/'
    },
    'bitcoin-fast-profit': {
      description: '',
      disclaimer: 'We zijn op de hoogte van de talrijke websites die valse aanbevelingen van beroemdheden gebruiken om klanten naar niet-gereguleerde brokers of callcenters te sturen. Onze website stuurt klanten enkel naar de partners die ons de vereiste wettelijke garanties hebben verstrekt. Dit is een marketingaanbieding en onze gereguleerde partners zullen bij aanmelding aangeven welke diensten ze beschikbaar hebben.',
      keyFeatures: [
        'Krijg Hulp Van Een Gratis Adviseur',
        '62% van de gebruikers meldt een winst bij hun eerste storting',
        '31% slagingspercentage bij investeringen met een hoog risico'
      ],
      logoFeatured: '/images/bitcoin-fast-profit.svg',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Fast Profit',
      paymentMethods: ['Debetkaart', 'Kredietkaart', 'Overschrijving'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/26GC9QS/'
    },
    'bitcoin-trader': {
      description: '',
      disclaimer: 'We zijn op de hoogte van de talrijke websites die valse aanbevelingen van beroemdheden gebruiken om klanten naar niet-gereguleerde brokers of callcenters te sturen. Onze website stuurt klanten enkel naar de partners die ons de vereiste wettelijke garanties hebben verstrekt. Dit is een marketingaanbieding en onze gereguleerde partners zullen bij aanmelding aangeven welke diensten ze beschikbaar hebben.',
      keyFeatures: [
        'Krijg Hulp Van Een Gratis Adviseur',
        '62% van de gebruikers meldt een winst bij hun eerste storting',
        '31% slagingspercentage bij investeringen met een hoog risico'
      ],
      logoFeatured: '/images/bitcoin-trader.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Trader',
      paymentMethods: ['Debetkaart', 'Kredietkaart', 'Overschrijving'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/26HQ6HF/'
    },
    'bitcoin-millionaire-pro-system': {
      description: '',
      disclaimer: 'We zijn op de hoogte van de talrijke websites die valse aanbevelingen van beroemdheden gebruiken om klanten naar niet-gereguleerde brokers of callcenters te sturen. Onze website stuurt klanten enkel naar de partners die ons de vereiste wettelijke garanties hebben verstrekt. Dit is een marketingaanbieding en onze gereguleerde partners zullen bij aanmelding aangeven welke diensten ze beschikbaar hebben.',
      keyFeatures: [
        'Krijg Hulp Van Een Gratis Adviseur',
        '62% van de gebruikers meldt een winst bij hun eerste storting',
        '31% slagingspercentage bij investeringen met een hoog risico'
      ],
      logoFeatured: '/images/bitcoin-millionaire-pro-system.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Millionaire Pro System',
      paymentMethods: ['Debetkaart', 'Kredietkaart', 'Overschrijving'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/26LHXZM/'
    },
    'bitcoin-circuit': {
      description: '',
      disclaimer: 'We zijn op de hoogte van de talrijke websites die valse aanbevelingen van beroemdheden gebruiken om klanten naar niet-gereguleerde brokers of callcenters te sturen. Onze website stuurt klanten enkel naar de partners die ons de vereiste wettelijke garanties hebben verstrekt. Dit is een marketingaanbieding en onze gereguleerde partners zullen bij aanmelding aangeven welke diensten ze beschikbaar hebben.',
      keyFeatures: [
        'Krijg Hulp Van Een Gratis Adviseur',
        '62% van de gebruikers meldt een winst bij hun eerste storting',
        '31% slagingspercentage bij investeringen met een hoog risico'
      ],
      logoFeatured: '/images/bitcoin-circuit.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Circuit',
      paymentMethods: ['Debetkaart', 'Kredietkaart', 'Overschrijving'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/26P9PFT/'
    },
    'bitcoin-future': {
      description: '',
      disclaimer: 'We zijn op de hoogte van de talrijke websites die valse aanbevelingen van beroemdheden gebruiken om klanten naar niet-gereguleerde brokers of callcenters te sturen. Onze website stuurt klanten enkel naar de partners die ons de vereiste wettelijke garanties hebben verstrekt. Dit is een marketingaanbieding en onze gereguleerde partners zullen bij aanmelding aangeven welke diensten ze beschikbaar hebben.',
      keyFeatures: [
        'Krijg Hulp Van Een Gratis Adviseur',
        '62% van de gebruikers meldt een winst bij hun eerste storting',
        '31% slagingspercentage bij investeringen met een hoog risico'
      ],
      logoFeatured: '/images/bitcoin-future.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Future',
      paymentMethods: ['Debetkaart', 'Kredietkaart', 'Overschrijving'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/26QNL6G/'
    },
    'bitqt': {
      description: '',
      disclaimer: 'We zijn op de hoogte van de talrijke websites die valse aanbevelingen van beroemdheden gebruiken om klanten naar niet-gereguleerde brokers of callcenters te sturen. Onze website stuurt klanten enkel naar de partners die ons de vereiste wettelijke garanties hebben verstrekt. Dit is een marketingaanbieding en onze gereguleerde partners zullen bij aanmelding aangeven welke diensten ze beschikbaar hebben.',
      keyFeatures: [
        'Krijg Hulp Van Een Gratis Adviseur',
        '62% van de gebruikers meldt een winst bij hun eerste storting',
        '31% slagingspercentage bij investeringen met een hoog risico'
      ],
      logoFeatured: '/images/bitqt.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'BitQT',
      paymentMethods: ['Debetkaart', 'Kredietkaart', 'Overschrijving'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/28Q7XLQ/'
    },
    'trade-2-earn': {
      description: '',
      disclaimer: 'We zijn op de hoogte van de talrijke websites die valse aanbevelingen van beroemdheden gebruiken om klanten naar niet-gereguleerde brokers of callcenters te sturen. Onze website stuurt klanten enkel naar de partners die ons de vereiste wettelijke garanties hebben verstrekt. Dit is een marketingaanbieding en onze gereguleerde partners zullen bij aanmelding aangeven welke diensten ze beschikbaar hebben.',
      keyFeatures: [
        'Krijg Hulp Van Een Gratis Adviseur',
        '62% van de gebruikers meldt een winst bij hun eerste storting',
        '31% slagingspercentage bij investeringen met een hoog risico'
      ],
      logoFeatured: '/images/trade-2-earn.svg',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Trade 2 Earn',
      paymentMethods: ['Debetkaart', 'Kredietkaart', 'Overschrijving'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/28T1P3Z/'
    },
    'bitcoin-bank-breaker': {
      description: '',
      disclaimer: 'We zijn op de hoogte van de talrijke websites die valse aanbevelingen van beroemdheden gebruiken om klanten naar niet-gereguleerde brokers of callcenters te sturen. Onze website stuurt klanten enkel naar de partners die ons de vereiste wettelijke garanties hebben verstrekt. Dit is een marketingaanbieding en onze gereguleerde partners zullen bij aanmelding aangeven welke diensten ze beschikbaar hebben.',
      keyFeatures: [
        'Krijg Hulp Van Een Gratis Adviseur',
        '62% van de gebruikers meldt een winst bij hun eerste storting',
        '31% slagingspercentage bij investeringen met een hoog risico'
      ],
      logoFeatured: '/images/bitcoin-bank-breaker.svg',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Bank Breaker',
      paymentMethods: ['Debetkaart', 'Kredietkaart', 'Overschrijving'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2BZPNQ3/'
    },
    'bitcoin-billionaire': {
      description: '',
      disclaimer: 'We zijn op de hoogte van de talrijke websites die valse aanbevelingen van beroemdheden gebruiken om klanten naar niet-gereguleerde brokers of callcenters te sturen. Onze website stuurt klanten enkel naar de partners die ons de vereiste wettelijke garanties hebben verstrekt. Dit is een marketingaanbieding en onze gereguleerde partners zullen bij aanmelding aangeven welke diensten ze beschikbaar hebben.',
      keyFeatures: [
        'Krijg Hulp Van Een Gratis Adviseur',
        '62% van de gebruikers meldt een winst bij hun eerste storting',
        '31% slagingspercentage bij investeringen met een hoog risico'
      ],
      logoFeatured: '/images/bitcoin-billionaire.svg',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Billionaire',
      paymentMethods: ['Debetkaart', 'Kredietkaart', 'Overschrijving'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2DPBLHB/'
    },
    'bitcoin-loophole': {
      description: '',
      disclaimer: 'We zijn op de hoogte van de talrijke websites die valse aanbevelingen van beroemdheden gebruiken om klanten naar niet-gereguleerde brokers of callcenters te sturen. Onze website stuurt klanten enkel naar de partners die ons de vereiste wettelijke garanties hebben verstrekt. Dit is een marketingaanbieding en onze gereguleerde partners zullen bij aanmelding aangeven welke diensten ze beschikbaar hebben.',
      keyFeatures: [
        'Krijg Hulp Van Een Gratis Adviseur',
        '62% van de gebruikers meldt een winst bij hun eerste storting',
        '31% slagingspercentage bij investeringen met een hoog risico'
      ],
      logoFeatured: '/images/bitcoin-loophole.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Loophole',
      paymentMethods: ['Debetkaart', 'Kredietkaart', 'Overschrijving'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2DQPH7X/'
    },
    'bitcoin-motion': {
      description: '',
      disclaimer: 'We zijn op de hoogte van de talrijke websites die valse aanbevelingen van beroemdheden gebruiken om klanten naar niet-gereguleerde brokers of callcenters te sturen. Onze website stuurt klanten enkel naar de partners die ons de vereiste wettelijke garanties hebben verstrekt. Dit is een marketingaanbieding en onze gereguleerde partners zullen bij aanmelding aangeven welke diensten ze beschikbaar hebben.',
      keyFeatures: [
        'Krijg Hulp Van Een Gratis Adviseur',
        '62% van de gebruikers meldt een winst bij hun eerste storting',
        '31% slagingspercentage bij investeringen met een hoog risico'
      ],
      logoFeatured: '/images/bitcoin-motion.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Motion',
      paymentMethods: ['Debetkaart', 'Kredietkaart', 'Overschrijving'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2DS4CZJ/'
    },
    'profit-bitcoin': {
      description: '',
      disclaimer: 'We zijn op de hoogte van de talrijke websites die valse aanbevelingen van beroemdheden gebruiken om klanten naar niet-gereguleerde brokers of callcenters te sturen. Onze website stuurt klanten enkel naar de partners die ons de vereiste wettelijke garanties hebben verstrekt. Dit is een marketingaanbieding en onze gereguleerde partners zullen bij aanmelding aangeven welke diensten ze beschikbaar hebben.',
      keyFeatures: [
        'Krijg Hulp Van Een Gratis Adviseur',
        '62% van de gebruikers meldt een winst bij hun eerste storting',
        '31% slagingspercentage bij investeringen met een hoog risico'
      ],
      logoFeatured: '/images/profit-bitcoin.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Profit Bitcoin',
      paymentMethods: ['Debetkaart', 'Kredietkaart', 'Overschrijving'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2DTH8P5/'
    },
    'bitcoin-evolution': {
      description: '',
      disclaimer: 'We zijn op de hoogte van de talrijke websites die valse aanbevelingen van beroemdheden gebruiken om klanten naar niet-gereguleerde brokers of callcenters te sturen. Onze website stuurt klanten enkel naar de partners die ons de vereiste wettelijke garanties hebben verstrekt. Dit is een marketingaanbieding en onze gereguleerde partners zullen bij aanmelding aangeven welke diensten ze beschikbaar hebben.',
      keyFeatures: [
        'Krijg Hulp Van Een Gratis Adviseur',
        '62% van de gebruikers meldt een winst bij hun eerste storting',
        '31% slagingspercentage bij investeringen met een hoog risico'
      ],
      logoFeatured: '/images/bitcoin-evolution.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Evolution',
      paymentMethods: ['Debetkaart', 'Kredietkaart', 'Overschrijving'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2DWW5FQ/'
    },
    'bitcoin-champion': {
      description: '',
      disclaimer: 'We zijn op de hoogte van de talrijke websites die valse aanbevelingen van beroemdheden gebruiken om klanten naar niet-gereguleerde brokers of callcenters te sturen. Onze website stuurt klanten enkel naar de partners die ons de vereiste wettelijke garanties hebben verstrekt. Dit is een marketingaanbieding en onze gereguleerde partners zullen bij aanmelding aangeven welke diensten ze beschikbaar hebben.',
      keyFeatures: [
        'Krijg Hulp Van Een Gratis Adviseur',
        '62% van de gebruikers meldt een winst bij hun eerste storting',
        '31% slagingspercentage bij investeringen met een hoog risico'
      ],
      logoFeatured: '/images/bitcoin-champion.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Champion',
      paymentMethods: ['Debetkaart', 'Kredietkaart', 'Overschrijving'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2F1MWWZ/'
    },
    'bitcoin-money': {
      description: '',
      disclaimer: 'We zijn op de hoogte van de talrijke websites die valse aanbevelingen van beroemdheden gebruiken om klanten naar niet-gereguleerde brokers of callcenters te sturen. Onze website stuurt klanten enkel naar de partners die ons de vereiste wettelijke garanties hebben verstrekt. Dit is een marketingaanbieding en onze gereguleerde partners zullen bij aanmelding aangeven welke diensten ze beschikbaar hebben.',
      keyFeatures: [
        'Krijg Hulp Van Een Gratis Adviseur',
        '62% van de gebruikers meldt een winst bij hun eerste storting',
        '31% slagingspercentage bij investeringen met een hoog risico'
      ],
      logoFeatured: '/images/bitcoin-money.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Money',
      paymentMethods: ['Debetkaart', 'Kredietkaart', 'Overschrijving'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2F32RMK/'
    },
    'bitcoin-trend-app': {
      description: '',
      disclaimer: 'We zijn op de hoogte van de talrijke websites die valse aanbevelingen van beroemdheden gebruiken om klanten naar niet-gereguleerde brokers of callcenters te sturen. Onze website stuurt klanten enkel naar de partners die ons de vereiste wettelijke garanties hebben verstrekt. Dit is een marketingaanbieding en onze gereguleerde partners zullen bij aanmelding aangeven welke diensten ze beschikbaar hebben.',
      keyFeatures: [
        'Krijg Hulp Van Een Gratis Adviseur',
        '62% van de gebruikers meldt een winst bij hun eerste storting',
        '31% slagingspercentage bij investeringen met een hoog risico'
      ],
      logoFeatured: '/images/bitcoin-trend-app.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Trend App',
      paymentMethods: ['Debetkaart', 'Kredietkaart', 'Overschrijving'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2F4FND6/'
    },
    'crypto-comeback-pro': {
      description: '',
      disclaimer: 'We zijn op de hoogte van de talrijke websites die valse aanbevelingen van beroemdheden gebruiken om klanten naar niet-gereguleerde brokers of callcenters te sturen. Onze website stuurt klanten enkel naar de partners die ons de vereiste wettelijke garanties hebben verstrekt. Dit is een marketingaanbieding en onze gereguleerde partners zullen bij aanmelding aangeven welke diensten ze beschikbaar hebben.',
      keyFeatures: [
        'Krijg Hulp Van Een Gratis Adviseur',
        '62% van de gebruikers meldt een winst bij hun eerste storting',
        '31% slagingspercentage bij investeringen met een hoog risico'
      ],
      logoFeatured: '/images/crypto-comeback-pro.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Crypto Comeback Pro',
      paymentMethods: ['Debetkaart', 'Kredietkaart', 'Overschrijving'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2F5SK4R/'
    },
    'ethereum-system': {
      description: '',
      disclaimer: 'We zijn op de hoogte van de talrijke websites die valse aanbevelingen van beroemdheden gebruiken om klanten naar niet-gereguleerde brokers of callcenters te sturen. Onze website stuurt klanten enkel naar de partners die ons de vereiste wettelijke garanties hebben verstrekt. Dit is een marketingaanbieding en onze gereguleerde partners zullen bij aanmelding aangeven welke diensten ze beschikbaar hebben.',
      keyFeatures: [
        'Krijg Hulp Van Een Gratis Adviseur',
        '62% van de gebruikers meldt een winst bij hun eerste storting',
        '31% slagingspercentage bij investeringen met een hoog risico'
      ],
      logoFeatured: '/images/ethereum-system.svg',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Ethereum System',
      paymentMethods: ['Debetkaart', 'Kredietkaart', 'Overschrijving'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2F77FTD/'
    },
    'the-bitcoin-millionaire': {
      description: '',
      disclaimer: 'We zijn op de hoogte van de talrijke websites die valse aanbevelingen van beroemdheden gebruiken om klanten naar niet-gereguleerde brokers of callcenters te sturen. Onze website stuurt klanten enkel naar de partners die ons de vereiste wettelijke garanties hebben verstrekt. Dit is een marketingaanbieding en onze gereguleerde partners zullen bij aanmelding aangeven welke diensten ze beschikbaar hebben.',
      keyFeatures: [
        'Krijg Hulp Van Een Gratis Adviseur',
        '62% van de gebruikers meldt een winst bij hun eerste storting',
        '31% slagingspercentage bij investeringen met een hoog risico'
      ],
      logoFeatured: '/images/the-bitcoin-millionaire.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'The Bitcoin Millionaire',
      paymentMethods: ['Debetkaart', 'Kredietkaart', 'Overschrijving'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2F8LBL1/'
    }
  },

  home: {
    title: '{0} - Aanbevolen voor {1} {2}',
    title2: 'Vind de veiligste plaatsen om te investeren',
    description: `
      {0} analyseert de koop- en verkooptrends op de markt om zo van de volatiliteit te profiteren en hier winst mee te boeken. Je hoeft het technische handelen niet te begrijpen om de software te kunnen gebruiken en dit systeem heeft talloze positieve online recensies ontvangen.
    `,

    description2: `
      <h2><strong>Hoe makelaars beoordeeld worden </strong>- {0}</h2>
      <p>
        Het wealth-info.com team analyseert en beoordeelt nauwgezet de makelaars in de sector, opdat jij dat niet hoeft te
        doen. Het team evalueert een lijst met maar liefst 57 items, maar enkele belangrijke aandachtspunten zijn:
      </p>
      <ul>
        <li>
          <strong>De kwaliteit van de klantenservice –</strong>&nbsp;Bieden ze een gemakkelijk te gebruiken help sectie?
          Is het gemakkelijk contactgegevens te vinden? Is de informatie duidelijk, met inbegrip van de openingstijden,
          landcodes etc.? Bieden ze verschillende klantondersteuningsmethoden, zoals e-mail, telefoon, chat, per post?
          Reageren ze snel? Bieden ze goede service? Zijn ze beleefd? Zijn ze goed geïnformeerd?
        </li>
        <li>
          <strong>Gebruiksgemak en gebruikersinterface –</strong>&nbsp;Websites worden bepaald door hun interface. Dit is
          wat hen aantrekkelijk maakt om te gebruiken en het is zeker niet anders binnen de beleggingssector. Het vinden
          van een site die er goed uitziet en gemakkelijk te gebruiken is, is cruciaal voor een positieve
          beleggingservaring.
        </li>
        <li>
          <strong>De kassier –</strong>&nbsp;Zijn de bankier mogelijkheden veelzijdig? Is de site veilig? Is het
          gemakkelijk om geld te storten? Worden opnames snel verwerkt? Is het een gemakkelijk en snel proces van begin
          tot einde?
        </li>
      </ul>
      <p>Plus nog veel meer…</p>
    `
  },

  register: {
    title: 'Voordat je weggaat...',
    description: 'De markten bewegen snel. Mis je kans niet om te kunnen profiteren van de laatste stijgingen en dalingen.Registreer je vandaag nog en laat je verbazen als je je tweewekelijkse winst opneemt!',
    registerNow: 'Registreer je nu',
    startTradingNow: 'Begin nu met handelen',
    terms: 'Door dit formulier in te vullen begrijp ik dat ik zal worden doorgestuurd naar een handelspartner van een derde partij en dat mijn persoonlijke informatie zal worden gedeeld.',
    fullName: 'Voor-en achternaam',
    email: 'E-mail',
    howOldAreU: 'Hoe oud ben jij?',
    loading: 'Registratie...'
  },

  footer: {
    description: `
      <p>Risk Warning:</p>
      <p>When investing your capital is at risk.</p>
      <p><br /></p>
      <p>
        Past performance is not an indication of future results. Any investing history presented is less than 5
        years old unless otherwise stated and may not suffice as a basis for investment decisions. Prices may go
        down as well as up, prices can fluctuate widely and you may lose all of or more than the amount you invest.
        Investing is not suitable for everyone; please ensure that you have fully understood the risks and
        legalities involved. If you are unsure, seek independent financial, legal, tax and/or accounting advice.
        This website does not provide investment, financial, legal, tax or accounting advice. Your investment may
        not qualify for investor protection in your country or state of residence, so please conduct your own due
        diligence.
      </p>
      <p>
        {0} is an independent comparison platform and information service that aims to provide you with
        the tools you need to make better decisions. While we are independent, the offers that appear on this site
        are from companies from which {0} receives compensation.&nbsp;
      </p>
      <p>
        Your investment may not qualify for investor protection in your country or state of residence, so please
        conduct your own due diligence. This website is free for you to use but we may receive commission from the
        companies we feature on this site.
      </p>
      <p>
      Wellgard Enterprise Inc <br>
      216913 Suite 1 Second floor , Sound & Vision House, Francis Rachel Street <br>
      Victoria Mahe<br>
       Seychelles
      </p>
    `
  },

  month: {
    "January": "januari",
    "February": "februari",
    "March": "maart",
    "April": "April",
    "May": "Mei",
    "June": "juni",
    "July": "juli",
    "August": "augustus",
    "September": "September",
    "October": "oktober",
    "November": "november",
    "December": "December"
  }
};
