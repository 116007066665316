import Vue from "vue";
import VueI18n from "vue-i18n";

import en from "@/core/config/i18n/en";
import de from "@/core/config/i18n/de";
import es from "@/core/config/i18n/es";
import it from "@/core/config/i18n/it";
import pt from "@/core/config/i18n/pt";
import nl from "@/core/config/i18n/nl";

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: 'en', 
  messages: { en, de, es, it, pt, nl }
});

export default i18n;
