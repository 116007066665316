import Vue from 'vue';
import App from '@/App.vue';

import router from "@/router";
import store from "@/core/store";

// import vuetify from '@/plugins/vuetify';
import i18n from "@/plugins/i18n";
import Vuelidate from 'vuelidate'
import VueCookies from 'vue-cookies'
import ApiService from "@/core/services/api.service";

import 'intl-tel-input/build/css/intlTelInput.css'

import '@/styles/main.css';

Vue.use(Vuelidate)
Vue.use(VueCookies, { expire: '180d'})

ApiService.init();

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  // vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
