<template>
  <footer class="bg-warmGray-200">
    <div class="container lg:py-8 lg:px-10 max-w-7xl">
      <div class="prose-sm prose text-warmGray-500 max-w-none">
        <div class="pt-2 general-footer-disclaimer">
          <p>
            <router-link class="mr-4" to="/terms-conditions/">{{$t('termsConditions')}}</router-link>
            <router-link class="mr-4" to="/privacy-policy/">{{$t('privacy')}}</router-link>
            <router-link class="mr-4" to="/disclaimer/">{{$t('disclaimer')}}</router-link>
          </p>
          <div v-html="$t('footer.description', [domain])"></div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  computed: {
    domain() {
      return process.env.VUE_APP_DOMAIN || 'wealth-researchs.com';
    }
  }
}
</script>
