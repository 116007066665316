export default {
  // Common translations

  termsConditions: 'Termini e Condizioni',
  privacy: 'Politica sull Privacy',
  disclaimer: 'Esclusione di Responsabilità',
  'min-deposit': 'Deposito minimo',
  'user-score': 'Valutazione dell’utente',
  'start-trading': 'Inizio Trading',
  'more-info': 'Ulteriori informazioni',
  description: 'Descrizione',
  'payment-methods': 'Modalità di pagamento',
  'full-regulations-list': 'Elenco completo dei regolamenti',
  'other-recommended-partners': 'Altri Partners Consigliati',

  // Offers list

  offers: {
    'immediate-edge': {
      description: '',
      disclaimer: `Siamo consapevoli che esistano numerosi siti Web che utilizzano false testimonianze di celebrità per inviare clienti a broker o call center non regolamentati. Il nostro sito Web invierà i clienti solo ai partner che ci hanno fornito le garanzie normative richieste. Questa è un'offerta di marketing e i nostri partner regolamentati indicheranno quali servizi hanno a disposizione al momento della registrazione.`,
      keyFeatures: [
        'Richiedi il Supporto di un Consulente Gratis',
        'Il 62% degli utenti segnala un profitto a partire dal primo deposito',
        'Segnalato un tasso di successo del 31% negli investimenti ad alto rischio'
      ],
      logoFeatured: '/images/immediate-edge.webp',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Immediate Edge',
      paymentMethods: ['Bonifico bancario', 'Carta di Credito', 'Carta di Debito'],
      starRating: 93,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/27P3D6/'
    },
    'bitcoin-system': {
      description: 'Descrizione',
      disclaimer: `Siamo consapevoli che esistano numerosi siti Web che utilizzano false testimonianze di celebrità per inviare clienti a broker o call center non regolamentati. Il nostro sito Web invierà i clienti solo ai partner che ci hanno fornito le garanzie normative richieste. Questa è un'offerta di marketing e i nostri partner regolamentati indicheranno quali servizi hanno a disposizione al momento della registrazione.`,
      keyFeatures: [
        'Richiedi il Supporto di un Consulente Gratis',
        'Il 62% degli utenti segnala un profitto a partire dal primo deposito',
        'Segnalato un tasso di successo del 31% negli investimenti ad alto rischio'
      ],
      logoFeatured: '/images/bitcoin-system.svg',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin System',
      paymentMethods: ['Bonifico bancario', 'Carta di Credito', 'Carta di Debito'],
      starRating: 91,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2CTPL/'
    },
    'bitcoin-bot': {
      description: 'Descrizione',
      disclaimer: `Siamo consapevoli che esistano numerosi siti Web che utilizzano false testimonianze di celebrità per inviare clienti a broker o call center non regolamentati. Il nostro sito Web invierà i clienti solo ai partner che ci hanno fornito le garanzie normative richieste. Questa è un'offerta di marketing e i nostri partner regolamentati indicheranno quali servizi hanno a disposizione al momento della registrazione.`,
      keyFeatures: [
        'Richiedi il Supporto di un Consulente Gratis',
        'Il 62% degli utenti segnala un profitto a partire dal primo deposito',
        'Segnalato un tasso di successo del 31% negli investimenti ad alto rischio'
      ],
      logoFeatured: '/images/bitcoinbot.jpg',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Bot',
      paymentMethods: ['Bonifico bancario', 'Carta di Credito', 'Carta di Debito'],
      starRating: 98,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/SDFZCK/'
    },
    'bitcoin-era': {
      description: 'Descrizione',
      disclaimer: `Siamo consapevoli che esistano numerosi siti Web che utilizzano false testimonianze di celebrità per inviare clienti a broker o call center non regolamentati. Il nostro sito Web invierà i clienti solo ai partner che ci hanno fornito le garanzie normative richieste. Questa è un'offerta di marketing e i nostri partner regolamentati indicheranno quali servizi hanno a disposizione al momento della registrazione.`,
      keyFeatures: [
        'Richiedi il Supporto di un Consulente Gratis',
        'Il 62% degli utenti segnala un profitto a partire dal primo deposito',
        'Segnalato un tasso di successo del 31% negli investimenti ad alto rischio'
      ],
      logoFeatured: '/images/bitcoin-era.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Era',
      paymentMethods: ['Bonifico bancario', 'Carta di Credito', 'Carta di Debito'],
      starRating: 85,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/5XTBKP/'
    },
    'profit-builder': {
      description: 'Descrizione',
      disclaimer: `Siamo consapevoli che esistano numerosi siti Web che utilizzano false testimonianze di celebrità per inviare clienti a broker o call center non regolamentati. Il nostro sito Web invierà i clienti solo ai partner che ci hanno fornito le garanzie normative richieste. Questa è un'offerta di marketing e i nostri partner regolamentati indicheranno quali servizi hanno a disposizione al momento della registrazione.`,
      keyFeatures: [
        'Richiedi il Supporto di un Consulente Gratis',
        'Il 62% degli utenti segnala un profitto a partire dal primo deposito',
        'Segnalato un tasso di successo del 31% negli investimenti ad alto rischio'
      ],
      logoFeatured: '/images/profitbuilder.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Profit Builder',
      paymentMethods: ['Bonifico bancario', 'Carta di Credito', 'Carta di Debito'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/LDF3B4/'
    },
    '1k-in-1day': {
      description: 'Descrizione',
      disclaimer: `Siamo consapevoli che esistano numerosi siti Web che utilizzano false testimonianze di celebrità per inviare clienti a broker o call center non regolamentati. Il nostro sito Web invierà i clienti solo ai partner che ci hanno fornito le garanzie normative richieste. Questa è un'offerta di marketing e i nostri partner regolamentati indicheranno quali servizi hanno a disposizione al momento della registrazione.`,
      keyFeatures: [
        'Richiedi il Supporto di un Consulente Gratis',
        'Il 62% degli utenti segnala un profitto a partire dal primo deposito',
        'Segnalato un tasso di successo del 31% negli investimenti ad alto rischio'
      ],
      logoFeatured: '/images/1k-in-1day.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: '1K in 1Day',
      paymentMethods: ['Bonifico bancario', 'Carta di Credito', 'Carta di Debito'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2DZ926C/'
    },
    'amazon-stocks-investment': {
      description: 'Descrizione',
      disclaimer: `Siamo consapevoli che esistano numerosi siti Web che utilizzano false testimonianze di celebrità per inviare clienti a broker o call center non regolamentati. Il nostro sito Web invierà i clienti solo ai partner che ci hanno fornito le garanzie normative richieste. Questa è un'offerta di marketing e i nostri partner regolamentati indicheranno quali servizi hanno a disposizione al momento della registrazione.`,
      keyFeatures: [
        'Richiedi il Supporto di un Consulente Gratis',
        'Il 62% degli utenti segnala un profitto a partire dal primo deposito',
        'Segnalato un tasso di successo del 31% negli investimenti ad alto rischio'
      ],
      logoFeatured: '/images/amazon-stocks-investment.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Amazon Stocks Investment',
      paymentMethods: ['Bonifico bancario', 'Carta di Credito', 'Carta di Debito'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/ZDXHZQ/'
    },
    'tradermate': {
      description: 'Descrizione',
      disclaimer: `Siamo consapevoli che esistano numerosi siti Web che utilizzano false testimonianze di celebrità per inviare clienti a broker o call center non regolamentati. Il nostro sito Web invierà i clienti solo ai partner che ci hanno fornito le garanzie normative richieste. Questa è un'offerta di marketing e i nostri partner regolamentati indicheranno quali servizi hanno a disposizione al momento della registrazione.`,
      keyFeatures: [
        'Richiedi il Supporto di un Consulente Gratis',
        'Il 62% degli utenti segnala un profitto a partire dal primo deposito',
        'Segnalato un tasso di successo del 31% negli investimenti ad alto rischio'
      ],
      logoFeatured: '/images/tradermate.svg',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'TraderMate',
      paymentMethods: ['Bonifico bancario', 'Carta di Credito', 'Carta di Debito'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/XN48XM/'
    },
    'bitcoin-profit': {
      description: 'Descrizione',
      disclaimer: `Siamo consapevoli che esistano numerosi siti Web che utilizzano false testimonianze di celebrità per inviare clienti a broker o call center non regolamentati. Il nostro sito Web invierà i clienti solo ai partner che ci hanno fornito le garanzie normative richieste. Questa è un'offerta di marketing e i nostri partner regolamentati indicheranno quali servizi hanno a disposizione al momento della registrazione.`,
      keyFeatures: [
        'Richiedi il Supporto di un Consulente Gratis',
        'Il 62% degli utenti segnala un profitto a partire dal primo deposito',
        'Segnalato un tasso di successo del 31% negli investimenti ad alto rischio'
      ],
      logoFeatured: '/images/bitcoin-profit.svg',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Profit',
      paymentMethods: ['Bonifico bancario', 'Carta di Credito', 'Carta di Debito'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/ZCJM85/'
    },
    'bitcoin-buyer': {
      description: 'Descrizione',
      disclaimer: `Siamo consapevoli che esistano numerosi siti Web che utilizzano false testimonianze di celebrità per inviare clienti a broker o call center non regolamentati. Il nostro sito Web invierà i clienti solo ai partner che ci hanno fornito le garanzie normative richieste. Questa è un'offerta di marketing e i nostri partner regolamentati indicheranno quali servizi hanno a disposizione al momento della registrazione.`,
      keyFeatures: [
        'Richiedi il Supporto di un Consulente Gratis',
        'Il 62% degli utenti segnala un profitto a partire dal primo deposito',
        'Segnalato un tasso di successo del 31% negli investimenti ad alto rischio'
      ],
      logoFeatured: '/images/bitcoin-buyer.svg',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Buyer',
      paymentMethods: ['Bonifico bancario', 'Carta di Credito', 'Carta di Debito'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/ZGBDPC/'
    },
    'yuan-payments': {
      description: 'Descrizione',
      disclaimer: `Siamo consapevoli che esistano numerosi siti Web che utilizzano false testimonianze di celebrità per inviare clienti a broker o call center non regolamentati. Il nostro sito Web invierà i clienti solo ai partner che ci hanno fornito le garanzie normative richieste. Questa è un'offerta di marketing e i nostri partner regolamentati indicheranno quali servizi hanno a disposizione al momento della registrazione.`,
      keyFeatures: [
        'Richiedi il Supporto di un Consulente Gratis',
        'Il 62% degli utenti segnala un profitto a partire dal primo deposito',
        'Segnalato un tasso di successo del 31% negli investimenti ad alto rischio'
      ],
      logoFeatured: '/images/yuan-payments.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Yuan Payments',
      paymentMethods: ['Bonifico bancario', 'Carta di Credito', 'Carta di Debito'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/22C3ZNF/'
    },
    'crypto-bank': {
      description: 'Descrizione',
      disclaimer: `Siamo consapevoli che esistano numerosi siti Web che utilizzano false testimonianze di celebrità per inviare clienti a broker o call center non regolamentati. Il nostro sito Web invierà i clienti solo ai partner che ci hanno fornito le garanzie normative richieste. Questa è un'offerta di marketing e i nostri partner regolamentati indicheranno quali servizi hanno a disposizione al momento della registrazione.`,
      keyFeatures: [
        'Richiedi il Supporto di un Consulente Gratis',
        'Il 62% degli utenti segnala un profitto a partire dal primo deposito',
        'Segnalato un tasso di successo del 31% negli investimenti ad alto rischio'
      ],
      logoFeatured: '/images/crypto-bank.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Crypto Bank',
      paymentMethods: ['Bonifico bancario', 'Carta di Credito', 'Carta di Debito'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/22FTQ5M/'
    },
    'crypto-engine': {
      description: 'Descrizione',
      disclaimer: `Siamo consapevoli che esistano numerosi siti Web che utilizzano false testimonianze di celebrità per inviare clienti a broker o call center non regolamentati. Il nostro sito Web invierà i clienti solo ai partner che ci hanno fornito le garanzie normative richieste. Questa è un'offerta di marketing e i nostri partner regolamentati indicheranno quali servizi hanno a disposizione al momento della registrazione.`,
      keyFeatures: [
        'Richiedi il Supporto di un Consulente Gratis',
        'Il 62% degli utenti segnala un profitto a partire dal primo deposito',
        'Segnalato un tasso di successo del 31% negli investimenti ad alto rischio'
      ],
      logoFeatured: '/images/crypto-engine.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Crypto Engine',
      paymentMethods: ['Bonifico bancario', 'Carta di Credito', 'Carta di Debito'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/22H8LW8/'
    },
    'bitcoin-pro': {
      description: 'Descrizione',
      disclaimer: `Siamo consapevoli che esistano numerosi siti Web che utilizzano false testimonianze di celebrità per inviare clienti a broker o call center non regolamentati. Il nostro sito Web invierà i clienti solo ai partner che ci hanno fornito le garanzie normative richieste. Questa è un'offerta di marketing e i nostri partner regolamentati indicheranno quali servizi hanno a disposizione al momento della registrazione.`,
      keyFeatures: [
        'Richiedi il Supporto di un Consulente Gratis',
        'Il 62% degli utenti segnala un profitto a partire dal primo deposito',
        'Segnalato un tasso di successo del 31% negli investimenti ad alto rischio'
      ],
      logoFeatured: '/images/bitcoin-pro.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Pro',
      paymentMethods: ['Bonifico bancario', 'Carta di Credito', 'Carta di Debito'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/22L2DCG/'
    },

    'bitcoin-bank': {
      description: 'Descrizione',
      disclaimer: `Siamo consapevoli che esistano numerosi siti Web che utilizzano false testimonianze di celebrità per inviare clienti a broker o call center non regolamentati. Il nostro sito Web invierà i clienti solo ai partner che ci hanno fornito le garanzie normative richieste. Questa è un'offerta di marketing e i nostri partner regolamentati indicheranno quali servizi hanno a disposizione al momento della registrazione.`,
      keyFeatures: [
        'Richiedi il Supporto di un Consulente Gratis',
        'Il 62% degli utenti segnala un profitto a partire dal primo deposito',
        'Segnalato un tasso di successo del 31% negli investimenti ad alto rischio'
      ],
      logoFeatured: '/images/bitcoin-bank.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Bank',
      paymentMethods: ['Bonifico bancario', 'Carta di Credito', 'Carta di Debito'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/22RKX9W/'
    },
    'tesler': {
      description: 'Descrizione',
      disclaimer: `Siamo consapevoli che esistano numerosi siti Web che utilizzano false testimonianze di celebrità per inviare clienti a broker o call center non regolamentati. Il nostro sito Web invierà i clienti solo ai partner che ci hanno fornito le garanzie normative richieste. Questa è un'offerta di marketing e i nostri partner regolamentati indicheranno quali servizi hanno a disposizione al momento della registrazione.`,
      keyFeatures: [
        'Richiedi il Supporto di un Consulente Gratis',
        'Il 62% degli utenti segnala un profitto a partire dal primo deposito',
        'Segnalato un tasso di successo del 31% negli investimenti ad alto rischio'
      ],
      logoFeatured: '/images/tesler.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Tesler',
      paymentMethods: ['Bonifico bancario', 'Carta di Credito', 'Carta di Debito'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/22SZS2H/'
    },
  
    'bitcoin-up': {
      description: 'Descrizione',
      disclaimer: `Siamo consapevoli che esistano numerosi siti Web che utilizzano false testimonianze di celebrità per inviare clienti a broker o call center non regolamentati. Il nostro sito Web invierà i clienti solo ai partner che ci hanno fornito le garanzie normative richieste. Questa è un'offerta di marketing e i nostri partner regolamentati indicheranno quali servizi hanno a disposizione al momento della registrazione.`,
      keyFeatures: [
        'Richiedi il Supporto di un Consulente Gratis',
        'Il 62% degli utenti segnala un profitto a partire dal primo deposito',
        'Segnalato un tasso di successo del 31% negli investimenti ad alto rischio'
      ],
      logoFeatured: '/images/bitcoin-up.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin UP',
      paymentMethods: ['Bonifico bancario', 'Carta di Credito', 'Carta di Debito'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/23PZP9F/'
    },
    'nft-code': {
      description: 'Descrizione',
      disclaimer: `Siamo consapevoli che esistano numerosi siti Web che utilizzano false testimonianze di celebrità per inviare clienti a broker o call center non regolamentati. Il nostro sito Web invierà i clienti solo ai partner che ci hanno fornito le garanzie normative richieste. Questa è un'offerta di marketing e i nostri partner regolamentati indicheranno quali servizi hanno a disposizione al momento della registrazione.`,
      keyFeatures: [
        'Richiedi il Supporto di un Consulente Gratis',
        'Il 62% degli utenti segnala un profitto a partire dal primo deposito',
        'Segnalato un tasso di successo del 31% negli investimenti ad alto rischio'
      ],
      logoFeatured: '/images/nft-code.svg',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'NFT Code',
      paymentMethods: ['Bonifico bancario', 'Carta di Credito', 'Carta di Debito'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/23W5CH8/'
    },
    'bit-iq': {
      description: 'Descrizione',
      disclaimer: `Siamo consapevoli che esistano numerosi siti Web che utilizzano false testimonianze di celebrità per inviare clienti a broker o call center non regolamentati. Il nostro sito Web invierà i clienti solo ai partner che ci hanno fornito le garanzie normative richieste. Questa è un'offerta di marketing e i nostri partner regolamentati indicheranno quali servizi hanno a disposizione al momento della registrazione.`,
      keyFeatures: [
        'Richiedi il Supporto di un Consulente Gratis',
        'Il 62% degli utenti segnala un profitto a partire dal primo deposito',
        'Segnalato un tasso di successo del 31% negli investimenti ad alto rischio'
      ],
      logoFeatured: '/images/bit-iq.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bit IQ',
      paymentMethods: ['Bonifico bancario', 'Carta di Credito', 'Carta di Debito'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/25TNNXJ/?uid=2381'
    },
    'bitcoin-code': {
      description: 'Descrizione',
      disclaimer: `Siamo consapevoli che esistano numerosi siti Web che utilizzano false testimonianze di celebrità per inviare clienti a broker o call center non regolamentati. Il nostro sito Web invierà i clienti solo ai partner che ci hanno fornito le garanzie normative richieste. Questa è un'offerta di marketing e i nostri partner regolamentati indicheranno quali servizi hanno a disposizione al momento della registrazione.`,
      keyFeatures: [
        'Richiedi il Supporto di un Consulente Gratis',
        'Il 62% degli utenti segnala un profitto a partire dal primo deposito',
        'Segnalato un tasso di successo del 31% negli investimenti ad alto rischio'
      ],
      logoFeatured: '/images/bitcoin-code.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Code',
      paymentMethods: ['Bonifico bancario', 'Carta di Credito', 'Carta di Debito'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/26DZF27/'
    },
    'bitcoin-fast-profit': {
      description: 'Descrizione',
      disclaimer: `Siamo consapevoli che esistano numerosi siti Web che utilizzano false testimonianze di celebrità per inviare clienti a broker o call center non regolamentati. Il nostro sito Web invierà i clienti solo ai partner che ci hanno fornito le garanzie normative richieste. Questa è un'offerta di marketing e i nostri partner regolamentati indicheranno quali servizi hanno a disposizione al momento della registrazione.`,
      keyFeatures: [
        'Richiedi il Supporto di un Consulente Gratis',
        'Il 62% degli utenti segnala un profitto a partire dal primo deposito',
        'Segnalato un tasso di successo del 31% negli investimenti ad alto rischio'
      ],
      logoFeatured: '/images/bitcoin-fast-profit.svg',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Fast Profit',
      paymentMethods: ['Bonifico bancario', 'Carta di Credito', 'Carta di Debito'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/26GC9QS/'
    },
    'bitcoin-trader': {
      description: 'Descrizione',
      disclaimer: `Siamo consapevoli che esistano numerosi siti Web che utilizzano false testimonianze di celebrità per inviare clienti a broker o call center non regolamentati. Il nostro sito Web invierà i clienti solo ai partner che ci hanno fornito le garanzie normative richieste. Questa è un'offerta di marketing e i nostri partner regolamentati indicheranno quali servizi hanno a disposizione al momento della registrazione.`,
      keyFeatures: [
        'Richiedi il Supporto di un Consulente Gratis',
        'Il 62% degli utenti segnala un profitto a partire dal primo deposito',
        'Segnalato un tasso di successo del 31% negli investimenti ad alto rischio'
      ],
      logoFeatured: '/images/bitcoin-trader.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Trader',
      paymentMethods: ['Bonifico bancario', 'Carta di Credito', 'Carta di Debito'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/26HQ6HF/'
    },
    'bitcoin-millionaire-pro-system': {
      description: 'Descrizione',
      disclaimer: `Siamo consapevoli che esistano numerosi siti Web che utilizzano false testimonianze di celebrità per inviare clienti a broker o call center non regolamentati. Il nostro sito Web invierà i clienti solo ai partner che ci hanno fornito le garanzie normative richieste. Questa è un'offerta di marketing e i nostri partner regolamentati indicheranno quali servizi hanno a disposizione al momento della registrazione.`,
      keyFeatures: [
        'Richiedi il Supporto di un Consulente Gratis',
        'Il 62% degli utenti segnala un profitto a partire dal primo deposito',
        'Segnalato un tasso di successo del 31% negli investimenti ad alto rischio'
      ],
      logoFeatured: '/images/bitcoin-millionaire-pro-system.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Millionaire Pro System',
      paymentMethods: ['Bonifico bancario', 'Carta di Credito', 'Carta di Debito'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/26LHXZM/'
    },
    'bitcoin-circuit': {
      description: 'Descrizione',
      disclaimer: `Siamo consapevoli che esistano numerosi siti Web che utilizzano false testimonianze di celebrità per inviare clienti a broker o call center non regolamentati. Il nostro sito Web invierà i clienti solo ai partner che ci hanno fornito le garanzie normative richieste. Questa è un'offerta di marketing e i nostri partner regolamentati indicheranno quali servizi hanno a disposizione al momento della registrazione.`,
      keyFeatures: [
        'Richiedi il Supporto di un Consulente Gratis',
        'Il 62% degli utenti segnala un profitto a partire dal primo deposito',
        'Segnalato un tasso di successo del 31% negli investimenti ad alto rischio'
      ],
      logoFeatured: '/images/bitcoin-circuit.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Circuit',
      paymentMethods: ['Bonifico bancario', 'Carta di Credito', 'Carta di Debito'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/26P9PFT/'
    },
    'bitcoin-future': {
      description: 'Descrizione',
      disclaimer: `Siamo consapevoli che esistano numerosi siti Web che utilizzano false testimonianze di celebrità per inviare clienti a broker o call center non regolamentati. Il nostro sito Web invierà i clienti solo ai partner che ci hanno fornito le garanzie normative richieste. Questa è un'offerta di marketing e i nostri partner regolamentati indicheranno quali servizi hanno a disposizione al momento della registrazione.`,
      keyFeatures: [
        'Richiedi il Supporto di un Consulente Gratis',
        'Il 62% degli utenti segnala un profitto a partire dal primo deposito',
        'Segnalato un tasso di successo del 31% negli investimenti ad alto rischio'
      ],
      logoFeatured: '/images/bitcoin-future.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Future',
      paymentMethods: ['Bonifico bancario', 'Carta di Credito', 'Carta di Debito'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/26QNL6G/'
    },
    'bitqt': {
      description: 'Descrizione',
      disclaimer: `Siamo consapevoli che esistano numerosi siti Web che utilizzano false testimonianze di celebrità per inviare clienti a broker o call center non regolamentati. Il nostro sito Web invierà i clienti solo ai partner che ci hanno fornito le garanzie normative richieste. Questa è un'offerta di marketing e i nostri partner regolamentati indicheranno quali servizi hanno a disposizione al momento della registrazione.`,
      keyFeatures: [
        'Richiedi il Supporto di un Consulente Gratis',
        'Il 62% degli utenti segnala un profitto a partire dal primo deposito',
        'Segnalato un tasso di successo del 31% negli investimenti ad alto rischio'
      ],
      logoFeatured: '/images/bitqt.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'BitQT',
      paymentMethods: ['Bonifico bancario', 'Carta di Credito', 'Carta di Debito'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/28Q7XLQ/'
    },
    'trade-2-earn': {
      description: 'Descrizione',
      disclaimer: `Siamo consapevoli che esistano numerosi siti Web che utilizzano false testimonianze di celebrità per inviare clienti a broker o call center non regolamentati. Il nostro sito Web invierà i clienti solo ai partner che ci hanno fornito le garanzie normative richieste. Questa è un'offerta di marketing e i nostri partner regolamentati indicheranno quali servizi hanno a disposizione al momento della registrazione.`,
      keyFeatures: [
        'Richiedi il Supporto di un Consulente Gratis',
        'Il 62% degli utenti segnala un profitto a partire dal primo deposito',
        'Segnalato un tasso di successo del 31% negli investimenti ad alto rischio'
      ],
      logoFeatured: '/images/trade-2-earn.svg',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Trade 2 Earn',
      paymentMethods: ['Bonifico bancario', 'Carta di Credito', 'Carta di Debito'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/28T1P3Z/'
    },
    'bitcoin-bank-breaker': {
      description: 'Descrizione',
      disclaimer: `Siamo consapevoli che esistano numerosi siti Web che utilizzano false testimonianze di celebrità per inviare clienti a broker o call center non regolamentati. Il nostro sito Web invierà i clienti solo ai partner che ci hanno fornito le garanzie normative richieste. Questa è un'offerta di marketing e i nostri partner regolamentati indicheranno quali servizi hanno a disposizione al momento della registrazione.`,
      keyFeatures: [
        'Richiedi il Supporto di un Consulente Gratis',
        'Il 62% degli utenti segnala un profitto a partire dal primo deposito',
        'Segnalato un tasso di successo del 31% negli investimenti ad alto rischio'
      ],
      logoFeatured: '/images/bitcoin-bank-breaker.svg',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Bank Breaker',
      paymentMethods: ['Bonifico bancario', 'Carta di Credito', 'Carta di Debito'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2BZPNQ3/'
    },
    'bitcoin-billionaire': {
      description: 'Descrizione',
      disclaimer: `Siamo consapevoli che esistano numerosi siti Web che utilizzano false testimonianze di celebrità per inviare clienti a broker o call center non regolamentati. Il nostro sito Web invierà i clienti solo ai partner che ci hanno fornito le garanzie normative richieste. Questa è un'offerta di marketing e i nostri partner regolamentati indicheranno quali servizi hanno a disposizione al momento della registrazione.`,
      keyFeatures: [
        'Richiedi il Supporto di un Consulente Gratis',
        'Il 62% degli utenti segnala un profitto a partire dal primo deposito',
        'Segnalato un tasso di successo del 31% negli investimenti ad alto rischio'
      ],
      logoFeatured: '/images/bitcoin-billionaire.svg',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Billionaire',
      paymentMethods: ['Bonifico bancario', 'Carta di Credito', 'Carta di Debito'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2DPBLHB/'
    },
    'bitcoin-loophole': {
      description: 'Descrizione',
      disclaimer: `Siamo consapevoli che esistano numerosi siti Web che utilizzano false testimonianze di celebrità per inviare clienti a broker o call center non regolamentati. Il nostro sito Web invierà i clienti solo ai partner che ci hanno fornito le garanzie normative richieste. Questa è un'offerta di marketing e i nostri partner regolamentati indicheranno quali servizi hanno a disposizione al momento della registrazione.`,
      keyFeatures: [
        'Richiedi il Supporto di un Consulente Gratis',
        'Il 62% degli utenti segnala un profitto a partire dal primo deposito',
        'Segnalato un tasso di successo del 31% negli investimenti ad alto rischio'
      ],
      logoFeatured: '/images/bitcoin-loophole.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Loophole',
      paymentMethods: ['Bonifico bancario', 'Carta di Credito', 'Carta di Debito'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2DQPH7X/'
    },
    'bitcoin-motion': {
      description: 'Descrizione',
      disclaimer: `Siamo consapevoli che esistano numerosi siti Web che utilizzano false testimonianze di celebrità per inviare clienti a broker o call center non regolamentati. Il nostro sito Web invierà i clienti solo ai partner che ci hanno fornito le garanzie normative richieste. Questa è un'offerta di marketing e i nostri partner regolamentati indicheranno quali servizi hanno a disposizione al momento della registrazione.`,
      keyFeatures: [
        'Richiedi il Supporto di un Consulente Gratis',
        'Il 62% degli utenti segnala un profitto a partire dal primo deposito',
        'Segnalato un tasso di successo del 31% negli investimenti ad alto rischio'
      ],
      logoFeatured: '/images/bitcoin-motion.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Motion',
      paymentMethods: ['Bonifico bancario', 'Carta di Credito', 'Carta di Debito'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2DS4CZJ/'
    },
    'profit-bitcoin': {
      description: 'Descrizione',
      disclaimer: `Siamo consapevoli che esistano numerosi siti Web che utilizzano false testimonianze di celebrità per inviare clienti a broker o call center non regolamentati. Il nostro sito Web invierà i clienti solo ai partner che ci hanno fornito le garanzie normative richieste. Questa è un'offerta di marketing e i nostri partner regolamentati indicheranno quali servizi hanno a disposizione al momento della registrazione.`,
      keyFeatures: [
        'Richiedi il Supporto di un Consulente Gratis',
        'Il 62% degli utenti segnala un profitto a partire dal primo deposito',
        'Segnalato un tasso di successo del 31% negli investimenti ad alto rischio'
      ],
      logoFeatured: '/images/profit-bitcoin.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Profit Bitcoin',
      paymentMethods: ['Bonifico bancario', 'Carta di Credito', 'Carta di Debito'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2DTH8P5/'
    },
    'bitcoin-evolution': {
      description: 'Descrizione',
      disclaimer: `Siamo consapevoli che esistano numerosi siti Web che utilizzano false testimonianze di celebrità per inviare clienti a broker o call center non regolamentati. Il nostro sito Web invierà i clienti solo ai partner che ci hanno fornito le garanzie normative richieste. Questa è un'offerta di marketing e i nostri partner regolamentati indicheranno quali servizi hanno a disposizione al momento della registrazione.`,
      keyFeatures: [
        'Richiedi il Supporto di un Consulente Gratis',
        'Il 62% degli utenti segnala un profitto a partire dal primo deposito',
        'Segnalato un tasso di successo del 31% negli investimenti ad alto rischio'
      ],
      logoFeatured: '/images/bitcoin-evolution.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Evolution',
      paymentMethods: ['Bonifico bancario', 'Carta di Credito', 'Carta di Debito'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2DWW5FQ/'
    },
    'bitcoin-champion': {
      description: 'Descrizione',
      disclaimer: `Siamo consapevoli che esistano numerosi siti Web che utilizzano false testimonianze di celebrità per inviare clienti a broker o call center non regolamentati. Il nostro sito Web invierà i clienti solo ai partner che ci hanno fornito le garanzie normative richieste. Questa è un'offerta di marketing e i nostri partner regolamentati indicheranno quali servizi hanno a disposizione al momento della registrazione.`,
      keyFeatures: [
        'Richiedi il Supporto di un Consulente Gratis',
        'Il 62% degli utenti segnala un profitto a partire dal primo deposito',
        'Segnalato un tasso di successo del 31% negli investimenti ad alto rischio'
      ],
      logoFeatured: '/images/bitcoin-champion.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Champion',
      paymentMethods: ['Bonifico bancario', 'Carta di Credito', 'Carta di Debito'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2F1MWWZ/'
    },
    'bitcoin-money': {
      description: 'Descrizione',
      disclaimer: `Siamo consapevoli che esistano numerosi siti Web che utilizzano false testimonianze di celebrità per inviare clienti a broker o call center non regolamentati. Il nostro sito Web invierà i clienti solo ai partner che ci hanno fornito le garanzie normative richieste. Questa è un'offerta di marketing e i nostri partner regolamentati indicheranno quali servizi hanno a disposizione al momento della registrazione.`,
      keyFeatures: [
        'Richiedi il Supporto di un Consulente Gratis',
        'Il 62% degli utenti segnala un profitto a partire dal primo deposito',
        'Segnalato un tasso di successo del 31% negli investimenti ad alto rischio'
      ],
      logoFeatured: '/images/bitcoin-money.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Money',
      paymentMethods: ['Bonifico bancario', 'Carta di Credito', 'Carta di Debito'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2F32RMK/'
    },
    'bitcoin-trend-app': {
      description: 'Descrizione',
      disclaimer: `Siamo consapevoli che esistano numerosi siti Web che utilizzano false testimonianze di celebrità per inviare clienti a broker o call center non regolamentati. Il nostro sito Web invierà i clienti solo ai partner che ci hanno fornito le garanzie normative richieste. Questa è un'offerta di marketing e i nostri partner regolamentati indicheranno quali servizi hanno a disposizione al momento della registrazione.`,
      keyFeatures: [
        'Richiedi il Supporto di un Consulente Gratis',
        'Il 62% degli utenti segnala un profitto a partire dal primo deposito',
        'Segnalato un tasso di successo del 31% negli investimenti ad alto rischio'
      ],
      logoFeatured: '/images/bitcoin-trend-app.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Bitcoin Trend App',
      paymentMethods: ['Bonifico bancario', 'Carta di Credito', 'Carta di Debito'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2F4FND6/'
    },
    'crypto-comeback-pro': {
      description: 'Descrizione',
      disclaimer: `Siamo consapevoli che esistano numerosi siti Web che utilizzano false testimonianze di celebrità per inviare clienti a broker o call center non regolamentati. Il nostro sito Web invierà i clienti solo ai partner che ci hanno fornito le garanzie normative richieste. Questa è un'offerta di marketing e i nostri partner regolamentati indicheranno quali servizi hanno a disposizione al momento della registrazione.`,
      keyFeatures: [
        'Richiedi il Supporto di un Consulente Gratis',
        'Il 62% degli utenti segnala un profitto a partire dal primo deposito',
        'Segnalato un tasso di successo del 31% negli investimenti ad alto rischio'
      ],
      logoFeatured: '/images/crypto-comeback-pro.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Crypto Comeback Pro',
      paymentMethods: ['Bonifico bancario', 'Carta di Credito', 'Carta di Debito'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2F5SK4R/'
    },
    'ethereum-system': {
      description: 'Descrizione',
      disclaimer: `Siamo consapevoli che esistano numerosi siti Web che utilizzano false testimonianze di celebrità per inviare clienti a broker o call center non regolamentati. Il nostro sito Web invierà i clienti solo ai partner che ci hanno fornito le garanzie normative richieste. Questa è un'offerta di marketing e i nostri partner regolamentati indicheranno quali servizi hanno a disposizione al momento della registrazione.`,
      keyFeatures: [
        'Richiedi il Supporto di un Consulente Gratis',
        'Il 62% degli utenti segnala un profitto a partire dal primo deposito',
        'Segnalato un tasso di successo del 31% negli investimenti ad alto rischio'
      ],
      logoFeatured: '/images/ethereum-system.svg',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'Ethereum System',
      paymentMethods: ['Bonifico bancario', 'Carta di Credito', 'Carta di Debito'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2F77FTD/'
    },
    'the-bitcoin-millionaire': {
      description: 'Descrizione',
      disclaimer: `Siamo consapevoli che esistano numerosi siti Web che utilizzano false testimonianze di celebrità per inviare clienti a broker o call center non regolamentati. Il nostro sito Web invierà i clienti solo ai partner che ci hanno fornito le garanzie normative richieste. Questa è un'offerta di marketing e i nostri partner regolamentati indicheranno quali servizi hanno a disposizione al momento della registrazione.`,
      keyFeatures: [
        'Richiedi il Supporto di un Consulente Gratis',
        'Il 62% degli utenti segnala un profitto a partire dal primo deposito',
        'Segnalato un tasso di successo del 31% negli investimenti ad alto rischio'
      ],
      logoFeatured: '/images/the-bitcoin-millionaire.png',
      logoThumbnail: '',
      minDeposit: 250,
      name: 'The Bitcoin Millionaire',
      paymentMethods: ['Bonifico bancario', 'Carta di Credito', 'Carta di Debito'],
      starRating: 75,
      redirect: true,
      href: 'https://www.qw5dvch.com/25R83W2R/2F8LBL1/'
    }
  },

  home: {
    title: '{0} - Broker consigliato del mese di {1} {2}',
    title2: 'Trova il posto piú sicuro dove investire',
    description: `
      {0} analizza le tendenze di acquisto/vendita del mercato al fine di trarre vantaggio e trarre profitto dalla volatilità. Non è necessario comprendere il trading tecnico per poter utilizzare il software e questo sistema ha ricevuto numerose recensioni positive online.
    `,

    description2: `
      <h2><strong>Come sono Valutati i Brokers&nbsp;</strong>- {0}</h2>
      <p>
        Il team di wealth-info.com analizza e riesamina in maniera rigorosa i broker del settore in modo che tu non debba
        farlo. Il team valuta un elenco di non meno di 57 articoli, alcuni dei punti chiave includono:
      </p>
      <ul>
        <li>
          <strong>Qualità del Servizio Clienti -&nbsp;</strong>Hanno una sezione di aiuto facile da usare? Sono facili da
          contattare? Le informazioni sono chiare, inclusi orari di apertura, codici paese ecc.? Offrono una varietà di
          metodi per l’assistenza clienti - ad es. Email, Telefono, Chat, Posta? Sono veloci nel rispondere? Offrono un
          buon servizio? Sono educati? Sono ben informati?
        </li>
        <li>
          <strong>Facilità d'uso e interfaccia utente -&nbsp;</strong>I siti Web si definiscono dalla loro interfaccia; è
          ciò che li rende attrattivi per l’uso e non è diverso nel settore degli investimenti. Trovare un sito che sembra
          buono ed è facile da usare è fondamentale per un'esperienza di investimento positiva.
        </li>
        <li>
          <strong>”Cashier” -</strong>&nbsp;Esiste una buona selezione di metodi bancari? Il sito è sicuro? È facile
          depositare fondi? I prelievi vengono elaborati rapidamente? È un processo facile e veloce dall’inizio alla fine?
        </li>
      </ul>
      <p>E molti altri ancora...</p>
      <h2><strong>5 Fatti Interessanti sul Mercato Azionario</strong></h2>
      <ol>
        <li>
          Il termine&nbsp;<strong>blue chip</strong>&nbsp;è un nickname prestigioso riservato alle società che operano in
          borsa e sono conosciute a livello nazionale, il nome deriva dal chip di poker di maggior valore, che è blu.
        </li>
        <li>
          Una quota dà diritto al proprietario di quella quota a detenere letteralmente una quota azionaria e a
          partecipare&nbsp;<strong>nella società&nbsp;</strong>.
        </li>
        <li>
          Amazon ha raggiunto quasi il<strong>100% di guadagni</strong>&nbsp;nel punto più alto del 2018, chiudendo l'anno
          con un aumento del 49%!
        </li>
        <li>
          L'inizio di ogni giornata di borsa alla Borsa di New York inizia con il&nbsp;<strong
            >suono di una vera campana&nbsp;</strong
          >alle 9:30, e questo accade dal 1903.
        </li>
        <li>
          Nel 1972, un incontro di 24 tra i maggiori commercianti ha portato alla creazione del mercato, la Borsa di New
          York (NYSE). Si sarebbero incontrati&nbsp;<strong>fuori a Wall Street per scambiare azioni</strong>.
        </li>
      </ol>
      <p>&nbsp;</p>
    `
  },

  register: {
    title: 'Prima di andartene...',
    description: `I mercati si muovono velocemente. Non perdere l'occasione di approfittare degli ultimi rialzi e ribassi.Registrati oggi e sarai sorpreso dopo aver prelevato il tuo profitto di sole 2 settimane!`,
    registerNow: 'Apri un account adesso!',
    startTradingNow: 'Inizia il Trading adesso',
    terms: 'Compilando questo modulo sono consapevole che verrò reindirizzato a un partner commerciale di terze parti e che verranno condivisi i miei dati personali.',
    fullName: 'Nome e cognome',
    email: 'E-mail',
    howOldAreU: 'Quanti anni hai?',
    loading: 'Registrazione...'
  },

  footer: {
    description: `
      <p>Risk Warning:</p>
      <p>When investing your capital is at risk.</p>
      <p><br /></p>
      <p>
        Past performance is not an indication of future results. Any investing history presented is less than 5
        years old unless otherwise stated and may not suffice as a basis for investment decisions. Prices may go
        down as well as up, prices can fluctuate widely and you may lose all of or more than the amount you invest.
        Investing is not suitable for everyone; please ensure that you have fully understood the risks and
        legalities involved. If you are unsure, seek independent financial, legal, tax and/or accounting advice.
        This website does not provide investment, financial, legal, tax or accounting advice. Your investment may
        not qualify for investor protection in your country or state of residence, so please conduct your own due
        diligence.
      </p>
      <p>
        {0} is an independent comparison platform and information service that aims to provide you with
        the tools you need to make better decisions. While we are independent, the offers that appear on this site
        are from companies from which {0} receives compensation.&nbsp;
      </p>
      <p>
        Your investment may not qualify for investor protection in your country or state of residence, so please
        conduct your own due diligence. This website is free for you to use but we may receive commission from the
        companies we feature on this site.
      </p>
      <p>
      Wellgard Enterprise Inc <br>
      216913 Suite 1 Second floor , Sound & Vision House, Francis Rachel Street <br>
      Victoria Mahe<br>
       Seychelles
      </p>
    `
  },

  month: {
    "January": "Gennaio",
    "February": "Febbraio",
    "March": "Marzo",
    "April": "Aprile",
    "May": "Maggio",
    "June": "giugno",
    "July": "luglio",
    "August": "Agosto",
    "September": "Settembre",
    "October": "Ottobre",
    "November": "Novembre",
    "December": "Dicembre"
  }
};
