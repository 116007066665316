<template>
  <component :is="layout">
    <transition name="scroll-x-transition" mode="out-in" appear>
      <router-view :key="$route.path" />
    </transition>
  </component>
</template>

<script>
import DefaultLayout from '@/view/layouts/Default'
import EmptyLayout from '@/view/layouts/Empty'

export default {
  name: 'App',
  components: {
    DefaultLayout,
    EmptyLayout,
  },
  computed: {
    layout() {
      if (this.$route.meta.layout) {
        return this.$route.meta.layout + '-layout';
      }

      const emptyLayoutPaths = ['/']

      return emptyLayoutPaths.includes(this.$route.path) ? 'empty-layout' : `default-layout`
    },
  },
}
</script>
